import { http } from "../utils/http";
import Util from "../utils/util";

let currencyService = {
  findCurrencyByCode: async (code) => {
    return await http.get(
      `${Util.baseUrl}/api/currency/get_by_code/${code}`,
      Util.getAuthorizedHeaders()
    );
  },
  findAllActiveCurrencies: async () => {
    return await http.get(`${Util.baseUrl}/api/currency/get_all_active`, {
      headers: Util.getHeaders(),
    });
  },
  findCurrencyById: async (id) => {
    return await http.get(`${Util.baseUrl}/api/currency/get_by_id/${id}`, {
      headers: Util.getAuthorizedHeaders(),
    });
  },
};
export { currencyService };
