import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
const ConfirmationModal = ({
  message,
  isProcessing,
  onAcceptHandlaer,
  onRejectHandler,
}) => {
  return (
    <>
      <div className="p-4">
        <div className="text-center py-3">
          <p>{message}</p>
        </div>
        <div className="form-group__input mt-3">
          <button
            place="Yes"
            className="h-[40px] rounded bg-qorange text-white p-2 mr-2"
            onClick={onRejectHandler}
          >
            <span>No</span>
          </button>
          <button
            place="Yes"
            className="h-[40px] rounded bg-qyellow text-white  p-2 mr-2"
            onClick={onAcceptHandlaer}
          >
            <span>
              {isProcessing ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : (
                <span>Yes</span>
              )}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default ConfirmationModal;
