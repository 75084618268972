import { http } from "../utils/http";
import Util from "../utils/util";

let wishlistService = {
  addWishList: async (productId) => {
    return await http.post(
      `${Util.baseUrl}/api/wishlist/add/${productId}`,
      {},
      Util.getAuthorizedHeaders()
    );
  },
  findWishListByUserId: async (id) => {
    return await http.get(
      `${Util.baseUrl}/api/wishlist/get_by_user/${id}`,
      Util.getAuthorizedHeaders()
    );
  },
  deleteWishList: async (id) => {
    return await http.delete(
      `${Util.baseUrl}/api/wishlist/delete/${id}`,
      Util.getAuthorizedHeaders()
    );
  },
  deleteAllWishList: async () => {
    return await http.delete(
      `${Util.baseUrl}/api/wishlist/delete-all`,
      Util.getAuthorizedHeaders()
    );
  },
};
export { wishlistService };
