import "react-input-range/lib/css/index.css";
import { Link } from "react-router-dom";

export default function ProductsFilter({ categories, handle = "" }) {
  return (
    <>
      <div
        className={`filter-widget bellabanga-hom w-full d-none d-sm-block overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px"}`}
      >
        <div className="filter-subject-item pb-10 border-b border-qgray-border my-8">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">
              Product categories
            </h1>
          </div>
          <div className="filter-items">
            <ul>
              {categories && categories.length > 0 ? (
                categories.map((item, idx) => {
                  return (
                    <Link
                      key={idx}
                      className="item flex justify-between items-center mb-5"
                      to={`/${handle ? handle : "products"}/${
                        item.category.id
                      }`}
                    >
                      <span
                        htmlFor="mobileLaptop"
                        className="text-xxs font-black font-400 capitalize"
                      >
                        {item.category.name}
                      </span>
                    </Link>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
