import { Countries, States } from "countries-states-cities-service";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { useContext, useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../../Access";
import { cartService } from "../../services/cart.service";
import { orderService } from "../../services/order.service";
import { settingsService } from "../../services/settings.service";
import { storage } from "../../utils/storage";
import Util from "../../utils/util";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import SelectBox from "../Helpers/SelectBox";
import TextArea from "../Helpers/TextArea";
import Layout from "../Partials/Layout";

export default function CheakoutPage() {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  if (!authenticated) window.location.href = "login?redirect=checkout";

  const [cartItems, setCartItems] = useState([]);
  const [countries, setCountries] = useState(["Select country...."]);
  const [states, setStates] = useState(["Select city...."]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(
    storage.get("currencyCode") || "USD"
  );
  const [paymentOption, setPaymentOption] = useState("Flutterwave");
  const [isProcessing, setIsProcessing] = useState(false);
  const [serviceCharge, setServiceCharge] = useState({});
  const [order, setOrder] = useState({});
  const [validation, setValidation] = useState({
    validName: true,
    validEmail: true,
    validMobile: true,
    validCity: true,
    validAddress: true,
  });
  const [input, setInput] = useState({
    name: `${user ? user?.name : ""}`,
    email: `${user ? user?.email : ""}`,
    mobile: `${user ? user?.mobile : ""}`,
    city: `${user ? user?.city : ""}`,
    zip: "",
    note: "",
    address: `${user ? user?.city : ""}`,
  });

  const flutterwaveConfig = {
    public_key: Util.flutterwaveKey,
    tx_ref: new Date().getTime().toString(),
    amount: order?.totalAmount,
    currency: selectedCurrency,
    redirect_url: location.host + "/profile#order",
    payment_options: "card,ussd,mobilemoney,paypal,googlepay,applepay,eNaira",
    meta: { orderId: order?.id, orderAmount: order?.totalAmount },
    customer: {
      email: user?.email,
      phone_number: user?.mobile,
      name: user?.name,
    },
    customizations: {
      title: "Bellabanga",
      description: "Payment for items in cart",
      logo: "https://assets.piedpiper.com/logo.png",
    },
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: user?.email,
    currency: selectedCurrency,
    redirectUrl: location.host + "/profile#order",
    amount: order?.totalAmount,
    publicKey: Util.paystackKey,
    metadata: {
      custom_fields: [
        { orderId: order?.id, orderAmount: order?.totalAmount },
        {
          email: user?.email,
          phone_number: user?.mobile,
          name: user?.name,
        },
      ],
    },
  };
  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
  const handlePaystackPayment = usePaystackPayment(paystackConfig);

  const getCartItems = () => {
    let cart = cartService.getItems();
    setCartItems(cart);
  };

  const findCountryCode = (selectedCountry) => {
    var countryCode = null;
    Countries.getCountries().forEach((country) => {
      if (country.name == selectedCountry) countryCode = country.iso2;
    });
    return countryCode;
  };

  const getCities = (selectedCountry) => {
    const countryCode = findCountryCode(selectedCountry);
    const states = States.getStates({ filters: { country_code: countryCode } });
    var stateList = [];
    if (selectedCountry == "United Kingdom") {
      stateList = ["England", "Scotland", "Wales", "Northern Ireland"];
    } else {
      states.forEach((state) => {
        stateList.push(state.name);
      });
    }
    setStates(stateList);
  };

  const getServiceCharge = () => {
    settingsService
      .findServiceeCharge()
      .then((result) => {
        console.log(result);
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setServiceCharge(responseData);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const inputHandler = (e) => {
    const name = e.target.id;
    const key = `valid${Util.capitalize(name)}`;
    setInput({ ...input, [name]: e.target.value });
    const result = Util.validate(validation, input, [name]);
    setValidation({ ...validation, ...result });
  };

  const cancelOrder = (orderId) => {
    orderService
      .cancelOder(orderId)
      .then((result) => {
        if (result.data && result.data.success) {
          toast.error("Order canceled");
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const proceedOrder = async () => {
    const paymentRef = new Date().getTime().toString();
    const result = Util.validate(validation, input, [
      "name",
      "email",
      "mobile",
      "city",
      "address",
    ]);
    setValidation({ ...validation, ...result });

    if (
      validation.validName &&
      validation.validEmail &&
      validation.validMobile &&
      validation.validCity &&
      validation.validAddress
    ) {
      const payload = {
        customerName: input.name,
        emailAddress: input.email,
        phoneNumber: input.mobile,
        paymentOption: "CARD",
        paymentReferenceId: paymentRef,
        currencyCode: selectedCurrency,
        country: selectedCountry,
        state: selectedState,
        address: input.address,
        tax: 0,
        note: input.note,
        products: cartItems.map((item, idx) => {
          return {
            id: item.product.id,
            quantity: item.quantity,
            color: item.color || null,
            size: item.size || null,
          };
        }),
      };
      setIsProcessing(true);
      orderService
        .placeOrder(payload)
        .then((result) => {
          if (result.data.success) {
            const order = result.data.payload;
            if (paymentOption === "Flutterwave") {
              flutterwaveConfig.amount = order.totalAmount;
              flutterwaveConfig.meta.orderId = order.id;
              flutterwaveConfig.tx_ref = paymentRef;
              setOrder(order);

              handleFlutterPayment({
                callback: async (response) => {
                  setIsProcessing(false);
                  if (response.status && response.status === "completed") {
                    //onSuccess
                    closePaymentModal();
                    toast.success("Order Successful");
                    setTimeout(() => {
                      window.location.href = "/profile#order";
                    }, 500);
                    cartService.clearCart();
                  } else {
                    setIsProcessing(false);
                    toast.error(response.status);
                  }
                },
                onClose: async () => {
                  setIsProcessing(false);
                  toast.error("Payment canceled");
                  await cancelOrder(order?.id);
                },
              });
            } else if (paymentOption === "Paystack") {
              const totalPayable = Number(order.totalAmount) * 100;

              paystackConfig.amount = totalPayable;
              paystackConfig.reference = paymentRef;
              paystackConfig.metadata.custom_fields[0].orderId = order.id;
              paystackConfig.metadata.custom_fields[0].orderAmount =
                totalPayable;
              setOrder(order);

              handlePaystackPayment(
                (reference) => {
                  setIsProcessing(false);
                  if (reference) {
                    //onSuccess
                    toast.success("Order Successful");
                    setTimeout(() => {
                      window.location.href = "/profile#order";
                    }, 500);
                    cartService.clearCart();
                  } else {
                    setIsProcessing(false);
                    toast.error("Oops! Payment failed");
                  }
                },
                () => {
                  setIsProcessing(false);
                  toast.error("Payment canceled");
                  cancelOrder(order?.id);
                }
              );
            } else if (paymentOption === "Squad") {
              toast.warn("Squad is not available now");
            }
            //end payment
          } else {
            setIsProcessing(false);
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          toast.error(
            e.response ? e.response.data.message : "Connection refused!"
          );
          console.log(e);
          setIsProcessing(false);
        });
    } else {
      toast.error("All fields required!");
    }
  };

  const getComputedTotalAmount = () => {
    return Number(
      Util.convertCurrencyToPrefered(
        cartItems[0].currencyCode,
        cartItems
          .map((item, idx) => {
            return Number(item.product.price) * Number(item.quantity);
          })
          .reduce((partialSum, a) => partialSum + a, 0)
      )
    );
  };

  const getComputedShippingFees = () => {
    return Number(
      Util.convertCurrencyToPrefered(
        cartItems[0].currencyCode,
        cartItems
          .map((item, idx) => {
            return Number(item.product.shippingFees) * Number(item.quantity);
          })
          .reduce((partialSum, a) => partialSum + a, 0)
      )
    );
  };

  const getComputedTransactionCharge = () => {
    const totalAmount = getComputedTotalAmount();
    const charge = Util.convertCurrencyToPrefered(
      "NGN",
      Number(serviceCharge.value)
    );
    if (serviceCharge.mode == "PERCENTAGE") {
      return Number((charge / 100) * totalAmount);
    } else {
      return Number(charge);
    }
  };
  useEffect(() => {
    getServiceCharge();
    getCartItems();
    let countryList = [];
    Countries.getCountries().forEach((country) => {
      countryList.push(country.name);
    });
    setCountries(countryList);
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <ToastContainer />
      <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
        <div className="w-full mb-5">
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "checkout", path: "/checkout" },
            ]}
          />
        </div>
        <div className="checkout-main-content w-full">
          <div className="container-x mx-auto">
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-1/2 w-full">
                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                  Billing Details
                </h1>
                <div className="form-area">
                  <div className="sm:flex sm:space-x-5 items-center mb-8">
                    <div className="sm:w-1/2  mb-5 sm:mb-0">
                      <InputCom
                        label="Name*"
                        placeholder="Name"
                        name="name"
                        inputClasses="w-full h-[50px]"
                        parentClasses={
                          !validation.validName
                            ? `border-red-600`
                            : " border-slate-300"
                        }
                        value={input.name}
                        inputHandler={inputHandler}
                        name="name"
                      />
                    </div>
                    <div className="flex-1">
                      <InputCom
                        label="Email Address*"
                        placeholder="demoemial@gmail.com"
                        inputClasses="w-full h-[50px]"
                        parentClasses={
                          !validation.validEmail
                            ? `border-red-600`
                            : " border-slate-300"
                        }
                        value={input.email}
                        inputHandler={inputHandler}
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="flex space-x-5 items-center mb-8">
                    <div className="flex-1">
                      <InputCom
                        label="Phone Number*"
                        placeholder="012 3  *******"
                        inputClasses="w-full h-[50px]"
                        parentClasses={
                          !validation.validMobile
                            ? `border-red-600`
                            : " border-slate-300"
                        }
                        value={input.mobile}
                        inputHandler={inputHandler}
                        name="mobile"
                      />
                    </div>
                  </div>
                  <div className="mb-5">
                    <SelectBox
                      datas={countries}
                      label="Country*"
                      className="w-full h-[50px] border border-slate-300 px-5 flex justify-between items-center mb-2 rounded-lg"
                      parentClasses=" border-slate-300"
                      action={(value) => {
                        getCities(value);
                        setSelectedCountry(value);
                      }}
                      value={input.country}
                      name="country"
                    />
                    <SelectBox
                      datas={states}
                      label="State*"
                      className="w-full h-[50px] border border-slate-300 px-5 flex justify-between items-center mb-2 rounded-lg"
                      parentClasses=" border-slate-300"
                      action={(value) => {
                        setSelectedState(value);
                      }}
                      value={input.state}
                      name="state"
                    />
                  </div>
                  <div className="flex space-x-5 items-center mb-8">
                    <div className="w-1/2">
                      <InputCom
                        label="Town/City *"
                        placeholder=""
                        inputClasses="w-full h-[50px]"
                        parentClasses={
                          !validation.validCity
                            ? `border-red-600`
                            : " border-slate-300"
                        }
                        value={input.city}
                        inputHandler={inputHandler}
                        name="city"
                      />
                    </div>
                    <div className="flex-1">
                      <InputCom
                        label="Postcode / ZIP"
                        placeholder=""
                        inputClasses="w-full h-[50px]"
                        parentClasses=" border-slate-300"
                        value={input.zip}
                        inputHandler={inputHandler}
                        name="zip"
                      />
                    </div>
                  </div>
                  <div className=" mb-12">
                    <div className="w-full">
                      <InputCom
                        label="Address*"
                        placeholder="your address here"
                        inputClasses="w-full h-[50px]"
                        parentClasses={
                          !validation.validAddress
                            ? `border-red-600`
                            : " border-slate-300"
                        }
                        value={input.address}
                        inputHandler={inputHandler}
                        name="address"
                      />
                    </div>
                    <div className="w-full mt-10">
                      <TextArea
                        label="note"
                        placeholder="Additional information about the order"
                        inputClasses="w-full h-[150px]"
                        parentClasses=" border-slate-300"
                        value={input.note}
                        inputHandler={inputHandler}
                        name="note"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex space-x-2 items-center mb-10">
                      <div>
                        <input type="checkbox" name="" id="address" />
                      </div>
                      <label
                        htmlFor="address"
                        className="text-qblack text-[15px] select-none"
                      >
                        Agree to our Terms and Conditions
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                  Order Summary
                </h1>

                {cartItems && cartItems.length > 0 ? (
                  <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          Product
                        </p>
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          total
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                    <div className="product-list w-full mb-[30px]">
                      <ul className="flex flex-col space-y-5">
                        {cartItems.map((item, idx) => {
                          return (
                            <li key={idx}>
                              <div className="flex justify-between items-center">
                                <div>
                                  <h4 className="text-[15px] text-qblack mb-2.5">
                                    {item.product.name}
                                    <sup className="text-[13px] text-qgray ml-2 mt-2">
                                      x{item.quantity}
                                    </sup>
                                  </h4>
                                  <p className="text-[13px] text-qgray">
                                    {item.color}&nbsp;
                                    {item.size}
                                  </p>
                                </div>
                                <div>
                                  <span className="text-[15px] text-qblack font-medium">
                                    {Util.getAmountInCurrency(
                                      cartItems[0]?.currencyCode,
                                      item.product.price * item.quantity
                                    )}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                    <div className="w-full mt-[30px] flex sm:justify-end">
                      <div className=" w-full border border-[#EDEDED] px-[30px] py-[26px]">
                        <div className="sub-total mb-6">
                          <div className=" flex justify-between mb-6">
                            <p className="text-[15px] font-medium text-qblack">
                              Subtotal
                            </p>
                            <p className="text-[15px] font-medium text-qred">
                              <span>
                                {Util.getAmountInCurrency(
                                  cartItems[0]?.currencyCode,
                                  getComputedTotalAmount()
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                        </div>
                        <div className="sub-total mb-6">
                          <div className=" flex justify-between mb-6">
                            <p className="text-[15px] font-medium text-qblack">
                              Shipping fee
                            </p>
                            <p className="text-[15px] font-medium text-qred">
                              <span>
                                {Util.getAmountInCurrency(
                                  cartItems[0]?.currencyCode,
                                  getComputedShippingFees()
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                        </div>

                        {serviceCharge &&
                        serviceCharge.mode == "TRANSACTION" ? (
                          <div className="sub-total mb-6">
                            <div className=" flex justify-between mb-6">
                              <p className="text-[15px] font-medium text-qblack">
                                Service Charge
                              </p>
                              <p className="text-[15px] font-medium text-qred">
                                {/* Sum the service charge and total */}
                                <span>
                                  {Util.getAmountInCurrency(
                                    cartItems[0]?.currencyCode,
                                    getComputedTransactionCharge()
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="total mb-6">
                          <div className=" flex justify-between">
                            <p className="text-[18px] font-medium text-qblack">
                              Total
                            </p>
                            <p className="text-[18px] font-medium text-qred">
                              {Util.getAmountInCurrency(
                                cartItems[0].currencyCode,
                                getComputedTotalAmount() +
                                  getComputedShippingFees() +
                                  getComputedTransactionCharge()
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {authenticated ? (
                      <>
                        <form className="payment-options">
                          {selectedCurrency == "USD" ? (
                            <label className="option">
                              <input
                                type="radio"
                                value="Flutterwave"
                                name="option"
                                defaultChecked={paymentOption === "Flutterwave"}
                                onChange={(e) => {
                                  setPaymentOption(e.target.value);
                                }}
                              />{" "}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/flutterwave.png`}
                                alt="about"
                                className="w-full h"
                              />
                            </label>
                          ) : (
                            <>
                              <label className="option">
                                <input
                                  type="radio"
                                  value="Flutterwave"
                                  name="option"
                                  defaultChecked={
                                    paymentOption === "Flutterwave"
                                  }
                                  onChange={(e) => {
                                    setPaymentOption(e.target.value);
                                  }}
                                />{" "}
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/flutterwave.png`}
                                  alt="about"
                                  className="w-full h"
                                />
                              </label>
                              <label className="option">
                                <input
                                  type="radio"
                                  value="Paystack"
                                  name="option"
                                  defaultChecked={paymentOption === "Paystack"}
                                  onChange={(e) => {
                                    setPaymentOption(e.target.value);
                                  }}
                                />{" "}
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/paystack.png`}
                                  alt="about"
                                  className="w-full h"
                                />
                              </label>
                            </>
                          )}

                          {/* <label className="option">
                            <input
                              type="radio"
                              value="Squad"
                              name="option"
                              defaultChecked={paymentOption === "Squad"}
                              onChange={(e) => {
                                setPaymentOption(e.target.value);
                              }}
                            />{" "}
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/squad.png`}
                              alt="about"
                              className="w-full h"
                            />
                          </label> */}
                        </form>

                        <button
                          type="button"
                          onClick={!isProcessing ? proceedOrder : null}
                          className="rounded-lg mt-5 black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                        >
                          <span>
                            {isProcessing ? (
                              <ClipLoader
                                color="#fff"
                                size={30}
                                data-testid="loader"
                              />
                            ) : (
                              "Continue to Payment"
                            )}
                          </span>
                        </button>
                      </>
                    ) : (
                      <Link to="/login?redirect=checkout">
                        <div className="w-full h-[50px] black-btn flex justify-center items-center">
                          <span className="text-sm font-semibold">
                            Login to Place Order
                          </span>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
