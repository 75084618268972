import { http } from "../utils/http";
import Util from "../utils/util";

let chatService = {
  countNewMessages: async () => {
    return await http.get(
      `${Util.baseUrl}/api/messages/count`,
      Util.getAuthorizedHeaders()
    );
  },
  countNewMessagesBySenderAndRecipient: async (senderId, recipientId) => {
    return await http.get(
      `${Util.baseUrl}/api/messages/${senderId}/${recipientId}/count`,
      Util.getAuthorizedHeaders()
    );
  },
  findChatMessages: async (senderId, recipientId) => {
    return await http.get(
      `${Util.baseUrl}/api/messages/${senderId}/${recipientId}`,
      Util.getAuthorizedHeaders()
    );
  },
  findAllChatContacts: async (page) => {
    return await http.get(
      `${Util.baseUrl}/api/chat-contact/get_all?page=${page}&size=${Util.pageSize}`,
      Util.getAuthorizedHeaders()
    );
  },
  searchChatContacts: async (q, page) => {
    return await http.get(
      `${Util.baseUrl}/api/chat-contact/search?q=${q}&page=${page}&size=${Util.pageSize}`,
      Util.getAuthorizedHeaders()
    );
  },
  findChat: async (id) => {
    return await http.get(
      `${Util.baseUrl}/api/messages/${id}`,
      Util.getAuthorizedHeaders()
    );
  },
};
export { chatService };
