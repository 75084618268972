import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { orderService } from "../../services/order.service";
import { productService } from "../../services/product.service";
import Util from "../../utils/util";
import BreadcrumbCom from "../BreadcrumbCom";
import Error from "../Error";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import Layout from "../Partials/Layout";
import ProductView from "./ProductView";

export default function OrderDetail() {
  const [tab, setTab] = useState("des");
  const [hover, setHover] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [reviewLoading, setLoading] = useState(true);
  const reviewElement = useRef(null);
  const [isLoadingRelatedProducts, setIsLoadingRelatedProducts] =
    useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isLaoding, setIsLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState({});
  const { id } = useParams();

  const reviewAction = () => {
    setLoading(true);
    setTimeout(() => {
      if ((name, message, rating)) {
        setComments((prev) => [
          {
            id: Math.random(),
            author: name,
            comments: message,
            review: rating,
          },
          ...prev,
        ]);
        setLoading(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setRating(0);
        setHover(0);
        window.scrollTo({
          top: -reviewElement.current.getBoundingClientRect().top,
          left: 0,
          behavior: "smooth",
        });
      }
      setLoading(false);
      return false;
    }, 2000);
  };

  const getOrderDetail = () => {
    setIsLoading(true);
    orderService
      .getOrderByOrderId(id)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          console.log("order:", responseData);
          setOrder(responseData);
          getOrderDetailItems();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getOrderDetailItems = () => {
    setIsLoading(true);
    orderService
      .getOrderItemsByOrderId(id)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          console.log("order items:", responseData);
          setOrderItems(responseData);
          getRelatedProducts(responseData[0].product.subCategoryId);
          setProduct(responseData[0].product);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRelatedProducts = (subCategoryId) => {
    setIsLoadingRelatedProducts(true);
    productService
      .getProductsBySubCategory(0, subCategoryId)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          const rating = Util.calculateRating(responseData?.ratings);
          setRelatedProducts(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingRelatedProducts(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingRelatedProducts(false);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [id]);

  return (
    <>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="single-product-wrapper w-full ">
          <div className="product-view-main-wrapper bg-white pt-[30px] w-full">
            <div className="breadcrumb-wrapper w-full ">
              <div className="container-x mx-auto">
                <BreadcrumbCom
                  paths={[
                    { name: "home", path: "/" },
                    { name: "Order deails", path: "/order-detail" },
                  ]}
                />
              </div>
            </div>
            <div className="w-full bg-white pb-[60px]">
              <div className="container-x mx-auto">
                {isLaoding ? (
                  <ClipLoader />
                ) : orderItems && orderItems.length > 0 ? (
                  <ProductView items={orderItems} order={order} />
                ) : (
                  <Error message="Order items not found" />
                )}
              </div>
            </div>
          </div>

          <div
            className="product-des-wrapper w-full relative pb-[60px]"
            ref={reviewElement}
          >
            <div className="tab-buttons w-full mb-10 mt-5 sm:mt-0">
              <div className="container-x mx-auto">
                <ul className="flex space-x-12 ">
                  <li>
                    <span
                      onClick={() => setTab("des")}
                      className={`py-[15px] sm:text-[15px] text-sm sm:block border-b font-medium cursor-pointer ${
                        tab === "des"
                          ? "border-qyellow text-qblack "
                          : "border-transparent text-qgray"
                      }`}
                    >
                      Description
                    </span>
                  </li>
                </ul>
              </div>
              <div className="w-full h-[1px] bg-[#E8E8E8] absolute left-0 sm:top-[50px] top-[36px] -z-10"></div>
            </div>
            <div className="tab-contents w-full min-h-[400px] ">
              <div className="container-x mx-auto">
                {tab === "des" && (
                  <div data-aos="fade-up" className="w-full tab-content-item">
                    <h6 className="text-[18px] font-medium text-qblack mb-2">
                      Additional Information
                    </h6>
                    <p className="text-[15px] text-qgray text-normal mb-10">
                      {order?.note ? order?.note : "N/A"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {relatedProducts && relatedProducts.length > 0 ? (
            <div className="related-product w-full bg-white">
              <div className="container-x mx-auto">
                <div className="w-full py-[60px]">
                  <h1 className="sm:text-3xl text-xl font-600 text-qblacktext leading-none mb-[30px]">
                    Related Product
                  </h1>
                  <div
                    data-aos="fade-up"
                    className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5"
                  >
                    {relatedProducts.map((data) => {
                      return (
                        <div data-aos="fade-up" key={data.id} className="item">
                          <ProductCardStyleOne data={data} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Layout>
    </>
  );
}
