import { useEffect, useState } from "react";
import ProductCardStyleOne from "./Cards/ProductCardStyleOne";
import ViewMoreTitle from "./ViewMoreTitle";

export default function AdsProductSection({
  className,
  categoryTitle,
  sectionTitle,
  seeMoreUrl,
  products = [],
  categoryBackground,
}) {
  const [productLength, setLength] = useState(3);
  useEffect(() => {
    if (window.matchMedia("(max-width: 1024px)")) {
      setLength(2);
    }
  }, []);
  return (
    <div data-aos="fade-up" className={`section-style-one ${className || ""}`}>
      <ViewMoreTitle categoryTitle={sectionTitle} seeMoreUrl={seeMoreUrl}>
        <div className="products-section w-full">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-2 xl:gap-[30px] gap-5">
            {products.map((data) => {
              return (
                <div key={data.id} className="item">
                  <ProductCardStyleOne data={data} />
                </div>
              );
            })}
          </div>
        </div>
      </ViewMoreTitle>
    </div>
  );
}
