import { BsSendCheck } from "react-icons/bs";
import Layout from "../Partials/Layout";
export default function SuccessTicket() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto py-20">
          <div className="main-wrapper max-w-[800px] mx-auto">
            <div className="flex justify-center items-center py-4">
              <BsSendCheck size={90} className="text-green-600" />
            </div>

            <p className="text-2xl text-center">
              You Support Ticket is sent Successfull..!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
