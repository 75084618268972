import { useState } from "react";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "../../TextArea";
import Star from "../../icons/Star";
const RatingModal = ({ isProcessing, onClickHandler }) => {
  const [rating, setRating] = useState([0, 0, 0, 0, 0, 0]);
  const [rateTag, setRateTag] = useState("Bad");
  const [input, setInput] = useState({
    review: "",
    rate: 0,
    productId: "",
  });

  const fillRatingStar = (rate) => {
    var tempRating = [rating];
    for (let i = 0; i < rate; i++) {
      tempRating[i] = 1;
    }
    setRating(tempRating);
    setInput({ ...input, rate });
  };
  return (
    <>
      <div className="p-4">
        <div className="form-group__input mt-3">
          <div className="bellabanga-rating my-5">
            <div className="flex">
              <Star
                w="30"
                h="30"
                value={rating[0]}
                onClick={() => fillRatingStar(1)}
              />
              <Star
                w="30"
                h="30"
                value={rating[1]}
                onClick={() => fillRatingStar(2)}
              />
              <Star
                w="30"
                h="30"
                value={rating[2]}
                onClick={() => fillRatingStar(3)}
              />
              <Star
                w="30"
                h="30"
                value={rating[3]}
                onClick={() => fillRatingStar(4)}
              />
              <Star
                w="30"
                h="30"
                value={rating[4]}
                onClick={() => fillRatingStar(5)}
              />
              <Star
                w="30"
                h="30"
                value={rating[5]}
                onClick={() => fillRatingStar(6)}
              />
            </div>
          </div>
          <div className="mb-[100px]">
            <TextArea
              label="Write Review"
              value={input.review}
              inputHandler={(e) => {
                setInput({ ...input, review: e.target.value });
              }}
              placeholder="Describe your experiance (Optional)"
            />
          </div>
          <div className="text-right w-full">
            <button
              place="Continue"
              className="h-[40px] rounded bg-qyellow text-white  p-2 mr-2"
              onClick={() => onClickHandler(input)}
            >
              <span>
                {isProcessing ? (
                  <ClipLoader color="#fff" size={30} data-testid="loader" />
                ) : (
                  <span>Submit Rating</span>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default RatingModal;
