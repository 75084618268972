import { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { authService } from "../../../services/auth.service";
import { storage } from "../../../utils/storage";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";

export default function Otp() {
  const n2 = useRef();
  const n3 = useRef();
  const n4 = useRef();
  const n5 = useRef();
  const n6 = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [searchParams] = useSearchParams();
  const [input, setInput] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
    n6: "",
  });

  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value.charAt(0) });
    if (name == "n1") n2.current.focus();
    if (name == "n2") n3.current.focus();
    if (name == "n3") n4.current.focus();
    if (name == "n4") n5.current.focus();
    if (name == "n5") n6.current.focus();
  };
  const verify = () => {
    const request = {
      username: storage.get("email"),
      otp: input.n1 + input.n2 + input.n3 + input.n4 + input.n5 + input.n6,
    };
    if (request.otp.length == 6) {
      setIsProcessing(true);

      authService
        .verifyAccount(request)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success(result.data.message);
            setTimeout(() => {
              var redirect = location.search.split("=")[1];
              if (redirect && redirect == "fp") {
                navigate("/reset-password");
              } else {
                if (searchParams.get("r") == "vendor") {
                  const host = window.location.host.toLowerCase();
                  if (host == "bellabanga.com")
                    window.location.href = `https://dashboard.bellabanga.com/login`;
                  else if (host == "stage.bellabanga.com")
                    window.location.href = `https://stagedashboard.bellabanga.com/login`;
                  else navigate("/login");
                } else {
                  navigate("/login");
                }
              }
              // }
            }, 500);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          toast.error(
            e.response ? e.response.data.message : "Connection refused!"
          );
        });
    }
  };

  const resendOtp = () => {
    const request = {
      username: storage.get("email"),
    };
    setIsSending(true);
    authService
      .resendOTP(request)
      .then((result) => {
        setIsSending(false);
        if (result.data.success) {
          toast.success("OTP sent successfully");
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        setIsSending(false);
        toast.error(
          e.response ? e.response.data.message : "Connection refused!"
        );
      });
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <ToastContainer />
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full h-[500px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Enter your OTP
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="172"
                      height="29"
                      viewBox="0 0 172 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      color=""
                    >
                      <path
                        d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                        stroke="#86ca2a"
                      />
                    </svg>
                  </div>
                </div>
                <div className="input-area">
                  <div className="input-item mb-5 pb-6">
                    <div className="flex gap-4">
                      <InputCom
                        placeholder="0"
                        label=""
                        value={input.n1}
                        maxLength={1}
                        type="text"
                        name="n1"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                      <InputCom
                        placeholder="0"
                        label=""
                        value={input.n2}
                        inputRef={n2}
                        maxLength={1}
                        type="text"
                        name="n2"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                      <InputCom
                        placeholder="0"
                        label=""
                        value={input.n3}
                        inputRef={n3}
                        maxLength={1}
                        type="text"
                        name="n3"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                      <InputCom
                        placeholder="0"
                        label=""
                        value={input.n4}
                        inputRef={n4}
                        maxLength={1}
                        type="text"
                        name="n4"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                      <InputCom
                        placeholder="0"
                        label=""
                        type="text"
                        value={input.n5}
                        inputRef={n5}
                        maxLength={1}
                        name="n5"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                      <InputCom
                        placeholder="0"
                        label=""
                        type="text"
                        value={input.n6}
                        inputRef={n6}
                        maxLength={1}
                        name="n6"
                        inputHandler={inputHandler}
                        inputClasses="h-[50px] px-2 text-center input-otp"
                      />
                    </div>
                  </div>
                  <div className="signin-area mb-3.5">
                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={!isProcessing ? verify : null}
                        className="rounded-lg black-btn mb-6 text-sm text-white w-full h-[50px] px-2 text-center font-semibold flex justify-center bg-purple items-center"
                      >
                        <span>
                          {isProcessing ? (
                            <ClipLoader
                              color="#fff"
                              size={30}
                              data-testid="loader"
                            />
                          ) : (
                            "Continue"
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="signup-area flex justify-center">
                  <p className="text-base text-qgraytwo font-normal">
                    Not receive OTP yet ?
                    <button
                      className="ml-2 text-qblack"
                      onClick={!isSending ? resendOtp : null}
                    >
                      <span>
                        {isSending ? <span>Sending..</span> : "Resend"}
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/otp.png`}
                  className="w-[80%]"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
