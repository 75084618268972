import { useState } from "react";
import "./style.css";

export default function SelectBox({
  datas = [],
  className,
  label,
  action,
  children,
  value,
  parentClasses,
  labelClasses = "text-qgray text-[13px] font-normal",
}) {
  const [item, setItem] = useState(value ? value : datas[0]);
  const [toggle, setToggle] = useState(false);
  const handler = (e, val) => {
    if (action) {
      action(val);
    }
    setItem(val);
    setToggle(!toggle);
  };
  return (
    <>
      {datas.length > 0 && (
        <div className={`block relative w-full  `}>
          {label && (
            <label
              className={`input-label capitalize block  mb-2 ${
                labelClasses || ""
              }`}
              htmlFor={name}
            >
              {label}
            </label>
          )}
          <div
            className={`my-select-box  border ${className || ""} ${
              parentClasses || ""
            }`}
          >
            <button
              onClick={() => setToggle(!toggle)}
              type="button"
              className="my-select-box-btn "
            >
              {children ? children({ item }) : <span>{item}</span>}
            </button>
            {toggle && (
              <div
                className="click-away"
                onClick={() => setToggle(!toggle)}
              ></div>
            )}
            <div className={`my-select-box-section  ${toggle ? "open" : ""}`}>
              <ul className="list select-option-list">
                {datas.map((value) => (
                  <li
                    className={item === value ? "selected" : ""}
                    key={Math.random() + value}
                    onClick={(e) => handler(e, value)}
                  >
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
