import {
  BusinessOutlined,
  Chat,
  LocationCityOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { Link, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../Access";
import { categoryService } from "../../services/category.service";
import { productService } from "../../services/product.service";
import { userService } from "../../services/user.service";
import { storage } from "../../utils/storage";
import Util from "../../utils/util";
import BreadcrumbCom from "../BreadcrumbCom";
import ProductCardRowStyleTwo from "../Helpers/Cards/ProductCardRowStyleOne";
import Loader from "../Helpers/icons/Loader";
import Star from "../Helpers/icons/Star";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";

export default function Vendor() {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [elementsSize, setSize] = useState("0px");
  const [products, setProducts] = useState([]);
  const [vendor, setVendor] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  10;
  const [categories, setCategories] = useState([]);
  const [subCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingVendor, setIsLoadingVendor] = useState(true);
  const params = useParams();
  const search = location.search.split("=")[1];

  const getVendor = () => {
    setIsLoadingVendor(true);
    userService
      .findVendorByHandle(params?.handle)
      .then(async (result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          storage.saveObject("activeVendor", responseData);
          setVendor(responseData);
          ///fetch product based on vendor
          if (search && search.length > 0) {
            searchProducts(responseData?.id);
          } else {
            if (params?.categoryId && !params?.subCategoryId) {
              getProductsCategoryById(params?.categoryId);
            } else if (params?.subCategoryId) {
              getProductsSubCategoryById(params?.subCategoryId);
            } else {
              getProducts(responseData?.id);
            }
          }
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        setIsLoadingVendor(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingVendor(false);
      });
  };

  const getCategories = () => {
    setIsLoadingCategories(true);
    categoryService
      .findAllCategories()
      .then(async (result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setCategories(responseData);
          await responseData.forEach((category) => {
            category.subCategories.forEach((subCategory) => {
              subCategories.push(subCategory);
            });
          });
        }
      })
      .catch((error) => {
        setIsLoadingCategories(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingCategories(false);
      });
  };

  const getProducts = (id) => {
    setIsLoadingProducts(true);
    productService
      .findProductsByVendorId(id, currentPage)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const searchProducts = (id) => {
    setIsLoadingProducts(true);
    productService
      .searchProductsByVendor(id, search, 0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const getProductsCategoryById = (categoryId) => {
    setIsLoadingProducts(true);
    console.log("products by category");
    productService
      .findProductByCategoryId(categoryId, currentPage)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const getProductsSubCategoryById = (categoryId) => {
    setIsLoadingProducts(true);
    console.log("products by sub category");
    productService
      .findProductBySubCategoryId(categoryId, currentPage)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          console.log(responseData);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  useEffect(() => {
    getVendor();
    setSize(`0px`);
    if (categories.length <= 0) getCategories();
  }, [params, search]);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            {isLoadingVendor ? (
              <Loader />
            ) : vendor && vendor?.id ? (
              <div
                data-aos="fade-right"
                className="saller-info w-full mb-[40px] sm:h-[328px]  sm:flex justify-between items-center px-11 overflow-hidden relative py-10 sm:py-0"
                style={{
                  background: `url(${process.env.PUBLIC_URL}/assets/images/saller-cover.png) no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                <div className="store-status w-[230px] h-[60px] bg-qyellow flex justify-end items-center rounded-full absolute top-[30px] -left-[30px]">
                  <span className="text-[26px] font-600 mr-[30px]">
                    Store Open
                  </span>
                </div>

                <div className="saller-text-details sm:mt-[55px] mt-[100px]">
                  <ul>
                    <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                      <VerifiedOutlined />
                      <span>{vendor.isVerified ? "Official" : "Regular"}</span>
                    </li>
                    <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                      <BusinessOutlined />
                      <span>{vendor.accountType}</span>
                    </li>

                    <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                      <LocationCityOutlined />
                      <span>{vendor?.businessAddress || "N/A"}</span>
                    </li>
                    <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal mt-5">
                      <Link
                        className="h-[50px] bg-qorange rounded text-white text-center font-bold p-2 mr-2"
                        to={`/chat/${vendor.id}`}
                      >
                        <Chat /> Contact {vendor.businessName}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="saller-name lg:block hidden">
                  <h1 className="text-[60px] font-bold">
                    {vendor?.businessName}
                  </h1>
                  <div className="flex justify-center">
                    <div className="flex">
                      <Star w="30" h="30" />
                      <Star w="30" h="30" />
                      <Star w="30" h="30" />
                      <Star w="30" h="30" />
                      <Star w="30" h="30" />
                      {/* <span className="text-[22px] font-bold">(0)</span> */}
                    </div>
                  </div>
                </div>

                <div className="saller-logo mt-5 sm:mt-5">
                  <div className="flex sm:justify-center justify-start">
                    <div className="w-[170px] h-[170px] rounded-full  flex justify-center items-center rounded-full bg-white mb-1">
                      <img
                        src={`${Util.storageBaseURL}/${vendor?.dpLink}`}
                        alt="logo"
                        onError={Util.onImageError}
                        className=" w-full h-full rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex sm:justify-center justify-start">
                    <span className="text-[30px] font-medium text-center">
                      {vendor?.businessName}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <Erorr message="Vendor not available" />
            )}
            <BreadcrumbCom />
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-[270px]">
                {isLoadingCategories ? (
                  <ClipLoader />
                ) : (
                  <ProductsFilter
                    handle={params?.handle}
                    categories={categories}
                    className="mb-[30px]"
                  />
                )}

                {/* <div className="w-full bellabanga-hom hidden lg:block h-[295px] mt-10">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="w-full bellabanga-hom h-[164px] overflow-hidden mb-[40px]">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div> */}
              </div>

              {isLoadingProducts ? (
                <ClipLoader />
              ) : products && products.length > 0 ? (
                <div className="flex-1">
                  <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                    <div>
                      <p className="font-400 text-[13px]">
                        <span className="text-qgray"> Showing</span>{" "}
                        {currentPage + 1} of {totalPages}
                        &nbsp; result
                      </p>
                    </div>
                    {/* <div className="flex space-x-3 items-center">
                      <span className="font-400 text-[13px]">Sort by:</span>
                      <div className="flex space-x-3 items-center border-b border-b-qgray">
                        <span className="font-400 text-[13px] text-qgray">
                          Default
                        </span>
                        <span>
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1 1L5 5L9 1" stroke="#9A9A9A" />
                          </svg>
                        </span>
                      </div>
                    </div> */}
                    <button
                      type="button"
                      className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="grid xl:grid-cols-2 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
                    {products && products.length > 0 ? (
                      products.map((data) => {
                        return (
                          <div key={data.id} className="item">
                            <ProductCardRowStyleTwo data={data} />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></> // <Error
                //   message={
                //     search
                //       ? `Search ${search} not found`
                //       : `No Product Available`
                //   }
                // />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
