import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { authService } from "../../../services/auth.service";
import { storage } from "../../../utils/storage";
import Util from "../../../utils/util";
import InputCom from "../../Helpers/InputCom";
import CustomModal from "../../Helpers/Modal/CustomModal";
import InformationModal from "../../Helpers/Modal/Items/InformationModal";
import Layout from "../../Partials/Layout";
import Thumbnail from "./Thumbnail";

export default function Login() {
  const [checked, setValue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalVerificationOpen, setModalVerificationOpen] = useState(false);
  const [modalAccountReviewOpen, setModalAccountReviewOpen] = useState(false);
  const navigate = useNavigate();
  var redirect = location.search.split("=")[1];

  const [validation, setValidation] = useState({
    validUsername: true,
    validPassword: true,
  });
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const rememberMe = () => {
    setValue(!checked);
  };

  const inputHandler = (e) => {
    const name = e.target.id;
    const key = `valid${Util.capitalize(name)}`;
    setInput({ ...input, [name]: e.target.value });
    const result = Util.validate(validation, input, [name]);
    setValidation({ ...validation, ...result });
  };

  const signIn = async () => {
    const result = Util.validate(validation, input, ["username", "password"]);
    setValidation({ ...validation, ...result });

    if (validation.validUsername && validation.validPassword) {
      setIsProcessing(true);
      authService
        .userLogin(input)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            const user = result.data.user;
            toast.success("Login Successful");
            storage.save("user", JSON.stringify(result.data.user));
            storage.save("userToken", result.data.bearer);

            if (user.role == "USER") {
              setTimeout(() => {
                var redirect = location.search.split("=")[1];
                if (redirect) {
                  window.location.replace("/" + redirect);
                } else {
                  window.location.replace("/");
                }
              }, 500);
            } else {
              location.replace("https://dashboard.bellabanga.com");
            }
          } else {
            if (result.data.message.includes("Account not verified")) {
              setModalVerificationOpen(true);
            } else if (
              result.data.message.includes("Business account not approved")
            ) {
              setModalAccountReviewOpen(true);
            } else {
              toast.error(result.data.message);
            }
          }
        })
        .catch((e) => {
          const message = e.response
            ? e.response.data.message
            : "Connection refused!";
          setIsProcessing(false);

          if (message.includes("Account not verified")) {
            setModalVerificationOpen(true);
          } else if (message.includes("Business account not approved")) {
            setModalAccountReviewOpen(true);
          } else {
            toast.error(message ? message : "Invalid Username or Password");
          }
        });
    } else {
      toast.error("All fields required!");
    }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <ToastContainer />
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full h-[515px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Log In
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="172"
                      height="29"
                      viewBox="0 0 172 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                        stroke="#86ca2a"
                      />
                    </svg>
                  </div>
                </div>
                <div className="input-area">
                  <div className="input-item mb-5 pb-6">
                    <InputCom
                      placeholder="Email or Phone Number"
                      label="Username*"
                      name="username"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validUsername ? `border-red-600` : " "
                      }
                      value={input.username}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="input-item mb-5 pb-6">
                    <InputCom
                      placeholder="● ● ● ● ● ●"
                      label="Password*"
                      name="password"
                      type="password"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validPassword ? `border-red-600` : " "
                      }
                      value={input.password}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="forgot-password-area flex justify-between items-center mb-7">
                    <div className="remember-checkbox flex items-center space-x-2.5">
                      <button
                        onClick={rememberMe}
                        type="button"
                        className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                      >
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button>
                      <span
                        onClick={rememberMe}
                        className="text-base text-black"
                      >
                        Remember Me
                      </span>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-base text-qyellow"
                    >
                      Forgot Password
                    </Link>
                  </div>
                  <div className="signin-area mb-3.5">
                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={!isProcessing ? signIn : null}
                        className="rounded-lg black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                      >
                        <span>
                          {isProcessing ? (
                            <ClipLoader
                              color="#fff"
                              size={30}
                              data-testid="loader"
                            />
                          ) : (
                            "Sign In"
                          )}
                        </span>
                      </button>
                    </div>
                    {/* <div className="flex gap-4">
                      <a
                        href="#"
                        className="w-full border  h-[50px] flex space-x-3  justify-center bg-[#FAFAFA] items-center rounded-lg"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/glIcon.png`}
                          className="w-8"
                        ></img>

                        <span className="text-[12px] text-qgraytwo font-normal">
                          Sign In with Google
                        </span>
                      </a>
                      <a
                        href="#"
                        className="w-full border  h-[50px] flex space-x-3  justify-center bg-[#FAFAFA] items-center rounded-lg"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/fbIcon.png`}
                          className="w-8"
                        ></img>

                        <span className="text-[12px] text-qgraytwo font-normal">
                          Sign In with Facebook
                        </span>
                      </a>
                    </div> */}
                  </div>
                  <div className="signup-area flex justify-center">
                    <p className="text-base text-qgraytwo font-normal">
                      Dont’t have an account ?
                      <Link
                        to={
                          redirect ? `/signup?redirect=${redirect}` : "/signup"
                        }
                        className="ml-2 text-qblack"
                      >
                        Sign up free
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CustomModal
              title="Verification Required"
              open={modalVerificationOpen}
              width={400}
              onClose={() => setModalVerificationOpen(false)}
            >
              <InformationModal
                message={`We have sent you an OTP to your email address?`}
                isProcessing={isProcessing}
                onAcceptHandlaer={() => {
                  storage.save("email", input.username);
                  var redirect = location.search.split("=")[1];
                  if (redirect) {
                    navigate("/otp?redirect=" + redirect);
                  } else {
                    navigate("/otp");
                  }
                }}
              />
            </CustomModal>

            <CustomModal
              title="Account Under Review"
              open={modalAccountReviewOpen}
              width={400}
              onClose={() => setModalAccountReviewOpen(false)}
            >
              <InformationModal
                message={`Your Business account is under review?`}
                isProcessing={isProcessing}
                onAcceptHandlaer={() => setModalAccountReviewOpen(false)}
              />
            </CustomModal>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <Thumbnail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
