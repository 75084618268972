import { http } from "../utils/http";
import Util from "../utils/util";

let analyticService = {
  findUserAnalyticCounts: async (id) => {
    return await http.get(
      `${Util.baseUrl}/api/analytic/count`,
      Util.getAuthorizedHeaders()
    );
  },
};
export { analyticService };
