import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { cartService } from "../../services/cart.service";
import { wishlistService } from "../../services/wishlist.service";
import { storage } from "../../utils/storage";
import Util from "../../utils/util";
import SelectBox from "../Helpers/SelectBox";
import Star from "../Helpers/icons/Star";
export default function ProductView({ className, reportHandler, product }) {
  const [images, setImages] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const sizes = product?.size || [];
  const [src, setSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState({
    color: null,
    size: selectedSize,
    quantity: quantity,
    product: product,
  });

  useEffect(() => {
    if (product && product?.images) {
      const imageArray = product?.images.split(",").filter(Boolean);
      setImages(imageArray);
    }

    if (product && product?.colors) {
      const colorArray = product?.colors.split(",").filter(Boolean);
      setColors(colorArray);
    }
    if (product && product?.size) {
      const sizeArray = product.size?.split(",").filter(Boolean);
      setColors(sizeArray);
    }

    setCart({ ...cart, product });
  }, [product]);

  const changeImgHandler = (current) => {
    setSrc(current);
  };
  const increment = () => {
    setQuantity((prev) => prev + 1);
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const addToCart = () => {
    product.cartId = Math.random();
    setCart({ ...cart, quantity: quantity, product: product });
    setIsLoading(true);
    toast.success("Added to Cart");
    setTimeout(() => {
      setIsLoading(false);
      cartService.addToCart(cart);

      setQuantity(1);
      setCart({
        ...cart,
        color: null,
        size: null,
        currencyCode: storage.get("currencyCode") || "USD",
        quantity: 1,
      });
    }, 1000);
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const addWishlist = async () => {
    if (authenticated) {
      setIsProcessing(true);
      wishlistService
        .addWishList(data?.id)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success("Added to wishlist");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error(error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      var redirect = location.search.split("=")[1];
      location.href = `login?redirect=${redirect}`;
    }
  };
  const url = `${location.host}/product-detail/${product?.id}`;
  return (
    <>
      {!product || !product.id ? (
        <ClipLoader />
      ) : (
        <div
          className={`product-view w-full lg:flex justify-between ${
            className || ""
          }`}
        >
          <div
            data-aos="fade-right"
            className="lg:w-1/2 xl:mr-[70px] lg:mr-[50px]"
          >
            <div className="w-full">
              <div className="w-full h-[600px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
                <img
                  src={`${Util.storageBaseURL}/${src || product?.thumbnail}`}
                  onError={Util.onImageError}
                  alt=""
                  className="w-full"
                />
                <div className="w-[40px] h-[40px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-sm font-medium absolute left-[30px] top-[30px]">
                  <span>-5%</span>
                </div>
              </div>
              <div className="flex gap-2 flex-wrap">
                {images &&
                  images.length > 0 &&
                  images.map((img, index) => (
                    <div
                      onClick={() => changeImgHandler(img)}
                      key={index}
                      className="w-[110px] h-[90px]  border border-qgray-border cursor-pointer"
                    >
                      <img
                        src={`${Util.storageBaseURL}/${img}`}
                        alt=""
                        onError={Util.onImageError}
                        className={`w-[110px] h-[90px]`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="product-details w-full mt-10 lg:mt-0">
              <span
                data-aos="fade-up"
                className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
              >
                {product?.category}
              </span>
              <p
                data-aos="fade-up"
                className="text-xl font-medium text-qblack mb-4"
              >
                {product?.name}
              </p>

              <div
                data-aos="fade-up"
                className="flex space-x-[10px] items-center mb-6"
              >
                <div className="flex">
                  {Util.calculateRating(product.ratings).map((item, idx) => (
                    <span key={idx + Math.random()}>
                      <Star value={item} />
                    </span>
                  ))}
                </div>
                <span className="text-[13px] font-normal text-qblack">
                  {product?.ratings}
                </span>
              </div>

              <div
                data-aos="fade-up"
                className="flex space-x-2 items-center mb-7"
              >
                <span className="text-sm font-500 text-qgray line-through mt-2">
                  {Util.decodeCurrency(
                    product?.currencyCode,
                    product?.price + 5 * (product?.price / 100)
                  )}{" "}
                </span>
                <span className="text-2xl font-500 text-qred">
                  {Util.decodeCurrency(product?.currencyCode, product?.price)}
                </span>
              </div>

              <p
                data-aos="fade-up"
                className="text-qgray text-sm text-normal mb-[30px] leading-7"
              >
                {product?.description}
              </p>

              {colors && colors.length > 0 ? (
                <div data-aos="fade-up" className="colors mb-[30px]">
                  <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                    COLORS
                  </span>
                  <div className="flex space-x-4 items-center">
                    {colors.map((color, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setCart({ ...cart, color });
                        }}
                        type="button"
                        style={{ "--tw-ring-color": `${color}` }}
                        className="w-[20px] h-[20px]  rounded-full focus:ring-2 ring-offset-2 flex justify-center items-center"
                      >
                        <span
                          style={{ background: `${color}` }}
                          className="w-[20px] h-[20px] block rounded-full border"
                        ></span>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {sizes && sizes.length > 0 ? (
                <div className="product-size mb-[30px]">
                  <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                    SIZES
                  </span>
                  <div className="w-full">
                    <SelectBox
                      className="w-full"
                      datas={sizes.map((size) => sizeMap[size])}
                      selectedItem={selectedSize}
                      onItemClick={(size) => setSelectedSize(size)}
                    >
                      {({ item }) => (
                        <>
                          <div>
                            <span className="text-[13px] text-qblack">
                              {item}
                            </span>
                          </div>
                        </>
                      )}
                    </SelectBox>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                data-aos="fade-up"
                className="quantity-card-wrapper w-full flex items-center h-[50px] space-x-[10px] mb-[30px]"
              >
                <div className="w-[120px] h-full px-[26px] flex items-center border border-qgray-border">
                  <div className="flex justify-between items-center w-full">
                    <button
                      onClick={decrement}
                      type="button"
                      className="text-base text-qgray"
                    >
                      -
                    </button>
                    <span className="text-qblack">{quantity}</span>
                    <button
                      onClick={increment}
                      type="button"
                      className="text-base text-qgray"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="w-[60px] h-full flex justify-center items-center border border-qgray-border">
                  <button
                    onClick={addWishlist}
                    className="cursor-pointer"
                    type="button"
                  >
                    <span>
                      {isProcessing ? (
                        <ClipLoader />
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                            stroke="#D5D5D5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="square"
                          />
                        </svg>
                      )}
                    </span>
                  </button>
                </div>
                <div className="flex-1 h-full">
                  <button
                    onClick={addToCart}
                    type="button"
                    className="black-btn text-sm font-semibold w-full h-full"
                  >
                    {isLoading ? <ClipLoader /> : <span>Add To Cart</span>}
                  </button>
                </div>
              </div>

              <div data-aos="fade-up" className="mb-[20px]">
                <p className="text-[13px] text-qgray leading-7">
                  <span className="text-qblack">Category :</span>{" "}
                  {product?.categoryName}
                </p>
                <p className="text-[13px] text-qgray leading-7">
                  <span className="text-qblack">Sub Category :</span>{" "}
                  {product?.subCategoryName}
                </p>
                {product?.brand ? (
                  <p className="text-[13px] text-qgray leading-7">
                    <span className="text-qblack">Brand:</span> {product?.brand}
                  </p>
                ) : (
                  <></>
                )}
              </div>

              <div
                data-aos="fade-up"
                className="flex space-x-2 items-center mb-[20px]"
              >
                <span>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 0C0.247634 0 0.475436 0 0.729172 0C0.738324 0.160174 0.747477 0.316279 0.757647 0.493233C1.05816 0.392044 1.33885 0.282211 1.62818 0.203395C3.11296 -0.201361 4.51385 0.0366111 5.84202 0.779512C6.47661 1.13494 7.14171 1.39071 7.86987 1.47207C8.88125 1.58496 9.82093 1.35817 10.7098 0.88426C10.9335 0.765274 11.1522 0.636627 11.411 0.491199C11.4161 0.606117 11.4237 0.693577 11.4237 0.780529C11.4242 3.18822 11.4222 5.5954 11.4288 8.00309C11.4293 8.1892 11.3718 8.29089 11.2096 8.38039C9.31956 9.42279 7.4285 9.43499 5.54557 8.37734C4.06231 7.54443 2.55363 7.43307 0.992568 8.13835C0.804428 8.22327 0.737816 8.33005 0.739341 8.53904C0.749003 9.9206 0.744426 11.3027 0.744426 12.6842C0.744426 12.7849 0.744426 12.8851 0.744426 13C0.48764 13 0.254244 13 0 13C0 8.67582 0 4.34961 0 0Z"
                      fill="#EB5757"
                    />
                  </svg>
                </span>

                <button
                  type="button"
                  onClick={reportHandler}
                  className="text-qred font-semibold text-[13px]"
                >
                  Report This Item
                </button>
              </div>

              <div
                data-aos="fade-up"
                className="social-share flex  items-center w-full"
              >
                <span className="text-qblack text-[13px] mr-[17px] inline-block">
                  Share This
                </span>

                <div className="flex space-x-5 items-center">
                  <a
                    className="cursor-pointer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=Check out this ${product?.name} on bellabanga e-commerce..`}
                  >
                    <span>
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 16V9H0V6H3V4C3 1.3 4.7 0 7.1 0C8.3 0 9.2 0.1 9.5 0.1V2.9H7.8C6.5 2.9 6.2 3.5 6.2 4.4V6H10L9 9H6.3V16H3Z"
                          fill="#3E75B2"
                        />
                      </svg>
                    </span>
                  </a>
                  <a
                    className="cursor-pointer"
                    href={`https://api.whatsapp.com/send?text=${url} Check out this ${product?.name} on bellabanga e-commerce..`}
                  >
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/color/48/whatsapp--v1.png"
                      alt="whatsapp--v1"
                    />
                  </a>
                  <a
                    className="cursor-pointer"
                    href={`https://twitter.com/intent/tweet?url=${url}&text=Check out this ${product?.name} on bellabanga e-commerce..`}
                  >
                    <span>
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0722 1.60052C16.432 1.88505 15.7562 2.06289 15.0448 2.16959C15.7562 1.74278 16.3253 1.06701 16.5742 0.248969C15.8985 0.640206 15.1515 0.924742 14.3335 1.10258C13.6933 0.426804 12.7686 0 11.7727 0C9.85206 0 8.28711 1.56495 8.28711 3.48557C8.28711 3.7701 8.32268 4.01907 8.39382 4.26804C5.51289 4.12577 2.9165 2.73866 1.17371 0.604639C0.889175 1.13814 0.71134 1.70722 0.71134 2.34742C0.71134 3.5567 1.31598 4.62371 2.27629 5.26392C1.70722 5.22835 1.17371 5.08608 0.675773 4.83711V4.87268C0.675773 6.5799 1.88505 8.00258 3.48557 8.32268C3.20103 8.39382 2.88093 8.42938 2.56082 8.42938C2.34742 8.42938 2.09845 8.39382 1.88505 8.35825C2.34742 9.74536 3.62784 10.7768 5.15722 10.7768C3.94794 11.7015 2.45412 12.2706 0.818041 12.2706C0.533505 12.2706 0.248969 12.2706 0 12.2351C1.56495 13.2309 3.37887 13.8 5.37062 13.8C11.8082 13.8 15.3294 8.46495 15.3294 3.84124C15.3294 3.69897 15.3294 3.52113 15.3294 3.37887C16.0052 2.9165 16.6098 2.31186 17.0722 1.60052Z"
                          fill="#3FD1FF"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
