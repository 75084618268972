import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { currencyService } from "../../../../services/currency.service";
import CurrencyBox from "../../../Helpers/CurrencyBox";
import SelectBox from "../../../Helpers/SelectBox";
import Arrow from "../../../Helpers/icons/Arrow";

export default function TopBar({ className }) {
  const userSession = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const [data, setData] = useState([
    { name: "USD", code: "USD" },
    { name: "NGN", code: "NGN" },
    { name: "GBP", code: "GBP" },
  ]);

  const getCurrencies = () => {
    setIsLoading(true);
    currencyService
      .findAllActiveCurrencies()
      .then((result) => {
        console.log(result);
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setData(responseData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <>
      <div
        className={`w-full bg-qyellow h-10 border-b border-qgray-border ${
          className || ""
        }`}
      >
        <div className="container-x mx-auto h-full">
          <div className="flex justify-between items-center h-full">
            <div className="topbar-nav">
              <ul className="flex space-x-6">
                <li className="bellabanga-hom">
                  <Link to="/about">
                    <span className="text-xs leading-6 text-white font-500 ">
                      About Us
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/tracking-order">
                    <span className="text-xs leading-6 text-white font-500">
                      Track Order
                    </span>
                  </Link>
                </li>
                <li>
                  <a href="mailto:support@bellabanga.com">
                    <div className="flex gap-1 pt-2 xl:pt-0 ">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/emailIcon.svg`}
                        className="w-4"
                      ></img>
                      <span className=" hidden xl:block text-xs leading-6 text-white font-500">
                        support@bellabanga.com
                      </span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="tel:+2348000000000">
                    <div className="flex gap-1 pt-1 xl:pt-0">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/whatsappIcon.svg`}
                        className="w-4"
                      ></img>
                      <span className="hidden xl:block text-xs leading-6 text-white font-500">
                        +234 704 785 0929
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="topbar-dropdowns">
              <div className="flex space-x-2">
                <div className="currency-select  flex space-x-1 items-center">
                  <CurrencyBox className="w-fit" datas={data} />
                  <Arrow className="fill-current text-white" />
                </div>
                <div className="language-select flex space-x-1 items-center ">
                  <SelectBox
                    className="w-fit border-none"
                    datas={["English"]}
                  />
                  <Arrow className="fill-current text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
