import { useState } from "react";
import { cartService } from "../../services/cart.service";
import Util from "../../utils/util";
import InputQuantityCom from "../Helpers/InputQuantityCom";
import RemoveIcon from "../Helpers/icons/RemoveIcon";

export default function ProductsTable({ className, cartItems, setCartItems }) {
  const [quantity, setQuantity] = useState(1);

  const increment = (product) => {
    cartItems.forEach((item) => {
      if (item.product.cartId == product.cartId) {
        item.quantity += 1;
      }
    });
    cartService.saveCart(cartItems);
    getCartItems();
  };

  const decrement = (product) => {
    cartItems.forEach((item) => {
      if (item.product.cartId == product.cartId) {
        if (item.quantity > 1) item.quantity -= 1;
      }
    });
    cartService.saveCart(cartItems);
    getCartItems();
  };

  const changeSize = (e, item) => {
    const value = e.target.value;
    item.size = value;
    cartService.saveCart(cartItems);
    getCartItems();
  };

  const changeColor = (e, item) => {
    const value = e.target.value;
    item.color = value;
    cartService.saveCart(cartItems);
    getCartItems();
  };

  const getCartItems = () => {
    let cart = cartService.getItems();
    console.log("cart", cart);
    setCartItems(cart);
  };

  const removeCartItem = (item) => {
    cartService.removeCartItem(item);
    getCartItems();
  };

  return (
    <div className={`w-full ${className || ""}`}>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        {cartItems && cartItems != null ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tbody>
              {/* table heading */}
              <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                  product
                </td>
                <td className="py-4 whitespace-nowrap text-center">price</td>
                <td className="py-4 whitespace-nowrap  text-center">
                  quantity
                </td>
                <td className="py-4 whitespace-nowrap  text-center">total</td>
                <td className="py-4 whitespace-nowrap text-right w-[114px]"></td>
              </tr>
              {cartItems.map((item, idx) => {
                return (
                  <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                    <td className="pl-10  py-4  w-[380px]">
                      <div className="flex space-x-6 items-center">
                        <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                          <img
                            src={`${Util.storageBaseURL}/${item.product.thumbnail}`}
                            onError={Util.onImageError}
                            alt="product"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="flex-1 flex flex-col">
                          <p className="font-medium text-[15px] text-qblack">
                            {item.product.name}
                            <small>
                              {item?.unit ? ` / ${item?.unit}` : ``}
                            </small>
                            <p>{"color: " + item?.color ? item.color : ""}</p>
                            <p>{"size: " + item?.size ? item.size : ""}</p>
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className="text-center py-4 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {Util.getAmountInCurrency(
                            cartItems[0].currencyCode,
                            item.product.price
                          )}
                        </span>
                      </div>
                    </td>
                    <td className=" py-4">
                      <div className="flex justify-center items-center">
                        <InputQuantityCom
                          product={item.product}
                          increment={increment}
                          decrement={decrement}
                          quantity={item.quantity}
                        />
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {Util.getAmountInCurrency(
                            cartItems[0]?.currencyCode,
                            Number(item.product.price) * Number(item.quantity)
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-4">
                      <div className="flex space-x-1 items-center justify-center">
                        <RemoveIcon onClick={() => removeCartItem(item)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
