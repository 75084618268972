import { v4 as uuidv4 } from "uuid";
import { storage } from "./storage";

const Util = {
  pageSize: 50,
  whitelistedCountries: ["United Kingdom", "United States", "Nigeria"],
  //prod
  baseUrl: "https://service.phopis.com/bellabanga",
  storageBaseURL: "https://bellabanga.s3.amazonaws.com",
  flutterwaveKey: "FLWPUBK_TEST-3f74bf90d07b6f3be179d49f5ed7b87e-X",
  paystackKey: "pk_live_cad14c55dfcfe78a59886dae3faa535593d80d32",

  //test
  // baseUrl: "https://service.phopis.com/test-bellabanga",
  // storageBaseURL: "https://sauki-storage.s3.amazonaws.com/pictures",
  // flutterwaveKey: "FLWPUBK_TEST-3f74bf90d07b6f3be179d49f5ed7b87e-X",
  // paystackKey: "pk_test_3391ff5b9f03d0bfdb8ec1685713bb31b0bfa014",

  getHeaders: () => {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
      },
    };
  },
  getAuthorizedHeaders: () => {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${storage.get("userToken")}`,
      },
    };
  },
  getAuthorizedFileHeaders: () => {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${storage.get("userToken")}`,
      },
    };
  },
  getFileHeaders: () => {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json; charset=utf-8",
      },
    };
  },
  decodeStatus: (status) => {
    if (status == "AC") return "Active";
    if (status == "IA") return "InActive";
    if (status == "PV") return "Pending Verification";
    if (status == "PA") return "Pending Activation";
    if (status == "PP") return "Pending Payment";
    if (status == "PC") return "Pending Confirmation";
    if (status == "AD") return "Arrived to Destination";
    if (status == "OC") return "Confirmed";
    if (status == "RJ") return "Rejected";
    if (status == "CA") return "Canceled";
    if (status == "DP") return "Shipping";
    if (status == "DV") return "Delivered";
    if (status == "RC") return "Received";
  },
  onImageError: (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/favicon.ico`;
  },
  onProfileDPError: (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/favicon.ico`;
  },
  formatDate: (data) => {
    return new Date(data).toDateString();
  },
  getFormatedDate: (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  },
  getRandomString: () => {
    const id = uuidv4();
    const uid = id.split("-").join("");
    return uid.substring(0, 11).toLowerCase();
  },
  decodeMessage: (message) => {
    if (message.includes("<") && message.includes(">")) {
      const attachementData = message.substring(
        message.indexOf("<"),
        message.lastIndexOf(">") + 1
      );

      const attachementList = attachementData.split(" ");
      const format = [".jpg", ".png", ".jpeg", ".gif", ".svg"];
      var files = "";

      attachementList.forEach((file) => {
        var actualFile = file.substring(1, file.length - 1);
        var fileSrc = `${Util.storageBaseURL}/${actualFile}`;
        var fileExtension = actualFile.substring(
          actualFile.lastIndexOf("."),
          actualFile.length
        );
        var isImage = format.includes(fileExtension);
        if (!isImage)
          fileSrc = `${process.env.PUBLIC_URL}/assets/images/file.png`;

        files += `<a href="${fileSrc}" download="${actualFile}" target="_BLANK"><img class="w-24 h-auto" onError="${Util.onImageError}" src="${fileSrc}"></a>`;
      });
      return `<div class="block">${
        message || ""
      }</div> <div class="flex flex-row gap-2 overflow-auto">${files}</div>`;
    } else {
      return message;
    }
  },
  absMessageContent: (message) => {
    if (message.includes("<") && message.includes(">")) {
      message = message.substring(0, message.indexOf("<") - 1);
      if (message.trim() == "") {
        message = "New File";
      }
      return `${message || ""}`;
    } else {
      return message;
    }
  },
  convertCurrency: (amount, fromCurrency, toCurrency) => {
    var rates = storage.getObject("rate") || null;
    var rate = rates.find(
      (rate) =>
        (rate.fromCode == fromCurrency && rate.toCode == toCurrency) ||
        (rate.fromCode == toCurrency && rate.toCode == fromCurrency)
    );
    if (!rate) {
      return amount;
    } else {
      if (
        fromCurrency == "NGN" ||
        (fromCurrency == "USD" && toCurrency == "GBP")
      ) {
        amount = amount / rate.rate;
      } else {
        amount = amount * rate.rate;
      }
      return amount.toFixed(2);
      //end of currency convert
    }
  },
  decodeCurrency: (productCurrency, amount) => {
    var currenctCode = storage.get("currencyCode") || "USD";
    var absAmount = amount;
    if (productCurrency !== currenctCode)
      absAmount = Util.convertCurrency(amount, productCurrency, currenctCode);

    var absAmount = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currenctCode,
    }).format(absAmount);

    absAmount = absAmount.replace(" ", "");
    absAmount = absAmount.replace("NGN", "₦");
    return absAmount;
  },
  convertCurrencyToPrefered: (productCurrency, amount) => {
    var currenctCode = storage.get("currencyCode") || "USD";
    var absAmount = amount;
    if (productCurrency !== currenctCode)
      absAmount = Util.convertCurrency(amount, productCurrency, currenctCode);

    var absAmount = Intl.NumberFormat("en-US", {
      style: "decimal",
      currency: currenctCode,
      useGrouping: false,
    }).format(absAmount);
    return absAmount;
  },
  getAmountInCurrency: (currenctCode, amount) => {
    var absAmount = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currenctCode,
    }).format(amount);

    absAmount = absAmount.replace(" ", "");
    absAmount = absAmount.replace("NGN", "₦");
    return absAmount;
  },

  calculateRating: (rate) => {
    const tempRating = [];
    for (let i = 1; i <= 5; i++) {
      if (rate >= i) {
        tempRating.push(1);
      } else {
        tempRating.push(0);
      }
    }
    return tempRating;
  },
  onImageError: (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/favicon.ico`;
  },
  capitalize: (str) => {
    return str[0].toUpperCase() + str.slice(1);
  },
  capitalize: (str) => {
    if (typeof str !== "string" || !str) {
      return "";
    }
    return str[0].toUpperCase() + str.slice(1);
  },
  validate: (validation, input, keys) => {
    keys.forEach((key) => {
      let validationKey = `valid${Util.capitalize(key)}`;
      let value = input[key];

      if (value?.length < 3) validation[validationKey] = false;
      else validation[validationKey] = true;

      if (key == "name" || key == "personalName" || key == "businessName")
        if (!Util.validName(value)) validation[validationKey] = false;
        else validation[validationKey] = true;

      if (key == "mobile")
        if (!Util.validateMobile(value)) validation[validationKey] = false;
        else validation[validationKey] = true;

      if (key == "email" || key == "businessEmail")
        if (!Util.validateEmail(value)) validation[validationKey] = false;
        else validation[validationKey] = true;

      if (key == "confirmPassword") {
        if (value !== input["password"]) validation[validationKey] = false;
        else validation[validationKey] = true;
      }
    });
    return validation;
  },
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  validateMobile: (mobile) => {
    return String(mobile)
      .toLowerCase()
      .match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
  },
  validName: (name) => {
    return String(name).match(
      /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/
    );
  },
};

export default Util;
