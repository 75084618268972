export default function TextArea({
  label,
  type,
  name,
  inputRef,
  placeholder,
  children,
  inputHandler,
  value,
  inputClasses,
  parentClasses,
  labelClasses = "text-qgray text-[13px] font-normal ",
}) {
  return (
    <div className="input-com w-full h-12">
      {label && (
        <label
          className={`input-label capitalize block  mb-2 ${labelClasses || ""}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div
        className={`input-wrapper border w-full h-[100px] overflow-hidden relative rounded-lg ${
          parentClasses || ""
        }`}
      >
        <textarea
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={inputHandler}
          className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none  ${
            inputClasses || ""
          }`}
          type={type}
          id={name}
        />
        {children && children}
      </div>
    </div>
  );
}
