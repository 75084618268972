import {
  BusinessCenterOutlined,
  OnlinePredictionOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { userService } from "../../services/user.service";
import Util from "../../utils/util";
import ChevronRightIcon from "../Helpers/icons/ChevroRightIcon";
import Loader from "../Helpers/icons/Loader";
import Star from "../Helpers/icons/Star";
import Layout from "../Partials/Layout";

export default function Vendors() {
  const [vendors, setVendors] = useState([]);
  const [isLoadingVendor, setIsLoadingVendor] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const search = location.search.split("=")[1];

  const getVendors = () => {
    setIsLoadingVendor(true);
    userService
      .findAllVendors(currentPage)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setTotalPages(result.data.payload.totalPages);
          setVendors(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingVendor(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingVendor(false);
      });
  };

  const searchVendor = () => {
    setIsLoadingVendor(true);
    userService
      .searchVendors(search, currentPage)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setTotalPages(result.data.payload.totalPages);
          setVendors(responseData);
        } else {
          setVendors([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setVendors([]);
        setIsLoadingVendor(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingVendor(false);
      });
  };

  useEffect(() => {
    if (search && search.length > 0) {
      searchVendor();
    } else {
      getVendors();
    }
  }, [search]);
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div
        data-aos="fade-up"
        className="/vendors-widget w-full p-[30px] bg-white mb-[30px] px-12"
      >
        <h1 className="text-[22px] text-qblack font-bold mb-5">All Vendors</h1>
      </div>
      <div className="content-wrapper w-full mb-[60px]">
        {isLoadingVendor ? (
          <Loader />
        ) : vendors && vendors.length > 0 ? (
          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-[30px] gap-5 px-12">
            {vendors.map((vendor, idx) => (
              <div key={idx} data-aos="fade-up" className="item w-full">
                <div
                  className="w-full sm:h-[328px] sm:p-[30px] p-5"
                  style={{
                    background: `url(${process.env.PUBLIC_URL}/assets/images/sallers-cover-4.png) no-repeat`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="flex sm:flex-row flex-col-reverse sm:items-center justify-between w-full h-full">
                    <div className="flex flex-col justify-between h-full">
                      <div className="">
                        <div className="flex space-x-2 items-center mb-[30px]">
                          <div className="flex">
                            <Star w="24" h="24" />
                            <Star w="24" h="24" />
                            <Star w="24" h="24" />
                            <Star w="24" h="24" />
                            <Star w="24" h="24" />
                          </div>
                          {/* <span className="text-[15px] font-bold text-qblack ">
                            (4.7)
                          </span> */}
                        </div>
                        <div className="saller-text-details">
                          <ul>
                            <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                              <VerifiedOutlined />
                              <span>
                                {vendor.isVerified ? "Official" : "Regular"}
                              </span>
                            </li>
                            <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                              <BusinessCenterOutlined />
                              <span>{vendor.accountType}</span>
                            </li>
                            <li className="text-black flex space-x-5 items-center leading-9 text-base font-normal">
                              <OnlinePredictionOutlined />
                              <span>Online</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="w-[120px] h-[40px]">
                        <Link to={`/${vendor.handle}`} className="yellow-btn">
                          <span>Shop Now</span>
                          <span className="px-5">
                            <ChevronRightIcon />
                          </span>
                        </Link>
                      </div>
                    </div>

                    <div>
                      <div className="flex sm:justify-center justify-start">
                        <div className="w-[170px] h-[170px] rounded-full bg-white mb-[20px] flex justify-center items-center">
                          <img
                            className="img round-image"
                            src={`${Util.storageBaseURL}/${vendor.dpLink}`}
                            onError={Util.onImageError}
                            alt=""
                          />
                        </div>
                      </div>

                      <h1 className="sm:block hidden  font-semibold  text-qblack text-center leading-none">
                        {vendor.businessName}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="custom-error-message text-center my-3 border mx-12">
            {search ? `Search ${search} not found` : "No vendor available"}
          </div>
        )}
      </div>
    </Layout>
  );
}
