import { useContext, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { productService } from "../../services/product.service";
import { storage } from "../../utils/storage";
import Util from "../../utils/util";
import InputCom from "../Helpers/InputCom";
import Star from "../Helpers/icons/Star";
import { UserContext } from "./../../Access";
import Error from "../Error";
export default function Reviews({ product }) {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const isAuthenticated = currentUser?.id ? true : false;
  const [isLaodingReviews, setIsLoadingReviews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [checked, setValue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [input, setInput] = useState({
    name: currentUser?.name,
    email: currentUser?.personalEmail,
    phone: currentUser?.mobile,
    review: "",
    rate: 0,
    productId: product.id,
    userId: currentUser?.id,
    sellerId: product?.vendorId,
  });
  const [validation, setValidation] = useState({
    validEmail: true,
    validName: true,
    validPhone: true,
    validReview: true,
  });

  const getProductReviews = () => {
    setIsLoadingReviews(true);
    productService
      .getAllProductRiviewsById(product?.id) // Check if `product.id` is correctly passed
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setReviews(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingReviews(false);
        console.error("Error fetching reviews:", error);
        toast.error("Failed to fetch reviews");
      })
      .finally(() => {
        setIsLoadingReviews(false);
      });
  };
  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value });
    Util.validate(validation, input, [name]);
  };
  const addReview = async () => {
    Util.validate(validation, input, ["name", "email", "phone", "review"]);

    if (isAuthenticated) {
      if (
        validation.validEmail &&
        validation.validName &&
        validation.validPhone &&
        validation.validReview
      ) {
        const request = {
          name: currentUser?.name,
          email: currentUser?.personalEmail,
          phone: currentUser?.mobile,
          review: input.review,
          rate: 0,
          productId: product?.id,
          userId: currentUser?.id,
          sellerId: product?.vendorId,
        };
        setIsProcessing(true);
        productService
          .addReview(request)
          .then((result) => {
            setIsProcessing(false);
            if (result.data.success) {
              toast.success(result.data.message);
              setInput({ name: "", email: "", phone: "", review: "" });
              getProductReviews();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            toast.error(
              e.response ? e.response.data.message : "Connection refused!"
            );
            console.log(e);
            setIsProcessing(false);
          });
      } else {
        toast.error("All fields required!");
      }
    } else {
      storage.clear();
      window.location.replace("/login");
    }
  };

  useEffect(() => {
    getProductReviews();
  }, [product]);

  return (
    <div className="review-wrapper w-full">
      {reviews && reviews.length > 0 ? (
        <div className="w-full reviews mb-[60px]">
          {/* comments */}
          <div className="w-full comments mb-[60px]">
            {reviews &&
              reviews.length > 0 &&
              reviews.map((item) => (
                <div
                  key={item.id}
                  className="comment-item bg-white px-10 py-[32px] mb-2.5"
                >
                  <div className="comment-author flex justify-between items-center mb-3">
                    <div className="flex space-x-3 items-center">
                      <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                        <img
                          src={`${Util.storageBaseURL}/${item?.user?.dpLink}`}
                          alt=""
                          onError={Util.onImageError}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div>
                        <p className="text-[18px] font-medium text-qblack">
                          {item?.user?.name ? item?.user?.name : "Anonymous"}
                        </p>
                        {item?.user?.country ? (
                          <small>
                            {item?.user?.city}, {item?.user?.country}
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="flex">
                        {Util.calculateRating(
                          item?.review?.rate ? item?.review?.rate : 0
                        ).map((item, idx) => (
                          <span key={idx + Math.random()}>
                            <Star value={item} />
                          </span>
                        ))}
                      </div>
                      <span className="text-[13px] font-normal text-qblack mt-1 inline-block">
                        ({item?.review?.rate}.0)
                      </span>
                    </div>
                  </div>
                  <div className="comment mb-[30px]">
                    <p className="text-[15px] text-qgray leading-7 text-normal">
                      {item?.review?.review}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div className="w-full flex justify-center">
            <button
              type="button"
              className="black-btn w-[300px] h-[50px] text-sm font-semibold"
            >
              Load More
            </button>
          </div>
        </div>
      ) : (
        <Error message="No reviews found for this product" />
      )}
    </div>
  );
}
