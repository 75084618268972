import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { cartService } from "../../../../services/cart.service";
import { wishlistService } from "../../../../services/wishlist.service";
import Util from "../../../../utils/util";
import Error from "../../../Error";

export default function WishlistTab({ className }) {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [wishList, setWishList] = useState([]);
  const [cart, setCart] = useState({
    color: null,
    size: null,
    quantity: 1,
    product: null,
  });

  const getWishList = () => {
    setIsLoading(true);
    wishlistService
      .findWishListByUserId(currentUser?.id)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result?.data?.payload;
          setWishList(responseData);
          console.log("wishlist", responseData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteWishList = (id) => {
    setDeleteLoader(true);
    wishlistService
      .deleteWishList(id)
      .then((result) => {
        if (result.data && result.data.success) {
          toast.success(result.data.message);
          getWishList();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setDeleteLoader(false);
      });
  };

  const addAllToCart = () => {
    wishList.forEach((item) => {
      setCart({ ...cart, product: item?.product });
      cartService.addToCart(cart);
    });
    toast.success("Added all to Cart");
  };

  const clearWishlist = () => {
    setDeleteLoader(true);
    wishlistService
      .deleteAllWishList()
      .then((result) => {
        if (result.data && result.data.success) {
          toast.success(result.data.message);
          getWishList();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setDeleteLoader(false);
      });
  };

  useEffect(() => {
    getWishList();
  }, []);

  return (
    <>
      <div className={`w-full ${className || ""}`}>
        <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
          {isLoading ? (
            <ClipLoader />
          ) : wishList && wishList.length > 0 ? (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                {/* table heading */}
                <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                  <td className="py-4 pl-10 block whitespace-nowrap  w-[380px]">
                    Product
                  </td>
                  <td className="py-4 whitespace-nowrap text-center">Price</td>
                  <td className="py-4 whitespace-nowrap text-right w-[114px] block"></td>
                </tr>
                {wishList.map((item, idx) => (
                  <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                    <td className="pl-10  py-4 ">
                      <Link to={`/product-detail/${item.product?.id}`}>
                        <div className="flex space-x-6 items-center">
                          <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                            <img
                              src={`${Util.storageBaseURL}/${item.product?.thumbnail}`}
                              onError={Util.onImageError}
                              alt="product"
                              className="w-full h-full object-contain"
                            />
                          </div>
                          <div className="flex-1 flex flex-col">
                            <p className="font-medium text-[15px] text-qblack">
                              {item.product?.name}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </td>

                    <td className="text-center py-4 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[15px] font-normal">
                          {Util.decodeCurrency(
                            item?.product.currencyCode,
                            item?.product.price
                          )}
                        </span>
                      </div>
                    </td>

                    <td className="text-right py-4">
                      <div
                        onClick={() => deleteWishList(item.info?.id)}
                        className="flex space-x-1 items- cursor-pointer justify-center"
                      >
                        <span>
                          {deleteLoader ? (
                            <ClipLoader />
                          ) : (
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                fill="#AAAAAA"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Error message="You do not have wishlist" />
          )}
        </div>
      </div>
      {wishList && wishList.length > 0 ? (
        <div className="w-full mt-[30px] flex sm:justify-end justify-start">
          <div className="sm:flex sm:space-x-[30px] items-center">
            <button type="button" onClick={clearWishlist}>
              <div className="w-full text-sm font-semibold text-qred mb-5 sm:mb-0">
                {deleteLoader ? <ClipLoader /> : <span>Clear Wishlist</span>}
              </div>
            </button>
            <div className="w-[180px] h-[50px]">
              <button
                type="button"
                className="yellow-btn"
                onClick={addAllToCart}
              >
                <div className="w-full text-sm font-semibold">
                  Add to Cart All
                </div>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
