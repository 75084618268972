// import { useEffect, useState } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { categoryService } from "../../services/category.service";
import { productService } from "../../services/product.service";
import { userService } from "../../services/user.service";
import AdsProductSection from "../Helpers/AdsProductSection";
import GridProductSection from "../Helpers/GridProductSection";
import ProductSection from "../Helpers/ProductSection";
import TinyProductSection from "../Helpers/TinyProductSection";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import Loader from "../Helpers/icons/Loader";
import Layout from "../Partials/Layout";
import Banner from "./Banner";
import CategoryListing from "./CategoryListing";

export default function Home() {
  const [newArrivals, setNewArrivals] = useState([]);
  const [isLoadingNewArrivals, setIsLoadingNewArrivals] = useState(true);

  const [topProducts, setTopProducts] = useState([]);
  const [isLoadingTopProducts, setIsLoadingTopProducts] = useState(true);

  const [delasOfTheWeek, setDelasOfTheWeek] = useState([]);
  const [isLoadingDealsOfWeek, setIsLoadingDealsOfWeek] = useState(true);

  const [vendors, setVendors] = useState([]);
  const [isLoadingVendor, setIsLoadingVendor] = useState(true);

  const [categories, setCategories] = useState([]);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  const [grainsAndSeeds, setGrainsAndSeeds] = useState([]);
  const [africanSpices, setAfricanSpices] = useState([]);
  const [seaFoods, setSeafoods] = useState([]);
  const [herbs, setHerbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = () => {
    setIsLoadingCategory(true);
    categoryService
      .findAllCategories()
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setCategories(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingCategory(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingCategory(false);
      });
  };

  const getNewArrivals = () => {
    setIsLoadingNewArrivals(true);
    productService
      .getNewArrivedProducts(0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setNewArrivals(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingNewArrivals(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingNewArrivals(false);
      });
  };

  const getTopProducts = () => {
    setIsLoadingTopProducts(true);
    productService
      .getTopProducts(0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setTopProducts(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingTopProducts(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingTopProducts(false);
      });
  };

  const getDealsOfTheWeek = () => {
    setIsLoadingDealsOfWeek(true);
    productService
      .getDealOfTheWeek(0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setDelasOfTheWeek(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingDealsOfWeek(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingDealsOfWeek(false);
      });
  };

  const findProductByCategoryId = (categoryId, type) => {
    setIsLoadingTopProducts(true);
    productService
      .findProductByCategoryId(categoryId, 0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          if (type == "grainsAndSeeds") setGrainsAndSeeds(responseData);
          if (type == "africanSpices") setAfricanSpices(responseData);
          if (type == "seaFoods") setSeafoods(responseData);
          if (type == "herbs") setHerbs(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingTopProducts(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingTopProducts(false);
      });
  };
  const getVendors = () => {
    setIsLoadingVendor(true);
    userService
      .findAllVendors(0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setVendors(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingVendor(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingVendor(false);
      });
  };

  useEffect(() => {
    getNewArrivals();
    getTopProducts();
    getDealsOfTheWeek();
    getVendors();
    getCategories();
    findProductByCategoryId(2, "grainsAndSeeds");
    findProductByCategoryId(1, "africanSpices");
    findProductByCategoryId(8, "seaFoods");
    findProductByCategoryId(4, "herbs");
  }, []);

  return (
    <>
      <Layout>
        <div className="btn w-5 h-5 "></div>
        <Banner className="banner-wrapper mb-[60px] top-banner-design" />

        {isLoadingNewArrivals ? (
          <Loader />
        ) : newArrivals && newArrivals.length > 0 ? (
          <AdsProductSection
            products={newArrivals}
            categoryTitle="New Arrivals"
            sectionTitle="New Arrivals"
            seeMoreUrl="/products"
            className="category-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No Product Available" />
        )}

        {/* <CampaignCountDown
          className="mb-[60px]"
          lastDate="2023-10-04 4:00:00"
        /> */}

        {isLoadingTopProducts ? (
          <Loader />
        ) : topProducts && topProducts.length > 0 ? (
          <ViewMoreTitle
            className="top-selling-product mb-[60px]"
            seeMoreUrl="/products"
            categoryTitle="Top Selling Products"
          >
            <ProductSection products={topProducts} />
          </ViewMoreTitle>
        ) : (
          <></> // <Error message="No Product Available" />
        )}

        {isLoadingCategory ? (
          <Loader />
        ) : categories && categories.length > 0 ? (
          <ViewMoreTitle
            className="best-sallers-section mb-[60px]"
            seeMoreUrl="/products"
            categoryTitle="Shop by Category"
          >
            <CategoryListing categories={categories} />
          </ViewMoreTitle>
        ) : (
          <></> // <Error message="No Category Available" />
        )}

        {/* <ProductsAds
          ads={[
            `${process.env.PUBLIC_URL}/assets/images/ads-1.png`,
            `${process.env.PUBLIC_URL}/assets/images/ads-2.png`,
          ]}
          urls={["/products/2", "/products/3"]}
          sectionHeight="sm:h-[295px] h-full"
          className="products-ads-section mb-[60px]"
        /> */}
        {isLoadingDealsOfWeek ? (
          <Loader />
        ) : delasOfTheWeek && delasOfTheWeek.length > 0 ? (
          <AdsProductSection
            categoryBackground={`${process.env.PUBLIC_URL}/assets/images/section-category-2.jpg`}
            products={delasOfTheWeek}
            categoryTitle="Top Deals"
            sectionTitle="Deals of the Week"
            seeMoreUrl="/products"
            className="category-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No Product Available" />
        )}

        {/* <ProductsAds
          ads={[`${process.env.PUBLIC_URL}/assets/images/ads-3.png`]}
          className="products-ads-section mb-[60px]"
          urls={["/products/2"]}
        /> */}

        {isLoading ? (
          <Loader />
        ) : grainsAndSeeds && grainsAndSeeds.length > 0 ? (
          <GridProductSection
            products={grainsAndSeeds}
            sectionTitle="Grains & Seeds"
            seeMoreUrl="/products/2"
            className="new-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No Grains or Seeds Available" />
        )}

        {/* <ProductsAds
          sectionHeight="164"
          ads={[`${process.env.PUBLIC_URL}/assets/images/ads-4.png`]}
          urls={["/products"]}
          className="products-ads-section mb-[60px]"
        /> */}

        {isLoading ? (
          <Loader />
        ) : africanSpices && africanSpices.length > 0 ? (
          <TinyProductSection
            products={africanSpices}
            sectionTitle="African Spices"
            seeMoreUrl="/products/1"
            className="category-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No African Spices Available" />
        )}

        {isLoading ? (
          <Loader />
        ) : seaFoods && seaFoods.length > 0 ? (
          <GridProductSection
            products={seaFoods}
            sectionTitle="Seafoods"
            seeMoreUrl="/products/8"
            className="new-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No Sea Foods Available" />
        )}

        {isLoading ? (
          <Loader />
        ) : herbs && herbs.length > 0 ? (
          <TinyProductSection
            products={herbs}
            sectionTitle="Herbs"
            seeMoreUrl="/products/4"
            className="category-products mb-[60px]"
          />
        ) : (
          <></> // <Error message="No Herbs Available" />
        )}
      </Layout>
    </>
  );
}
