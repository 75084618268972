import { Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
const InformationModal = ({
  message,
  isProcessing,
  onAcceptHandlaer,
  onRejectHandler,
}) => {
  return (
    <>
      <div className="p-4">
        <div className="text-center">
          <Typography variant="subtitle1" component="div">
            {message}
          </Typography>
        </div>
        <div className="form-group__input mt-3">
          <button
            place="Yes"
            className="h-[40px] rounded bg-qyellow text-white  p-2 mr-2"
            onClick={onAcceptHandlaer}
          >
            <span>
              {isProcessing ? (
                <ClipLoader color="#fff" size={30} data-testid="loader" />
              ) : (
                <span>Okay</span>
              )}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default InformationModal;
