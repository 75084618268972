import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../Access";
import { cartService } from "../../../../services/cart.service";
import { chatService } from "../../../../services/chat.service";
import { storage } from "../../../../utils/storage";
import SearchBox from "../../../Helpers/SearchBox";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";

export default function Middlebar({ className, type }) {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [cartCount, setCartCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);

  const logout = () => {
    storage.remove("user");
    storage.remove("userToken");

    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  const loadMessageCount = () => {
    chatService
      .countNewMessages()
      .then((response) => {
        setMessagesCount(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setInterval(() => {
      let cart = cartService.getItems();
      setCartCount(cart && cart != null ? cart.length : 0);
    }, 100);
  }, []);

  return (
    <div className={`w-full h-[86px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              {type === 3 ? (
                <a href="/">
                  <img
                    width="152"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                    alt="logo"
                  />
                </a>
              ) : type === 4 ? (
                <a href="/">
                  <img
                    width="152"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                    alt="logo"
                  />
                </a>
              ) : (
                <a href="/">
                  <img
                    width="152"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                    alt="logo"
                  />
                </a>
              )}
            </div>
            <div className="w-[517px] h-[44px]">
              <SearchBox type={type} className="search-com" />
            </div>
            <div className="flex space-x-6 items-center">
              <div className="compaire relative">
                <Link to="/chat">
                  <span>
                    <img
                      className="w-6"
                      src="https://img.icons8.com/windows/32/chat.png"
                      alt="chat"
                    />
                  </span>
                  <span
                    className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                      type === 3 ? "bg-qh3-blue text-white" : "bg-qorange"
                    }`}
                  >
                    {messagesCount}
                  </span>
                </Link>
              </div>

              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <Link to="/cart">
                    <span>
                      <ThinBag />
                    </span>

                    <span
                      className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                        type === 3 ? "bg-qh3-blue text-white" : "bg-qorange"
                      }`}
                    >
                      {cartCount}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <Link to="/profile#wishlist">
                    <span>
                      <ThinLove />
                    </span>
                    <span
                      className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                        type === 3 ? "bg-qh3-blue text-white" : "bg-qorange"
                      }`}
                    >
                      0
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
