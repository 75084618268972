import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function RefundPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Refund Policy", path: "refund-policy" },
            ]}
            title="Refund Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p className="text-[15px] leading-7">
                In the event that a customer does not receive their order within
                14 days of the stipulated arrival date, Bellabanga has a refund
                policy in place. Customers are obligated to initiate a refund
                request through the Bellabanga App by contacting support at
                <strong>support@bellabanga.com</strong>, providing specific
                details regarding the issue along with supporting evidence. This
                must be executed within a 30-day timeframe. Bellabanga will
                review the refund request, investigating the circumstances and
                validating the claims made by the customer where deemed
                appropriate. If the issue is confirmed to be attributed to the
                vendor, Bellabanga holds the vendor accountable and takes
                necessary actions.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <p className="text-[15px] leading-7">
                Upon processing and approval of the refund, which is typically
                within 3-5 working days, Bellabanga will initiate the refund
                process. It is important to note that depending on the clearing
                policy of the customer's bank, an additional 7-10 working days
                may be required for the refunded amount to reflect in the
                customer's account. The refund amount encompasses only the sum
                paid and excludes any associated shipping fees, as well as the
                value of any discount, rebate, or promotion not directly paid
                for by the customer. In cases where a customer has made a
                purchase of multiple items within a single order, meeting the
                criteria for a particular discount, and subsequently receives a
                refund leading to the overall order value dropping below the
                minimum spending requirement for eligibility for said
                discount,the said discount becomes void. Consequently, the
                original prices of the remaining products before the discount
                shall be payable by the customer as an outstanding amount.
                Bellabanga maintains communication with the customer throughout
                the refund process, ensuring they are kept informed about
                progress and expected timelines. Continual assessment and
                improvement of processes are integral to Bellabanga's commitment
                to minimizing issues and enhancing customer satisfaction. For
                any inquiries or concerns regarding your refund, please do not
                hesitate to contact us at{" "}
                <strong> hello@bellabanga.com or support@bellabanga.com</strong>{" "}
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
