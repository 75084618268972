export default function ChevronDownIcon({ className }) {
  return (
    <span>
      <svg
        className="fill-current"
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.49805"
          y="0.818359"
          width="5.78538"
          height="1.28564"
          transform="rotate(45 1.49805 0.818359)"
        />
        <rect
          x="5.58984"
          y="4.90918"
          width="5.78538"
          height="1.28564"
          transform="rotate(135 5.58984 4.90918)"
        />
      </svg>
    </span>
  );
}
