import PropTypes from "prop-types";

const Error = ({ message = "Unknown Error", transparent = false }) => {
  return (
    <div data-aos="fade-up" className={`card border w-full`}>
      <div className="container-x mx-auto ">
        <div className="text-center mb-5">
          <div
            className={`error text-center p-2 my-3 ${
              transparent ? `custom-error-messag border` : ``
            }`}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};
Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
