import { AddCircleOutline } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { supportService } from "../../../../services/support.service";
import Util from "../../../../utils/util";
import Error from "../../../Error";
export default function SupportTab() {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [src, setSrc] = useState("");
  const [ticket, setTicket] = useState([]);
  const getTicketList = () => {
    setIsLoading(true);
    supportService
      .findTicketByUserId(currentUser.id, page)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result?.data?.payload?.content;
          // alert(JSON.stringify(responseData));
          setTicket(responseData);
          console.log("support list", responseData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getTicketList();
  }, []);
  return (
    <>
      <div className="support-tab w-full">
        <div className="w-full h-[50px] mb-4 text-right ustify-right">
          <Link
            to="/support"
            className="black-btn w-[200px] h-[50px]  justify-center text-center p-3 text-white rounded-lg bg-qyellow"
          >
            <AddCircleOutline /> Create New Support
          </Link>
        </div>
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          {isLoading ? (
            <ClipLoader />
          ) : ticket && ticket.length > 0 ? (
            <>
              <div className="table-container">
                <table className="w-full text-sm text-left text-gray-500 overflow-x-auto">
                  <tbody>
                    <tr className="text-base text-qblack font-semibold bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom ">
                      <td className="py-6 block whitespace-nowrap text-center">
                        Title
                      </td>
                      <td className="py-6 whitespace-nowrap text-center">
                        Department
                      </td>
                      <td className="py-6  px-4 whitespace-nowrap text-center">
                        Priority
                      </td>
                      <td className="py-6  px-4 whitespace-nowrap text-center">
                        Status
                      </td>
                      <td className="py-6 whitespace-nowrap w-[285px] text-center">
                        Description
                      </td>
                      <td className="py-6 px-6 whitespace-nowrap text-center">
                        Attachment
                      </td>
                      <td className="py-6 whitespace-nowrap  text-center">
                        Action
                      </td>
                    </tr>
                    {ticket.map((item, idx) => {
                      return (
                        <>
                          <tr key={idx} className="bg-white hover:bg-gray-50">
                            <td className="text-center py-4">
                              <span className="text-lg text-qblack font-medium">
                                {item?.title}
                              </span>
                            </td>
                            <td className="text-center py-4 px-2">
                              <span className="text-base text-qgray  whitespace-nowrap">
                                {item?.department}
                              </span>
                            </td>
                            <td className="text-center py-4 px-2">
                              <span className="text-base text-qgray  whitespace-nowrap">
                                {item?.priority}
                              </span>
                            </td>{" "}
                            <td className="text-center py-4 px-2">
                              <span className="text-base text-qgray  whitespace-nowrap">
                                {item?.status}
                              </span>
                            </td>
                            <td className="text-center py-4 px-2">
                              <span className="text-sm text-center text-qgray">
                                {item?.description}
                              </span>
                            </td>
                            <td className="text-center py-4 px-2">
                              <span className="text-sm text-center text-qgray">
                                <img
                                  src={`${Util.storageBaseURL}/${
                                    src || item?.attachment
                                  }`}
                                  onError={Util.onImageError}
                                  alt=""
                                  className="w-full h-full"
                                />
                              </span>
                            </td>
                            <td className="text-center py-4 flex space-x-2.5 justify-center">
                              <button
                                type="button"
                                className="border border-qgray w-[34px] h-[34px] rounded-full flex justify-center items-center"
                              >
                                <svg
                                  width="18"
                                  height="17"
                                  viewBox="0 0 18 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.34526 0.0234375C12.2264 0.133021 14.9269 1.30005 16.7549 4.04172C18.878 7.22662 18.2288 11.3601 15.1868 13.87C12.3535 16.2076 9.12958 16.6592 5.65976 15.5326C5.41809 15.4537 5.23754 15.4516 5.0071 15.5696C3.56972 16.3032 2.03046 16.7262 0.439223 16.9705C0.315577 16.9893 0.142753 16.923 0.0528284 16.8351C0.000840854 16.7841 0.0268346 16.5747 0.0914679 16.4986C0.912029 15.5403 1.12209 14.3635 1.33847 13.1811C1.42909 12.6855 1.33144 12.2891 1.06799 11.8466C-1.05226 8.27848 0.068284 3.93424 3.68564 1.57994C5.29725 0.530871 7.07607 0.043679 9.34526 0.0234375ZM7.79406 8.08444C7.80179 8.72589 8.33993 9.25705 8.98556 9.25845C9.64384 9.25984 10.189 8.71053 10.1813 8.05373C10.1735 7.40461 9.64665 6.88949 8.98978 6.8881C8.31886 6.8874 7.78633 7.42066 7.79406 8.08444ZM4.80618 6.8874C4.14018 6.87972 3.59009 7.41647 3.5915 8.07118C3.5929 8.70844 4.12894 9.24588 4.77457 9.25845C5.41809 9.27031 5.96326 8.74613 5.97731 8.10259C5.99206 7.42834 5.4778 6.89508 4.80618 6.8874ZM11.9966 8.08235C12.0022 8.72868 12.532 9.25635 13.1797 9.25984C13.8316 9.26333 14.3923 8.70355 14.3838 8.05722C14.3747 7.41298 13.8415 6.89089 13.1895 6.8888C12.5151 6.886 11.991 7.41089 11.9966 8.08235Z"
                                    fill="#27AE60"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Error message="You do not have any Support" />
          )}
        </div>
      </div>
    </>
  );
}
