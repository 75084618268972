import { http } from "../utils/http";
import Util from "../utils/util";

let userService = {
  createUser: async (body) => {
    return await http.post(`${Util.baseUrl}/user/add`, body, Util.getHeaders());
  },

  findVendorByHandle: async (handle) => {
    return await http.get(
      `${Util.baseUrl}/api/user/get_by_handle/${handle}`,
      Util.getHeaders()
    );
  },
  findAllVendors: async (page) => {
    return await http.get(
      `${Util.baseUrl}/api/user/get_by_role?role=VENDOR&status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  findVendorById: async (id) => {
    return await http.get(
      `${Util.baseUrl}/api/user/get_by_id/${id}`,
      Util.getAuthorizedHeaders()
    );
  },
  findAllVerifiedVendors: async (page) => {
    return await http.get(
      `${Util.baseUrl}/api/user/get_verified_by_role?role=VENDOR&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  searchVendors: async (q, page) => {
    return await http.get(
      `${Util.baseUrl}/api/user/search?q=${q}&role=VENDOR&status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
};
export { userService };
