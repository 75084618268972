import { useContext, useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { UserContext } from "../../Access";
import { categoryService } from "../../services/category.service";
import { productService } from "../../services/product.service";
import BreadcrumbCom from "../BreadcrumbCom";
import Error from "../Error";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";

export default function AllProductPage() {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [elementsSize, setSize] = useState("0px");
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [categories, setCategories] = useState([]);
  const [subCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const params = useParams();
  const search = location.search.split("=")[1];

  const getCategories = () => {
    setIsLoadingCategories(true);
    categoryService
      .findAllCategories()
      .then(async (result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setCategories(responseData);
          await responseData.forEach((category) => {
            category.subCategories.forEach((subCategory) => {
              subCategories.push(subCategory);
            });
          });
        }
      })
      .catch((error) => {
        setIsLoadingCategories(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingCategories(false);
      });
  };

  const getProducts = () => {
    setIsLoadingProducts(true);
    console.log("all products");
    productService
      .findAllProducts(0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        setProducts([]);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const searchProducts = () => {
    setIsLoadingProducts(true);
    productService
      .searchProducts(search, 0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        setProducts([]);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const getProductsCategoryById = (categoryId) => {
    setIsLoadingProducts(true);
    console.log("products by category");
    productService
      .findProductByCategoryId(categoryId, 10)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const getProductsSubCategoryById = (categoryId) => {
    setIsLoadingProducts(true);
    console.log("products by sub category");
    productService
      .findProductBySubCategoryId(categoryId, 10)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setProducts(responseData?.content);
          console.log(responseData);
          setTotalPages(responseData?.totalPages);
        }
      })
      .catch((error) => {
        setIsLoadingProducts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  useEffect(() => {
    setSize(`0px`);
    if (categories.length <= 0) getCategories();

    if (search) {
      searchProducts();
    } else {
      if (params?.categoryId && !params?.subCategoryId) {
        getProductsCategoryById(params?.categoryId);
      } else if (params?.subCategoryId) {
        getProductsSubCategoryById(params?.subCategoryId);
      } else {
        getProducts();
      }
    }
  }, [params, search]);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom />
            <div className="w-full lg:flex lg:space-x-[30px]">
              <div className="lg:w-[270px]">
                {isLoadingCategories ? (
                  <ClipLoader />
                ) : (
                  <ProductsFilter
                    categories={categories}
                    className="mb-[30px]"
                  />
                )}
                {/* <div
                  className={`filter-widget bellabanga-hom w-full d-none d-sm-block overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px"} mt-5 p-t`}
                >
                  <div className="filter-subject-item pb-10 border-b border-qgray-border my-5">
                    <div className="subject-title mb-[30px]">
                      {isLoadingCategories ? (
                        <ClipLoader />
                      ) : subCategories && subCategories.length > 0 ? (
                        subCategories.map((subCategory, sidx) => {
                          return (
                            <Link
                              className="p-2"
                              to={`/products/${subCategory.categoryId}/${subCategory.id}`}
                            >
                              <span>
                                <i className="fa-solid fa-chevron-right" />
                                {subCategory.name}
                              </span>
                            </Link>
                          );
                        })
                      ) : (
                        <Error message="No sub categories available" />
                      )}{" "}
                    </div>
                  </div>
                </div> */}
                {/* ads */}
                {/* <div className="w-full bellabanga-hom hidden lg:block h-[295px] mt-10">
                  <Link to="/products/2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
                      alt=""
                      className="w-full h-full object-contain"
                    />
                  </Link>
                </div>
                <div className="w-full bellabanga-hom h-[164px] overflow-hidden mb-[40px]">
                  <Link to="/products">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
                      alt=""
                      className="w-full h-full object-contain"
                    />
                  </Link>
                </div> */}
              </div>

              {isLoadingProducts ? (
                <ClipLoader />
              ) : products && products.length > 0 ? (
                <div className="flex-1">
                  <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                    <div>
                      <p className="font-400 text-[13px]">
                        <span className="text-qgray"> Showing</span>{" "}
                        {currentPage + 1} of {totalPages}
                        &nbsp; result
                      </p>
                    </div>
                    {/* <div className="flex space-x-3 items-center">
                      <span className="font-400 text-[13px]">Sort by:</span>
                      <div className="flex space-x-3 items-center border-b border-b-qgray">
                        <span className="font-400 text-[13px] text-qgray">
                          Default
                        </span>
                        <span>
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1 1L5 5L9 1" stroke="#9A9A9A" />
                          </svg>
                        </span>
                      </div>
                    </div> */}
                    <button
                      type="button"
                      className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-2  xl:gap-[30px] gap-5 mb-[40px]">
                    {products && products.length > 0 ? (
                      products.map((data) => {
                        return (
                          <div key={data.id} className="item">
                            <ProductCardStyleOne data={data} />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <Error
                  message={
                    search
                      ? `Search ${search} not found `
                      : `No Product Found ${
                          params.categoryId ? `in this category` : ""
                        }  `
                  }
                  transparent={true}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
