import { Countries, States } from "countries-states-cities-service";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { authService } from "../../../services/auth.service";
import { storage } from "../../../utils/storage";
import InputCom from "../../Helpers/InputCom";
import SelectBox from "../../Helpers/SelectBox";
import Layout from "../../Partials/Layout";
import Util from "./../../../utils/util";
import Thumbnail from "./Thumbnail";

export default function Signup() {
  const [checked, setValue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [countries, setCountries] = useState(["Select country...."]);
  const [states, setStates] = useState(["Select city...."]);
  const navigate = useNavigate();

  const [validation, setValidation] = useState({
    validName: true,
    validEmail: true,
    validMobile: true,
    validPassword: true,
    validConfirmPassword: true,
  });
  const [input, setInput] = useState({
    name: "",
    email: "",
    country: "",
    city: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const rememberMe = () => {
    setValue(!checked);
  };

  const inputHandler = (e) => {
    const name = e.target.id;
    let value = e.target.value;
    if (name == "name" || name == "personalEmail" || name == "businessName")
      value = Util.capitalize(value);

    console.log(value);

    setInput({ ...input, [name]: value });
    const result = Util.validate(validation, input, [name]);
    setValidation({ ...validation, ...result });
  };

  const signUp = () => {
    const result = Util.validate(validation, input, [
      "name",
      "email",
      "mobile",
      "password",
      "confirmPassword",
    ]);
    setValidation({ ...validation, ...result });

    if (
      validation.validName &&
      validation.validEmail &&
      validation.validMobile
    ) {
      delete input.confirmPassword;
      if (validation.validConfirmPassword) {
        if (checked) {
          setIsProcessing(true);

          authService
            .registerUser(input)
            .then((result) => {
              setIsProcessing(false);
              if (result.data.success) {
                toast.success(result.data.message);
                storage.save("email", result.data.payload.email);
                setTimeout(() => {
                  var redirect = location.search.split("=")[1];
                  if (redirect) {
                    navigate("/otp?redirect=" + redirect);
                  } else {
                    navigate("/otp");
                  }
                }, 500);
              } else {
                setInput({ ...input, confirmPassword: input.password });
                toast.error(result.data.message);
              }
            })
            .catch((e) => {
              setInput({ ...input, confirmPassword: input.password });
              setIsProcessing(false);
              toast.error(
                e.response ? e.response.data.message : "Connection refused!"
              );
            });
        } else {
          toast.warn("Accept terms and condition to proceed!");
        }
      } else {
        toast.warn("Password not equal!");
      }
    } else {
      toast.error("All fields required!");
    }
  };

  const findCountryCode = (selectedCountry) => {
    var countryCode = null;
    Countries.getCountries().forEach((country) => {
      if (country.name == selectedCountry) countryCode = country.iso2;
    });
    return countryCode;
  };

  const getCities = (selectedCountry) => {
    const countryCode = findCountryCode(selectedCountry);
    const states = States.getStates({ filters: { country_code: countryCode } });
    var stateList = [];
    if (selectedCountry == "United Kingdom") {
      stateList = ["England", "Scotland", "Wales", "Northern Ireland"];
    } else {
      states.forEach((state) => {
        stateList.push(state.name);
      });
    }
    setStates(stateList);
  };

  useEffect(() => {
    let countryList = [];
    Countries.getCountries().forEach((country) => {
      countryList.push(country.name);
    });
    setCountries(countryList);
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <ToastContainer />
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full lg:h-[783px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Create Account
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#86ca2a"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="input-area">
                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-6 pb-4">
                    <InputCom
                      placeholder="Your Name"
                      label="Full Name*"
                      name="name"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validName ? `border-red-600` : " "
                      }
                      value={input.name}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-6 pb-4">
                    <InputCom
                      placeholder="0213 *********"
                      label="Phone*"
                      name="mobile"
                      type="tel"
                      parentClasses={
                        !validation.validMobile ? `border-red-600` : " "
                      }
                      inputClasses="h-[50px]"
                      value={input.mobile}
                      inputHandler={inputHandler}
                    />
                    <br />
                    <InputCom
                      placeholder="Demo@gmail.com"
                      label="Email Address*"
                      name="email"
                      type="email"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validEmail ? `border-red-600` : " "
                      }
                      value={input.email}
                      inputHandler={inputHandler}
                    />
                  </div>

                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 pb-4">
                    <SelectBox
                      datas={countries}
                      label="Country"
                      name="country"
                      className="w-full h-[50px]  px-5 flex justify-between items-center mb-2 rounded-lg"
                      value={input.country}
                      action={(value) => {
                        getCities(value);
                        setInput({ ...input, country: value });
                      }}
                    />
                    <SelectBox
                      datas={states}
                      label="State"
                      name="city"
                      className="w-full h-[50px]  px-5 flex justify-between items-center mb-2 rounded-lg "
                      value={input.city}
                      action={(value) => {
                        setInput({ ...input, city: value });
                      }}
                    />
                  </div>

                  <div className="input-item mb-6 pb-4">
                    <InputCom
                      placeholder="Your address Here"
                      label="Address"
                      name="address"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses=""
                      value={input.address}
                      inputHandler={inputHandler}
                    />
                  </div>

                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-6 pb-4">
                    <InputCom
                      placeholder="New password Here"
                      label="Password"
                      name="password"
                      type="password"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validPassword ? `border-red-600` : " "
                      }
                      value={input.password}
                      inputHandler={inputHandler}
                    />
                    <br />
                    <InputCom
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validConfirmPassword
                          ? `border-red-600`
                          : " "
                      }
                      value={input.confirmPassword}
                      inputHandler={inputHandler}
                    />
                  </div>

                  <div className="forgot-password-area py-5">
                    <div className="remember-checkbox flex items-center space-x-2.5">
                      <button
                        onClick={rememberMe}
                        type="button"
                        className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                      >
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button>
                      <span
                        onClick={rememberMe}
                        className="text-base text-black"
                      >
                        I agree with the &nbsp;
                        <Link to="/terms-condition" className="text-link">
                          terms and conditions
                        </Link>
                        &nbsp;of Bellabanga.
                      </span>
                    </div>
                  </div>
                  <div className="signin-area mb-3">
                    <div className="flex justify-center">
                      <button
                        onClick={!isProcessing ? signUp : null}
                        type="button"
                        className="rounded-lg black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                      >
                        <span>
                          {isProcessing ? (
                            <ClipLoader
                              color="#fff"
                              size={30}
                              data-testid="loader"
                            />
                          ) : (
                            "Create Account"
                          )}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="signup-area flex justify-center">
                    <p className="text-base text-qgraytwo font-normal">
                      Already have an Account?
                      <Link to="/login" className="ml-2 text-qblack">
                        Log In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <Thumbnail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
