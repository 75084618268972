import Menu from '@mui/material/Menu';
import React from 'react';
// import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // You can replace this with your own icon
import IconButton from '@mui/material/IconButton';

import PropTypes from 'prop-types';

const TableOption = ({ children, icon }) => {
    const buttonStyle = {
        color: '#fa9833',
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
      <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary" // Change the color to your preference
        size="small" // Adjust the size as needed
        style={buttonStyle}
      >
        {icon ?? <MoreVertIcon />} {/* You can replace this icon with your own */}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </div>
    );
};

TableOption.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Updated PropTypes
};

export default TableOption;
