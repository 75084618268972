import { http } from "../utils/http";
import Util from "../utils/util";

let fileService = {
  uploadFile: async (formData) => {
    return await http.post(
      `${Util.baseUrl}/api/file/public-upload`,
      formData,
      Util.getFileHeaders()
    );
  },
  uploadFiles: async (formData) => {
    return await http.post(
      `${Util.baseUrl}/api/files/public-upload`,
      formData,
      Util.getFileHeaders()
    );
  },
};
export { fileService };
