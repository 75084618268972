import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { authService } from "../../../services/auth.service";
import { storage } from "../../../utils/storage";
import Util from "../../../utils/util";
import InputCom from "../../Helpers/InputCom";
import Layout from "../../Partials/Layout";

export default function ForgotPassword() {
  const [checked, setValue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const [validation, setValidation] = useState({
    validEmail: true,
  });

  const [input, setInput] = useState({
    username: "",
  });

  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value });
    const result = Util.validate(validation, input, [name]);
    setValidation({ ...validation, ...result });
  };

  const resetPassword = () => {
    if (validation.validEmail) {
      console.log("processing");
      setIsProcessing(true);
      authService
        .resendOTP(input)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success(result.data.message);
            storage.save("email", input.username);
            setTimeout(() => {
              navigate("/otp?f=fp", { state: { at: 1 } });
            }, 500);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          console.log(e);
          toast.error(
            e.response ? e.response.data.message : "Connection refused!"
          );
        });
    } else {
      toast.error("Enter a valid email address");
    }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="login-page-wrapper w-full py-10">
        <div className="container-x mx-auto">
          <div className="lg:flex items-center relative">
            <div className="lg:w-[572px] w-full h-[400px] bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                    Forgot Password
                  </h1>
                  <div className="shape -mt-6">
                    <svg
                      width="172"
                      height="29"
                      viewBox="0 0 172 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.08742C17.6667 19.0972 30.5 31.1305 62.5 27.2693C110.617 21.4634 150 -10.09 171 5.08727"
                        stroke="#FFBB38"
                      />
                    </svg>
                  </div>
                </div>
                <div className="input-area">
                  <div className="input-item mb-5 pb-6">
                    <InputCom
                      placeholder="example@quomodosoft.com"
                      label="Email Address*"
                      name="username"
                      type="email"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validEmail ? `border-red-600` : " "
                      }
                      value={input.username}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="signin-area mb-3.5">
                    <div className="flex justify-center">
                      <button
                        type="button"
                        onClick={!isProcessing ? resetPassword : null}
                        className=" rounded-lg black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                      >
                        <span>
                          {isProcessing ? (
                            <ClipLoader
                              color="#fff"
                              size={30}
                              data-testid="loader"
                            />
                          ) : (
                            "Continue"
                          )}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="signup-area flex justify-center">
                    <p className="text-base text-qgraytwo font-normal">
                      Remember your Password?
                      <a href="/login" className="ml-2 text-qblack">
                        Login
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 lg:flex hidden transform scale-60 xl:scale-100   xl:justify-center ">
              <div
                className="absolute xl:-right-20 -right-[138px]"
                style={{ top: "calc(50% - 258px)" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Forgot.png`}
                  className="w-[80%]"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
