import { SearchOutlined } from "@mui/icons-material";

export default function SearchInput({
  className,
  onChange,
  type,
  placeholder,
  onClick,
  value,
}) {
  return (
    <>
      <div
        className={`w-full h-full flex items-center  border border-qgray-border bg-white ${
          className || ""
        }`}
      >
        <div className="flex-1 h-full">
          <div className="h-full">
            <input
              type="search"
              className="search-input"
              placeholder={`${
                placeholder ? placeholder : "Search Product e.g Okro"
              }`}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="w-[1px] h-[22px] bg-qgray-border"></div>

        <button
          onClick={onClick}
          className={` w-[93px] h-full text-sm font-600 p-3 pl-6 ${
            type === 3 ? "bg-qh3-blue text-white" : "search-btn"
          }`}
        >
          <SearchOutlined />
        </button>
      </div>
    </>
  );
}
