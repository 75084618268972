import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../../Access";
import { cartService } from "../../../services/cart.service";
import { wishlistService } from "../../../services/wishlist.service";
import Util from "../../../utils/util";

export default function ProductCardRowStyleTwo({ className, data }) {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({
    color: null,
    size: null,
    quantity: 1,
    product: data,
  });

  const addToCart = () => {
    setCart({ ...cart, product: data });
    setIsLoading(true);
    toast.success("Added to Cart");
    setTimeout(() => {
      setIsLoading(false);
      cartService.addToCart(cart);
    }, 100);
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const addWishlist = async () => {
    if (authenticated) {
      setIsProcessing(true);
      wishlistService
        .addWishList(data?.id)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success("Added to wishlist");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error(error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      var redirect = location.search.split("=")[1];
      location.href = `login?redirect=${redirect}`;
    }
  };

  return (
    <div
      data-aos="fade-up"
      className={`product-card-row-two w-full  ${className || ""}`}
    >
      <div className="w-full h-[105px] bg-white border border-primarygray px-5 ">
        <div className="w-full h-full flex space-x-5 justify-center items-center">
          <div className="w-[75px] h-[75px]">
            {data && data?.thumbnail ? (
              <img
                src={`${Util.storageBaseURL}/${data?.thumbnail}`}
                alt=""
                className="w-full h-full object-cover"
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/favicon.ico}`}
                alt=""
                className="w-full h-full object-cover"
              />
            )}
          </div>
          <div className="flex-1 h-full flex flex-col justify-center ">
            <Link to={`/products/${data.id}`}>
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-1 hover:text-blue-600">
                {data.name}
                <small>{data?.unit ? ` / ${data?.unit}` : ``}</small>
              </p>
            </Link>

            <p className="price">
              <span className="main-price text-qgray line-through font-600 text-[18px]">
                {Util.decodeCurrency(
                  data?.currencyCode,
                  data?.price + 5 * (data?.price / 100)
                )}
              </span>
              <span className="offer-price text-qred font-600 text-[18px] ml-2">
                {Util.decodeCurrency(data?.currencyCode, data?.price)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
