import { CheckBoxOutlined } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { orderService } from "../../../../services/order.service";
import Util from "../../../../utils/util";
import Error from "../../../Error";

export default function OrderTab() {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [orders, setOrders] = useState([]);

  const getUserOrders = () => {
    console.log("fetching...");
    setIsLoading(true);
    orderService
      .getOrdersByUserId(user?.id)
      .then((result) => {
        setIsLoading(false);
        if (result.data.success) {
          const orders = result.data.payload;
          console.log(orders);
          setOrders(orders);
        }
      })
      .catch((e) => {
        toast.error(
          e.response ? e.response.data.message : "Connection refused!"
        );
        console.log(e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const confirmOrderReceipt = (item) => {
    setIsProcessing(true);
    orderService
      .updateOrderStatus(item?.id, "RC")
      .then((result) => {
        setIsProcessing(false);
        if (result.data.success) {
          getUserOrders();
        }
      })
      .catch((e) => {
        toast.error(
          e.response ? e.response.data.message : "Connection refused!"
        );
        console.log(e);
        setIsProcessing(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };
  useEffect(() => {
    getUserOrders();
  }, []);
  return (
    <>
      <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
              <td className="py-4 block whitespace-nowrap text-center">
                Order
              </td>
              <td className="py-4 whitespace-nowrap text-center">Date</td>
              <td className="py-4 whitespace-nowrap text-center">Status</td>
              <td className="py-4 whitespace-nowrap text-center">Amount</td>
              <td className="py-4 whitespace-nowrap  text-center">Action</td>
            </tr>
            {isLoading ? (
              <ClipLoader />
            ) : orders && orders.length > 0 ? (
              orders.map((item, idx) => (
                <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                  <td className="text-center py-4">
                    <span className="text-lg text-qgray font-medium">
                      #{item?.orderReferenceId}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-base text-qgray  whitespace-nowrap">
                      {Util.formatDate(item?.createdDate)}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className={`text-sm rounded p-2 ${item?.status}`}>
                      {Util.decodeStatus(item?.status)}
                    </span>
                  </td>
                  <td className="text-center py-4 px-2">
                    <span className="text-base text-qblack whitespace-nowrap px-2 ">
                      {Util.getAmountInCurrency(
                        item?.currencyCode,
                        item?.totalAmount
                      )}
                    </span>
                  </td>
                  <td className="inline-flex pt-2">
                    {item.status == "DV" || item.status == "DP" ? (
                      <button
                        type="button"
                        className="h-[45px]  w-[160px] bg-qyellow text-white font-bold p-2 mr-2"
                        onClick={() => confirmOrderReceipt(item)}
                      >
                        {isProcessing ? (
                          <ClipLoader color="#fff" />
                        ) : (
                          <span>
                            <CheckBoxOutlined />
                            Confirm Reciept
                          </span>
                        )}
                      </button>
                    ) : (
                      <></>
                    )}

                    <Link
                      to={`/order-detail/${item.id}`}
                      type="button"
                      className="h-[45px] w-[150px] bg-qorange text-white text-center font-bold p-3"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <Error message="You do not have an order yet" />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
