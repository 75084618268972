import { Countries, States } from "countries-states-cities-service";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authService } from "../../../services/auth.service";
import { fileService } from "../../../services/file.service";
import { storage } from "../../../utils/storage";
import InputCom from "../../Helpers/InputCom";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import Util from "./../../../utils/util";

export default function SignupBusiness() {
  const [checked, setValue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const [logoImg, setLogoImg] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  const [countries, setCountries] = useState(["Select country...."]);
  const [states, setStates] = useState(["Select city...."]);

  const [docs, setDoc] = useState({
    idFile: "",
    cacFile: "",
    nepcFile: "",
  });

  const [formValidated, setFormValidity] = useState(false);
  const [tab, setTab] = useState({
    personalInformation: true,
    businessInformation: false,
    securityInformation: false,
    documentation: false,
  });

  const [validation, setValidation] = useState({
    validName: true,
    validEmail: true,
    validMobile: true,
    validIdType: true,
    validPassword: true,
    validConfirmPassword: true,
    validHomeAddress: true,
    validBusinessName: true,
    validBusinessEmail: true,
    validBusinessMobile: true,
    validBusinessAddress: true,
    validCountry: true,
    validCity: true,
  });
  const [input, setInput] = useState({
    accountType: "BUSINESS",
    role: "VENDOR",
    name: "",
    email: "",
    mobile: "",
    idLink: "",
    idType: "",
    password: "",
    confirmPassword: "",
    rcNumber: "",
    homeAddress: "",
    businessCACLink: "",
    businessNEPCLink: "",
    businessName: "",
    businessEmail: "",
    businessMobile: "",
    businessAddress: "",
    country: "",
    city: "",
  });

  const rememberMe = () => {
    setValue(!checked);
  };

  const inputHandler = (e) => {
    const name = e.target.id;
    let value = e.target.value;
    if (name == "name" || name == "personalEmail" || name == "businessName")
      value = Util.capitalize(value);

    setInput({ ...input, [name]: value });
    const result = Util.validate(validation, input, [name]);
    setValidation({ ...validation, ...result });
  };

  const validateForm = async (currentTab) => {
    if (
      currentTab === "personalInformation" ||
      currentTab === "businessInformation" ||
      currentTab === "securityInformation" ||
      currentTab === "documentation"
    ) {
      goToTab(currentTab);
    } else if (tab.personalInformation) {
      const result = Util.validate(validation, input, [
        "name",
        "email",
        "mobile",
        "homeAddress",
      ]);
      setValidation({ ...validation, ...result });

      if (
        validation.validName &&
        validation.validEmail &&
        validation.validMobile &&
        validation.validHomeAddress
      ) {
        goToTab("businessInformation");
      } else {
        toast.error("Required personal information not filled! ");
      }
    } else if (tab.businessInformation) {
      const result = Util.validate(validation, input, [
        "businessName",
        "businessEmail",
        "businessMobile",
        "businessAddress",
        "country",
        "city",
      ]);
      setValidation({ ...validation, ...result });
      if (
        validation.validBusinessName &&
        validation.validBusinessEmail &&
        validation.validBusinessMobile &&
        validation.validBusinessAddress &&
        validation.validCountry &&
        validation.validCity
      ) {
        goToTab("securityInformation");
      } else {
        toast.error("Required business information not filled! ");
      }
    } else if (tab.securityInformation) {
      const result = Util.validate(validation, input, ["password"]);
      setValidation({ ...validation, ...result });
      if (validation.validPassword) {
        if (input.password == input.confirmPassword) {
          goToTab("documentation");
        } else {
          toast.error("Confirm password not equal to password");
        }
      } else {
        toast.error("Valid password required!");
      }
    } else if (tab.documentation) {
      if (input.idType != null && input.idType != "") {
        if (docs.idFile == "" || docs.idFile == null) {
          toast.error("Valid identity required!");
        } else {
          setFormValidity(true);
          submitForm();
        }
      } else {
        toast.error("Select identity type!");
      }
    }
  };

  const submitForm = async () => {
    if (checked) {
      setIsProcessing(true);

      var uploaded = false;
      const files = new FormData();
      files.append("files", docs.idFile);
      if (docs.cacFile) files.append("files", docs.cacFile);
      if (docs.nepcFile) files.append("files", docs.nepcFile);

      try {
        const fileUploadResponse = await fileService.uploadFiles(files);
        if (fileUploadResponse.data.success) {
          const fileURLs = fileUploadResponse.data.payload;
          console.log("processed files:", fileURLs);
          if (fileURLs[0]) input.idLink = fileURLs[0];
          if (fileURLs[1]) input.cacLink = fileURLs[1];
          if (fileURLs[2]) input.nepcLink = fileURLs[2];
          uploaded = true;
        } else {
          toast.error(fileUploadResponse.message);
        }
      } catch (e) {
        setIsProcessing(false);
        console.log(e);
      }

      if (uploaded) {
        authService
          .registerUser(input)
          .then((result) => {
            setIsProcessing(false);
            if (result.data.success) {
              toast.success(result.data.message);
              storage.save("email", result.data.payload.email);
              setTimeout(() => {
                navigate("/otp?r=vendor");
              }, 500);
            } else {
              setInput({ ...input, confirmPassword: input.password });
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            setInput({ ...input, confirmPassword: input.password });
            setIsProcessing(false);
            toast.error(
              e.response ? e.response.data.message : "Connection refused!"
            );
          });
      } else {
        toast.error("Unable to process attached documents");
      }
    } else {
      toast.warn("Accept terms and condition to proceed!");
    }
  };

  const findCountryCode = (selectedCountry) => {
    var countryCode = null;
    Countries.getCountries().forEach((country) => {
      if (country.name == selectedCountry) countryCode = country.iso2;
    });
    return countryCode;
  };

  const getCities = (selectedCountry) => {
    const countryCode = findCountryCode(selectedCountry);
    const states = States.getStates({ filters: { country_code: countryCode } });
    var stateList = [];
    if (selectedCountry == "United Kingdom") {
      stateList = ["England", "Scotland", "Wales", "Northern Ireland"];
    } else {
      states.forEach((state) => {
        stateList.push(state.name);
      });
    }
    setStates(stateList);
  };

  const goToTab = (tab) => {
    setTab({
      businessInformation: false,
      personalInformation: false,
      securityInformation: false,
      documentation: false,
    });

    if (tab === "businessInformation")
      setTab({ ...tab, businessInformation: true });
    if (tab === "personalInformation")
      setTab({ ...tab, personalInformation: true });
    if (tab === "securityInformation")
      setTab({ ...tab, securityInformation: true });
    if (tab === "documentation") setTab({ ...tab, documentation: true });
  };

  useEffect(() => {
    let countryList = [];
    Countries.getCountries().forEach((country) => {
      countryList.push(country.name);
    });
    setCountries(countryList);
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="become-saller-wrapper w-full">
        <div className="title mb-10">
          <PageTitle
            title="Personal Information"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Become Vendor", path: "/become-saller" },
            ]}
          />
        </div>
        <div className="content-wrapper w-full mb-10 ">
          <div className="container-x mx-auto">
            <div className="flex">
              <div className="col-md-5 right-body card">
                <img
                  src="/assets/images/vendor.png"
                  className="w-full side-img"
                />
              </div>
              <div className="col-md-5 right-body bg-white card p-7">
                <div className="input-area">
                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                    <InputCom
                      placeholder="Full Name"
                      label="Full Name*"
                      name="name"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validName ? `border-red-600` : ""
                      }
                      value={input.name}
                      inputHandler={inputHandler}
                    />

                    <InputCom
                      placeholder="Your email address"
                      label="Email Address*"
                      name="email"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validEmail ? `border-red-600` : ""
                      }
                      value={input.email}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                    <InputCom
                      placeholder="Your Phone Number"
                      label="Phone Number*"
                      name="mobile"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validMobile ? `border-red-600` : ""
                      }
                      value={input.mobile}
                      inputHandler={inputHandler}
                    />

                    <InputCom
                      placeholder="Home address"
                      label="Your Home Address*"
                      name="homeAddress"
                      type="text"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validHomeAddress ? `border-red-600` : ""
                      }
                      value={input.homeAddress}
                      inputHandler={inputHandler}
                    />
                  </div>
                </div>
                <div className="signup-area flex justify-cente">
                  <p className="text-sm text-qgraytwo font-normal mt-3">
                    Already have a Business Account?
                    <Link
                      to="https://dashboard.bellabanga.com/"
                      className="ml-2 text-qblack"
                    >
                      Login to your Dashboard
                    </Link>
                  </p>
                </div>

                <div className="signin-area mb-3 pt-4">
                  <div className="flex justify-center">
                    <button
                      type="button"
                      // onClick={formValidated ? submitForm : validateForm}
                      className="rounded-lg black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                    >
                      <span>
                        {isProcessing ? (
                          <ClipLoader
                            color="#fff"
                            size={30}
                            data-testid="loader"
                          />
                        ) : formValidated ? (
                          "Next"
                        ) : (
                          "Continue"
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
