import { useEffect } from "react";
import { Link } from "react-router-dom";
import Util from "../../utils/util";

export default function CategoryListing({ className, categories = [] }) {
  useEffect(() => {
    console.log(categories);
  }, []);
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="w-full flex inline-flex gap-5 overflow-auto">
        {categories.map((item, idx) => {
          return (
            <div
              key={idx}
              data-aos="fade-left"
              data-aos-duration="500"
              className="item w-full flex flex-col items-center card p-5"
            >
              <Link to={`/products/${item.category.id}`}>
                <div className="w-[170px] h-[170px] rounded-full bg-white flex justify-center items-center overflow-hidden mb-2">
                  <img
                    className="img img-responsive category-listing-image"
                    src={`${Util.storageBaseURL}/${item.category.imageLink}`}
                    alt=""
                  />
                </div>
              </Link>
              <Link to={`/products/${item.category.id}`}>
                <p className="text-base font-500 text-center">
                  {item.category.name}
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
