export default function ChevronRightIcon({ className }) {
  return (
    <span>
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.0918"
          y="0.636719"
          width="6.94219"
          height="1.54271"
          transform="rotate(45 1.0918 0.636719)"
          fill="#1D1D1D"
        />
        <rect
          x="6.00195"
          y="5.54492"
          width="6.94219"
          height="1.54271"
          transform="rotate(135 6.00195 5.54492)"
          fill="#1D1D1D"
        />
      </svg>
    </span>
  );
}
