import { http } from "../utils/http";
import Util from "../utils/util";

let productService = {
  findAllProductByFilter: async (from, to, page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/filter?from=${from}&to=${to}&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  findAllProducts: async (page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/get_all_active?page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  searchProducts: async (searchquery, page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/search?q=" +
        searchquery +
        "&status=AC&page=" +
        page +
        "&size=" +
        Util.pageSize,
      Util.getHeaders()
    );
  },
  searchProductsByVendor: async (vendorId, searchquery, page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/search?vendorId=" +
        vendorId +
        "&q=" +
        searchquery +
        "&status=AC&page=" +
        page +
        "&size=" +
        Util.pageSize,
      Util.getHeaders()
    );
  },
  findProductsByVendorId: async (vendorId, page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/get_all_by_vendor/${vendorId}?status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  findProductsByVendorHandle: async (handle, page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/get_all_by_handle/${handle}?status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },

  findProductByCategoryId: async (categoryId, page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/get_by_category_id/${categoryId}?status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  findProductBySubCategoryId: async (subCategoryId, page) => {
    return await http.get(
      `${Util.baseUrl}/api/product/get_by_sub_category_id/${subCategoryId}?status=AC&page=${page}&size=${Util.pageSize}`,
      Util.getHeaders()
    );
  },
  getProductsById: async (productId) => {
    return await http.get(
      Util.baseUrl + "/api/product/get_by_id/" + productId,
      Util.getHeaders()
    );
  },
  getOrderById: async (id) => {
    return await http.get(
      Util.baseUrl + "/api/order/get_by_id/" + id,
      Util.getHeaders()
    );
  },
  getNewArrivedProducts: async (page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/get_new_arrivals?page=" +
        page +
        "&size=" +
        Util.pageSize,
      Util.getHeaders()
    );
  },
  getTopProducts: async (page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/get_top_rated?size=" +
        Util.pageSize +
        "&page=" +
        page,
      Util.getHeaders()
    );
  },
  getDealOfTheWeek: async (page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/get_deal_of_week?page=" +
        page +
        "&size=" +
        Util.pageSize,
      Util.getHeaders()
    );
  },
  getRecentylyViewedByUserId: async (userId, page) => {
    return await http.get(
      Util.baseUrl +
        "/api/product/viewed_by_user/" +
        userId +
        "?page=" +
        page +
        "&size=" +
        Util.pageSize,
      Util.getHeaders()
    );
  },
  addReview: async (review) => {
    return await http.post(
      Util.baseUrl + "/api/review/add",
      review,
      Util.getAuthorizedHeaders()
    );
  },
  addRecentlyViewdProduct: async (userId, prodId) => {
    return await http.post(
      Util.baseUrl + "/api/product/view/mark/" + userId + "?product=" + prodId,
      Util.getHeaders()
    );
  },
  getAllProductRiviewsById: async (id) => {
    return await http.get(
      Util.baseUrl + "/api/review/get_by_product_id/" + id,
      Util.getHeaders()
    );
  },
};

export { productService };
