import { http } from "../utils/http";
import { storage } from "../utils/storage";
import Util from "../utils/util";

let authService = {
  registerUser: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/signup`,
      body,
      Util.getHeaders()
    );
  },
  updateUserDetails: async (id, body) => {
    return await http.put(
      `${Util.baseUrl}/api/user/profile-update/${id}`,
      body,
      Util.getHeaders()
    );
  },
  verifyAccount: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/verify`,
      body,
      Util.getHeaders()
    );
  },
  resetPassword: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/password-reset`,
      body,
      Util.getHeaders()
    );
  },
  changePassword: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/change-password`,
      body,
      Util.getAuthorizedHeaders()
    );
  },
  resendOTP: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/generate-otp`,
      body,
      Util.getHeaders()
    );
  },
  userLogin: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/user/signin`,
      body,
      Util.getHeaders()
    );
  },
  logout: (redirect) => {
    storage.remove("userToken");
    storage.remove("user");
    window.location.replace(
      redirect ? `/login?redirect=${redirect}` : `/login`
    );
  },
};
export { authService };
