import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../Access";
import { cartService } from "../../services/cart.service";
import Util from "../../utils/util";
import BreadcrumbCom from "../BreadcrumbCom";
import EmptyCardError from "../EmptyCardError";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import ProductsTable from "./ProductsTable";

export default function CardPage({ cart = true }) {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [cartItems, setCartItems] = useState([]);
  const getCartItems = () => {
    let cart = cartService.getItems();
    setCartItems(cart);
  };

  const clearCart = () => {
    cartService.clearCart();
    getCartItems();
  };

  useEffect(() => {
    getCartItems();
  }, []);
  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full">
            <PageTitle
              title={cartItems && cartItems != null ? `Your Cart` : ""}
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "cart", path: "/cart" },
              ]}
            />
          </div>
          {cartItems && cartItems != null ? (
            <div className="w-full mt-[23px]">
              <div className="container-x mx-auto">
                <ProductsTable
                  className="mb-[30px]"
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                />
                <div className="w-full sm:flex justify-between">
                  <div className="flex space-x-2.5 items-center">
                    <Link to="/products">
                      <div className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                        <span className="text-sm font-semibold">
                          Continue Shopping
                        </span>
                      </div>
                    </Link>
                    <button type="button" onClick={clearCart}>
                      <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                        <span className="text-sm font-semibold">
                          Clear Cart
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="w-full mt-[30px] flex sm:justify-end">
                  <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px]">
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-6">
                        <p className="text-[15px] font-medium text-qblack">
                          Subtotal
                        </p>
                        <p className="text-[15px] font-medium text-qred">
                          <span>
                            {Util.decodeCurrency(
                              cartItems[0]?.currencyCode,
                              cartItems
                                .map((item, idx) => {
                                  return (
                                    Number(item.product.price) *
                                    Number(item.quantity)
                                  );
                                })
                                .reduce((partialSum, a) => partialSum + a, 0)
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-6">
                        <p className="text-[15px] font-medium text-qblack">
                          Shipping fee
                        </p>
                        <p className="text-[15px] font-medium text-qred">
                          <span>
                            {Util.decodeCurrency(
                              cartItems[0]?.currencyCode,
                              cartItems
                                .map((item, idx) => {
                                  return (
                                    Number(item.product.shippingFees) *
                                    Number(item.quantity)
                                  );
                                })
                                .reduce((partialSum, a) => partialSum + a, 0)
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>

                    <div className="total mb-6">
                      <div className=" flex justify-between">
                        <p className="text-[18px] font-medium text-qblack">
                          Total
                        </p>
                        <p className="text-[18px] font-medium text-qred">
                          {Util.decodeCurrency(
                            cartItems[0]?.currencyCode,
                            cartItems
                              .map((item, idx) => {
                                return (
                                  Number(item.product.price) *
                                  Number(item.quantity)
                                );
                              })
                              .reduce((partialSum, a) => partialSum + a, 0) +
                              cartItems
                                .map((item, idx) => {
                                  return (
                                    Number(item.product.shippingFees) *
                                    Number(item.quantity)
                                  );
                                })
                                .reduce((partialSum, a) => partialSum + a, 0)
                          )}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={
                        authenticated ? "/checkout" : "/login?redirect=checkout"
                      }
                    >
                      <div className="w-full h-[50px] black-btn flex justify-center items-center">
                        <span className="text-sm font-semibold">
                          Proceed to Checkout
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className=" w-[300px] justify-center items-center text-center m-auto">
              <Link
                to="/products"
                className="w-[200px] h-[50px] black-btn flex justify-center items-center"
              >
                <span className="text-sm font-semibold">
                  Start Shopping Now
                </span>
              </Link>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
