function DataIteration(props) {
  const { datas = [], startLength, endLength, children } = props;
  return (
    <>
      {datas?.slice(startLength, endLength).map((data, index) => (
        <div key={index} className="item">
          {children({ data })}
        </div>
      ))}
    </>
  );
}

export default DataIteration;
