import { useContext, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../Access";
import { cartService } from "../../../services/cart.service";
import { wishlistService } from "../../../services/wishlist.service";
import Util from "../../../utils/util";
import QuickViewIco from "../icons/QuickViewIco";
import ThinLove from "../icons/ThinLove";

export default function ProductCardRowStyleOneTwo({ className, datas }) {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({
    color: null,
    size: null,
    quantity: 1,
    product: datas,
  });

  const addToCart = () => {
    setCart({ ...cart, product: datas });
    setIsLoading(true);
    toast.success("Added to Cart");
    setTimeout(() => {
      setIsLoading(false);
      cartService.addToCart(cart);
    }, 100);
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const addWishlist = async () => {
    if (authenticated) {
      setIsProcessing(true);
      wishlistService
        .addWishList(data?.id)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success("Added to wishlist");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error(error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      var redirect = location.search.split("=")[1];
      location.href = `login?redirect=${redirect}`;
    }
  };

  return (
    <div
      data-aos="fade-left"
      className={`product-row-card-style-one-two w-full h-[250px] bg-white group relative overflow-hidden ${
        className || ""
      }`}
    >
      <div className="flex space-x-5 items-center w-full h-full p-[16px]">
        <div className="w-1/3 h-full">
          <Link to={`/product-detail/${datas?.id}`}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${datas.image}`}
              onError={Util.onImageError}
              alt=""
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
        <div className="flex-1 flex flex-col justify-center h-full">
          <div>
            <Link to={`/product-detail/${datas?.id}`}>
              <p className="title mb-2 sm:text-[20px] text-[15px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">
                {datas.title}
                <small>{datas?.unit ? ` / ${datas?.unit}` : ``}</small>
              </p>
            </Link>
            <p className="price flex space-x-2 items-center  mb-2.5">
              <span className="offer-price text-qred font-600 sm:text-[24px] text-base">
                {datas.offer_price}
              </span>
              <span className="main-price text-qgray line-through font-600 sm:text-[18px] text-base">
                {datas.price}
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* quick-access-btns */}
      <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-[30px]  transition-all duration-300 ease-in-out">
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <QuickViewIco />
          </span>
        </a>
        <button type="button" onClick={addWishlist}>
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            {isProcessing ? <ClipLoader /> : <ThinLove />}
          </span>
        </button>
      </div>
    </div>
  );
}
