import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../Access";
import { cartService } from "../../../../services/cart.service";
import { chatService } from "../../../../services/chat.service";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";

export default function HeaderOne({ className, drawerAction, type = 1 }) {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;
  const [cartCount, setCartCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);

  const logout = () => {
    storage.remove("user");
    storage.remove("userToken");

    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  const loadMessageCount = () => {
    chatService
      .countNewMessages()
      .then((response) => {
        setMessagesCount(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setInterval(() => {
      let cart = cartService.getItems();
      setCartCount(cart && cart != null ? cart.length : 0);
    }, 100);
  }, []);

  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      <TopBar className="quomodo-shop-top-bar" />
      <Middlebar
        type={type}
        className="quomodo-shop-middle-bar lg:block hidden"
      />

      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            {type === 3 ? (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                  alt="logo"
                />
              </a>
            ) : type === 4 ? (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                  alt="logo"
                />
              </a>
            ) : (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  src={`${process.env.PUBLIC_URL}/assets/images/LogoNew.svg`}
                  alt="logo"
                />
              </a>
            )}
          </div>

          <div className="cart relative cursor-pointer ml-8">
            <Link to="/chat">
              <span>
                <img
                  className="w-6"
                  src="https://img.icons8.com/windows/32/chat.png"
                  alt="chat"
                />
              </span>
              <span
                className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                  type === 3 ? "bg-qh3-blue text-white" : "bg-qorange"
                }`}
              >
                {messagesCount}
              </span>
            </Link>
          </div>

          <div className="cart relative cursor-pointer">
            <Link to="/cart">
              <span>
                <ThinBag />
              </span>

              <span
                className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${
                  type === 3 ? "bg-qh3-blue text-white" : "bg-qorange"
                }`}
              >
                {cartCount}
              </span>
            </Link>
          </div>
        </div>
      </div>

      <Navbar type={type} className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}
