import {
  ChatBubbleOutlineOutlined,
  SupervisedUserCircleRounded,
} from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { SiMinutemailer } from "react-icons/si";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { UserContext } from "../../Access";
import { chatMessages } from "../../atom/globalState";
import { chatService } from "../../services/chat.service";
import { fileService } from "../../services/file.service";
import { userService } from "../../services/user.service";
import Util from "../../utils/util";
import Error from "../Error";
import SearchInput from "../Helpers/SearchInput";
import Layout from "../Partials/Layout";

var stompClient = null;

export default function Chat() {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const authenticated = currentUser && currentUser.uuid ? true : false;
  if (!authenticated) window.location.href = "/login?redirect=chat";

  const sentSound = `${process.env.PUBLIC_URL}/assets/sound/sent.mp3`;
  const receivedSound = `${process.env.PUBLIC_URL}/assets/sound/received.mp3`;
  const [searchVal, setSearchVal] = useState("");
  const [activeContact, setActiveContact] = useState({});
  const [selctedFiles, setSelctedFiles] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const [vendors, setVendors] = useState([]);
  const [chatContacts, setChatContacts] = useState([]);
  const { id } = useParams();

  const [tab, setTab] = useState({
    chats: true,
    contacts: false,
  });
  const [isLoadingVendors, setIsLoadingVendors] = useState(true);
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [messages, setMessages] = useRecoilState(chatMessages);

  const getVendors = () => {
    setIsLoadingVendors(true);
    userService
      .findAllVendors(currentPage)
      .then((result) => {
        if (result.data && result.data.success === true) {
          const responseData = result.data.payload.content;
          console.log(responseData);
          setVendors(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingVendors(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingVendors(false);
      });
  };
  const getVendorById = () => {
    setIsLoadingVendors(true);
    userService
      .findVendorById(id)
      .then((result) => {
        if (result.data && result.data.success === true) {
          const responseData = result.data.payload;
          onSwitchContact({
            role: responseData.role,
            name: responseData.businessName || responseData.name,
            dpLink: responseData.dpLink,
            uuid: responseData.uuid,
            lastLoginDate: responseData.lastLoginDate,
          });
        }
      })
      .catch((error) => {
        setIsLoadingVendors(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingVendors(false);
      });
  };
  const getChatContacts = () => {
    setIsLoadingContacts(true);
    chatService
      .findAllChatContacts(currentPage)
      .then((result) => {
        if (result.data && result.data.success === true) {
          const responseData = result.data.payload.content;
          setChatContacts(responseData);
          if (responseData.length > 0)
            if (responseData[0].recipientId === currentUser.uuid) {
              onSwitchContact({
                name: responseData[0].senderName,
                dpLink: responseData[0].senderDpLink,
                uuid: responseData[0].senderId,
              });
            } else {
              onSwitchContact({
                name: responseData[0].recipientName,
                dpLink: responseData[0].recipientDpLink,
                uuid: responseData[0].recipientId,
              });
            }
          console.log("activeContact:>>", activeContact);
        }
      })
      .catch((error) => {
        setIsLoadingContacts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingContacts(false);
      });
  };

  const searchChatContacts = (q) => {
    setIsLoadingContacts(true);
    chatService
      .searchChatContacts(q, currentPage)
      .then((result) => {
        if (result.data && result.data.success === true) {
          const responseData = result.data.payload.content;
          console.log("contacts:", responseData);
          setChatContacts(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingContacts(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoadingContacts(false);
      });
  };

  const searchVendors = (q) => {
    setIsLoadingVendors(true);
    userService
      .searchVendors(q, currentPage)
      .then((result) => {
        if (result.data && result.data.success === true) {
          const responseData = result.data.payload.content;
          console.log(responseData);
          setVendors(responseData);
        } else {
          setVendors([]);
        }
      })
      .catch((error) => {
        setIsLoadingVendors(false);
        console.log(error);
        setVendors([]);
      })
      .finally(() => {
        setIsLoadingVendors(false);
      });
  };

  const handleSearch = (event) => {
    const q = event.target.value;
    setSearchVal(q);
    if (tab.chats) {
      if (q.length > 2) searchChatContacts(q);
      else getChatContacts();
    } else {
      if (q.length > 2) searchVendors(q);
      else getVendors();
    }
  };

  const onSearchRequest = () => {
    if (tab.chats) {
      if (searchVal.length > 2) searchChatContacts(searchVal);
      else getChatContacts();
    } else {
      if (searchVal.length > 2) searchVendors(searchVal);
      else getVendors();
    }
  };

  const connect = () => {
    const sockjs = new SockJS(`${Util.baseUrl}/ws`);
    stompClient = Stomp.over(sockjs);
    console.log("connecting... using:", stompClient);
    stompClient.connect({}, onConnected, onError);
    console.log("Stoped connection");
  };

  const onConnected = (con) => {
    console.log(con);
    console.log("connected", currentUser);
    stompClient.subscribe(
      "/user/" + currentUser.uuid + "/queue/messages",
      onMessageReceived
    );
  };

  const onError = (err) => {
    console.log(err);
  };

  const tabSwitch = (tab) => {
    if (tab === "chats")
      setTab({
        chats: true,
        contacts: false,
      });

    if (tab === "contacts")
      setTab({
        chats: false,
        contacts: true,
      });
  };

  const onFileSelected = (e) => {
    const files = e.target.files;
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    setSelctedFiles(newFiles);
  };

  const onMessageReceived = (msg) => {
    const notification = JSON.parse(msg.body);

    console.log("notification:", notification);
    console.log("activeContact:", activeContact);

    getChatContacts();
    if (notification.senderId == activeContact.uuid) {
      chatService
        .findChat(notification.id)
        .then((message) => {
          const newMessages = JSON.parse(
            sessionStorage.getItem("recoil-persist")
          ).chatMessages;
          console.log(newMessages);
          newMessages.push(message);
          setMessages(newMessages);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.info("New message from " + notification.senderName);
    }
  };

  const sendMessage = async () => {
    if (messageContent.trim() !== "" || selctedFiles.length > 0) {
      var message = messageContent.trim();
      var uploadedFiles = "";
      var upoloaded = false;

      if (selctedFiles.length > 0) {
        setIsSendingMessage(true);
        const files = new FormData();
        console.log("selctedFiles:", selctedFiles);
        selctedFiles.forEach((file) => {
          files.append("files", file);
        });
        const fileUploadResponse = await fileService.uploadFiles(files);
        var uploaded = true;
        if (fileUploadResponse.data.success) {
          const fileURLs = fileUploadResponse.data.payload;
          fileURLs.forEach((proceedFile) => {
            if (!proceedFile.includes("unable")) {
              uploadedFiles += `<${proceedFile}> `;
            } else {
              uploaded = false;
            }
          });
          const newContent = messageContent + uploadedFiles;
          message = newContent;
        } else {
          uploaded = false;
          toast.error(fileUploadResponse.message);
        }
      }

      if (message.trim() !== "") {
        if (message || (selctedFiles.length > 0 && uploaded == true)) {
          setIsSendingMessage(true);
          const messageBody = {
            senderId: currentUser.uuid,
            recipientId: activeContact.uuid,
            senderName: currentUser.businessName || currentUser.name,
            recipientName: activeContact.businessName || activeContact.name,
            content: message,
          };
          stompClient.send("/app/chat", {}, JSON.stringify(messageBody));
          const newMessages = [...messages];
          newMessages.push(messageBody);
          console.log("newMessages", newMessages);
          setMessages(newMessages);
          setMessageContent("");
          setSelctedFiles([]);
          setIsSendingMessage(false);

          try {
            const audio = new Audio(sentSound);
            audio.play();
          } catch (ex) {
            console.error(ex);
          }
        } else {
          toast.error("Unable to send file");
        }
      }
    }
  };

  const loadMessages = () => {
    setIsLoadingMessages(true);
    chatService
      .findChatMessages(currentUser.uuid, activeContact.uuid)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingMessages(false));
  };

  const onSwitchContact = (user) => {
    setIsLoadingMessages(true);
    setActiveContact(user);
    loadMessages();
  };

  useEffect(() => {
    if (id) getVendorById();

    connect();
    getChatContacts();
    getVendors();
  }, []);

  useEffect(() => {
    loadMessages();
  }, [activeContact]);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto py-20">
          <div className="flex flex-col md:flex-row gap-6 md:space-x-4">
            <div className="bg-white h-full w-full md:w-[450px] p-8  rounded-md shadow-sm">
              <ul className="tabs  w-full mb-4 flex list-none flex-row flex-wrap border-b-0 pl-0">
                <li
                  className={`${
                    tab.chats ? "bg-qyellow text-white" : ""
                  } text-neutral-500 my-2 mr-3 px-2 py-3 rounded cursor-pointer`}
                  onClick={() => tabSwitch("chats")}
                >
                  <ChatBubbleOutlineOutlined /> Chats
                </li>
                <li
                  className={`${
                    tab.contacts ? "bg-qyellow text-white" : ""
                  } text-neutral-500  my-2 mr-3 px-2 py-3 rounded cursor-pointer`}
                  onClick={() => tabSwitch("contacts")}
                >
                  <SupervisedUserCircleRounded /> Vendors
                </li>
              </ul>

              <div className="tab-pages">
                {tab.chats ? (
                  <div className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block">
                    <div className="chat-list ">
                      <div className="container search mb-8">
                        <SearchInput
                          onChange={handleSearch}
                          onClick={onSearchRequest}
                          placeholder="Search e.g Jiji"
                          type="search"
                        />
                      </div>
                      <div className="list-header">
                        {isLoadingContacts ? (
                          <ClipLoader />
                        ) : chatContacts && chatContacts.length > 0 ? (
                          chatContacts.map((contact, idx) => (
                            <div
                              key={idx}
                              className="flex flex-row space-x-4 mb-5 cursor-pointer"
                              onClick={() => {
                                onSwitchContact(
                                  contact.recipientId === currentUser.uuid
                                    ? {
                                        name: contact.senderName,
                                        dpLink: contact.senderDpLink,
                                        uuid: contact.senderId,
                                      }
                                    : {
                                        name: contact.recipientName,
                                        dpLink: contact.recipientDpLink,
                                        uuid: contact.recipientId,
                                      }
                                );
                              }}
                            >
                              <div className="relative">
                                <div className="w-12 h-12 bg-gray-500 rounded-full relative">
                                  <img
                                    className="w-12 h-12 rounded-full"
                                    src={`${Util.storageBaseURL}/${
                                      contact.senderId == currentUser.uuid
                                        ? contact.recipientDpLink
                                        : contact.senderDpLink
                                    }`}
                                    onError={Util.onProfileDPError}
                                  />
                                  <div className="absolute bottom-0 right-0 transform -translate-x-1/2">
                                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="user-name justify-center">
                                <h4 className="text-lg font-bold">
                                  {contact.senderId == currentUser.uuid
                                    ? contact?.recipientName
                                    : contact?.senderName}
                                </h4>
                                <p className="text-sm chat-text">
                                  {Util.absMessageContent(contact.lastContent)}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Error message="No Chat History" />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="transition-opacity duration-150 ease-linear data-[te-tab-active]:block">
                    <div className="chat-list ">
                      <div className="container search mb-8">
                        <SearchInput
                          onChange={handleSearch}
                          onClick={onSearchRequest}
                          placeholder="Search vendor e.g Jiji"
                          type="search"
                        />
                      </div>
                      <div className="list-header">
                        {isLoadingVendors ? (
                          <ClipLoader />
                        ) : vendors && vendors.length > 0 ? (
                          vendors.map((vendor, idx) => (
                            <div
                              key={idx}
                              className="flex flex-row space-x-4 mb-5 cursor-pointer"
                              onClick={() => {
                                onSwitchContact(vendor);
                              }}
                            >
                              <div className="relative">
                                <div className="w-12 h-12 bg-gray-500 rounded-full relative">
                                  <img
                                    className="w-12 h-12 rounded-full"
                                    src={`${Util.storageBaseURL}/${vendor.dpLink}`}
                                    onError={Util.onProfileDPError}
                                  />
                                </div>
                              </div>
                              <div className="user-name justify-center">
                                <h4 className="text-lg font-bold">
                                  {vendor?.businessName}
                                </h4>
                                <p className="text-sm">
                                  {vendor.city + ","} {vendor.country}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Error message="No Vendors available" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white w-full h-full rounded-md shadow-sm p-8">
              {activeContact && activeContact?.uuid ? (
                <div className="chat-body">
                  <div className="chat-header">
                    <div className="flex flex-row space-x-4">
                      <div className="relative">
                        <div className="w-12 h-12 bg-gray-500 rounded-full relative">
                          <img
                            className="w-12 h-12 rounded-full"
                            src={`${Util.storageBaseURL}/${activeContact.dpLink}`}
                            onError={Util.onProfileDPError}
                          />
                          <div className="absolute bottom-0 right-0 transform -translate-x-1/2">
                            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                          </div>
                        </div>
                      </div>
                      <div className="vendor-user flex flex-col items-center justify-center">
                        <h4 className="text-lg font-bold">
                          {activeContact?.businessName || activeContact.name}
                        </h4>
                        <div className="recent-chat pr-1 text center">
                          <p className="">{activeContact.role}</p>
                        </div>
                      </div>
                    </div>
                    <hr className="w-full h-px bg-gray-100 my-3" />
                  </div>
                  <div className="chatting-body">
                    <div className="flex flex-col position-relative">
                      {isLoadingMessages ? (
                        <ClipLoader />
                      ) : messages && messages.length > 0 ? (
                        messages.map((message) => (
                          <>
                            {message.senderId != currentUser.uuid ? (
                              <div className="md:flex d-flex flex-row space-x-4 mb-3 w-[80%] received-message">
                                <div className="bg-gray-300 color-black w-full h-full rounded-md shadow-sm p-2 px-2  text-left md:text-justify  loat-left">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: Util.decodeMessage(
                                        message.content
                                      ),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            ) : (
                              <div className="md:flex d-flex flex-row space-x-4 mb-3 w-[80%] sent-message">
                                <div className="bg-qyellow w-full h-full rounded-md shadow-sm p-2 px-2 text-white  text-right md:text-justify  float-right">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: Util.decodeMessage(
                                        message.content
                                      ),
                                    }}
                                  ></div>
                                </div>
                              </div>
                            )}
                          </>
                        ))
                      ) : (
                        <Error
                          message={`No messages from ${activeContact.businessName}`}
                        />
                      )}

                      <div className="flex flex-row justify-between items-end  w-full md:px-2 py-4 bg-white border-t border-gray-200">
                        <div className="flex flex-col md:flex-row items-center md:space-x-4 w-full position-relative">
                          <div className="flex flex-row items-center md:space-x-4 w-full">
                            <input
                              type="file"
                              name="files[]"
                              multiple="true"
                              id="chatFile"
                              style={{ display: "none" }}
                              onChange={onFileSelected}
                            />
                            {isSendingMessage ? (
                              <ClipLoader />
                            ) : (
                              <label htmlFor="chatFile">
                                <RiAttachment2
                                  size={20}
                                  className="text-gray-500 cursor-pointer"
                                />
                                {selctedFiles.length > 0 ? (
                                  <span className="bg-qorange p-1 rounded-full text-white absolute">
                                    {selctedFiles.length}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </label>
                            )}

                            <div className="flex-1">
                              <textarea
                                placeholder="Type your message here"
                                name="messageContent"
                                className="w-full h-[55px] focus:ring-0 focus:outline-none p-3 bg-transparent placeholder:text-sm border border-gray-300 rounded-md"
                                value={messageContent}
                                onChange={(e) =>
                                  setMessageContent(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                          <div className="flex justify-end right-send-message">
                            <button
                              type="button"
                              className="black-btn py-3 px-5 rounded-md flex justify-center items-center"
                              onClick={sendMessage}
                            >
                              <SiMinutemailer
                                size={15}
                                className="text-white"
                              />
                              <span className="text-sm font-semibold ml-2">
                                Send
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Error message="Chat not found" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
