import { http } from "../utils/http";
import Util from "../utils/util";

let categoryService = {
  findAllCategories: async () => {
    return await http.get(
      `${Util.baseUrl}/api/category/get_all`,
      Util.getHeaders()
    );
  },
  findCategoryById: async (id) => {
    return await http.get(
      `${Util.baseUrl}/api/category/get_by_id/${id}`,
      Util.getHeaders()
    );
  },
};
export { categoryService };
