import { http } from "../utils/http";
import Util from "../utils/util";

let fxRateService = {
  findAllFxRates: async () => {
    return await http.get(`${Util.baseUrl}/api/fx/get_all`, Util.getHeaders());
  },
  reloadCurrencyRates: async () => {
    return await http.put(
      `${Util.baseUrl}/api/fx/refresh`,
      {},
      Util.getHeaders()
    );
  },
};
export { fxRateService };
