import UploadIcon from "../icons/UploadIcon";
import "./style.css";

export default function FileBox({
  type,
  className,
  name,
  label,
  onChange,
  value,
  labelClasses = "text-qgray text-[13px] font-normal",
}) {
  return (
    <>
      <div className="block relative w-full ">
        {label && (
          <label
            className={`input-label capitalize block  mb-2 ${
              labelClasses || ""
            }`}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <div className={`my-select-box ${className || ""}`}>
          <input
            type="file"
            className="hidden"
            name={name}
            id={name}
            onChange={onChange}
          />
          <label className="flex inline-flex" htmlFor={name}>
            <span>
              <UploadIcon />
            </span>
            <span className="ml-3">{value ? value : "Choose File"}</span>
          </label>
        </div>
      </div>
    </>
  );
}
