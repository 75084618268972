import { storage } from "../utils/storage";
import Util from "../utils/util";

let cartService = {
  addToCart: function (newItem) {
    const preferedCurrency = storage.get("currencyCode") || "USD";
    const product = { ...newItem.product }; //copy object

    const newPrice = Util.convertCurrency(
      product.price,
      product.currencyCode,
      preferedCurrency
    );
    const newShippingFees = Util.convertCurrency(
      product.shippingFees,
      product.currencyCode,
      preferedCurrency
    );
    // console.log(product.shippingFees, newShippingFees);
    product.price = newPrice;
    product.shippingFees = newShippingFees;

    newItem.currencyCode = preferedCurrency;
    newItem.product = product;
    newItem.id = Util.getRandomString();

    if (storage.get("cart") == null) {
      storage.save("cart", JSON.stringify([newItem]));
    } else {
      let cartItems = JSON.parse(storage.get("cart"));
      cartItems.push(newItem);
      storage.remove("cart");
      storage.save("cart", JSON.stringify(cartItems));
    }
  },
  saveCart: function (cart) {
    storage.save("cart", JSON.stringify(cart));
  },
  removeCartItem: function (item) {
    let cartItems = JSON.parse(storage.get("cart"));
    let currentItems = cartItems.filter(
      (storedItem) => storedItem.id !== item?.id
    );
    storage.save("cart", JSON.stringify(currentItems));
  },
  getItems: function () {
    let cartItems = JSON.parse(storage.get("cart"));
    return cartItems;
  },
  clearCart: function () {
    storage.remove("cart");
  },
};
export { cartService };
