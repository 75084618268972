import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Util from "../../utils/util";

export default function VendorListing({ className, sectionTitle, data = [] }) {
  return (
    <div data-aos="fade-up" className={`w-full card ${className || ""}`}>
      <div className="container-x mx-auto">
        <div className=" section-title flex justify-between items-center mb-5">
          <div>
            <h1 className="sm:text-3xl text-xl font-600 text-qblacktext">
              {sectionTitle}
            </h1>
          </div>
          <Link to="/vendors">
            <p className="text-base font-600 text-qblack">
              View More
              <ArrowRightAlt />
            </p>
          </Link>
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-4 grid-cols-2">
          {data.slice(0, 8).map((item, idx) => {
            return (
              <Link to={`/${item.handle}`} key={idx}>
                <div className="item">
                  <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                    {item.dpLink ? (
                      <img
                        src={`${Util.storageBaseURL}/${item.dpLink}`}
                        onError={Util.onImageError}
                        className="w-[60px] h-[60px] mr-3 rounded-[100px]"
                        alt="logo"
                      />
                    ) : (
                      <img
                        className="vendor-logo"
                        src={`${process.env.PUBLIC_URL}/assets/images/favicon.ico`}
                      />
                    )}
                    <div>
                      <h5>{item.businessName}</h5>
                      <smal className="vendor-subtile">
                        {item.city},{item.country}
                      </smal>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
