import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { RecoilRoot } from "recoil";
import recoilPersist from "recoil-persist";
import App from "./App";
import ScrollTop from "./ScrollTop";
import { fxRateService } from "./services/fxrate.service";
import { visitService } from "./services/visit.service";
import { storage } from "./utils/storage";
import Util from "./utils/util";

const token = storage.get("userToken");
const userData = storage.get("user");

let auth = null;
if (userData != null) {
  var user = JSON.parse(userData);
  if (user.role == "USER") {
    auth = user;
    auth.token = token;
  }
}

export const UserContext = React.createContext({
  user: auth,
  setUser: () => {},
});

function Access() {
  const [user, setUser] = useState(auth);
  const value = { user, setUser };

  const { RecoilPersist, updateState } = recoilPersist([], {
    key: "recoil-persist",
    storage: sessionStorage,
  });

  const refreshCurrencyRates = () => {
    fxRateService
      .reloadCurrencyRates()
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          storage.saveObject("rate", responseData);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };
  const setVisit = (date) => {
    visitService
      .addVisit()
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          storage.saveSession("VISIT", date);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error);
      });
  };

  const visitedEarlier = () => {
    const today = Util.getFormatedDate(new Date());
    const visit = storage.getSession("VISIT");
    const notVisited = visit == null || visit != today;

    if (notVisited) setVisit(today);
  };
  useEffect(() => {
    visitedEarlier();
    refreshCurrencyRates();
  }, []);

  return (
    <div className="Access">
      <RecoilRoot initializeState={updateState}>
        <RecoilPersist />
        <ToastContainer />
        <UserContext.Provider value={value}>
          <ScrollTop />
          <App />
        </UserContext.Provider>
      </RecoilRoot>
    </div>
  );
}

export default Access;
