// import React from "react";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";

const CustomModal = ({ title, open, onClose, children, width, height }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          position: "relative",
        },
      }}
      maxWidth={false}
      fullWidth={false}
    >
      <DialogTitle>
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: isMobile ? "14px" : "16px",
            top: isMobile ? `calc(-8px + 2%)` : "8px",
            zIndex: 1300,
            backgroundColor: "#86ca2a",
            color: "#fff",
            borderRadius: "70%",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: "#86ca2a",
              color: "#d1fae5",
            },
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width,
          height,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CustomModal.defaultProps = {
  width: "auto",
  height: "auto",
};

export default CustomModal;
