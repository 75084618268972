import { http } from "../utils/http";
import Util from "../utils/util";

let orderService = {
  placeOrder: async (orderDetails) => {
    return await http.post(
      Util.baseUrl + "/api/order/add",
      orderDetails,
      Util.getAuthorizedHeaders()
    );
  },
  updateOrderStatus: async (orderId, status) => {
    return await http.put(
      Util.baseUrl + `/api/order/status-update/${orderId}?status=${status}`,
      {},
      Util.getAuthorizedHeaders()
    );
  },
  updateOrderDetailStatus: async (orderId, detaildId, status) => {
    return await http.put(
      Util.baseUrl +
        `/api/order/product/status-update/${orderId}/${detaildId}?status=${status}`,
      {},
      Util.getAuthorizedHeaders()
    );
  },
  cancelOder: async (orderId) => {
    return await http.put(
      Util.baseUrl + `/api/order/cancel/${orderId}`,
      {},
      Util.getAuthorizedHeaders()
    );
  },
  getOrdersByUserId: async (userId) => {
    return await http.get(
      Util.baseUrl + "/api/order/get_by_customer/" + userId,
      Util.getAuthorizedHeaders()
    );
  },
  getOrderItemsByOrderId: async (orderId) => {
    return await http.get(
      Util.baseUrl + "/api/order/get_details_by_id/" + orderId,
      Util.getAuthorizedHeaders()
    );
  },
  getOrderByOrderId: async (orderId) => {
    return await http.get(
      Util.baseUrl + "/api/order/get_by_id/" + orderId,
      Util.getAuthorizedHeaders()
    );
  },
  getOrderByReferenceId: async (refId) => {
    return await http.get(
      Util.baseUrl + "/api/order/get_by_refid/" + refId,
      Util.getHeaders()
    );
  },
};
export { orderService };
