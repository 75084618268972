import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login/index";
import Otp from "./components/Auth/Otp";
import Profile from "./components/Auth/Profile";
import ResetPassword from "./components/Auth/ResetPassword";
import Signup from "./components/Auth/Signup";
import SignupBusiness from "./components/Auth/SignupBusiness";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import Home from "./components/Home";
import HomeFive from "./components/HomeFive";
import HomeFour from "./components/HomeFour";
import HomeThree from "./components/HomeThree";
import HomeTwo from "./components/HomeTwo";
import OrderDetail from "./components/OrderDetail/index.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductDetailPage from "./components/ProductDetail";
import ProductsCompaire from "./components/ProductsCompaire/index";
import RefundPolicy from "./components/RefundPolicy/index.jsx";
import ReturnPolicy from "./components/ReturnPolicy/index.jsx";
import SuccessMessage from "./components/SuccessMessage";
import SuccessTicket from "./components/SuccessTicket";
import Support from "./components/Support";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Vendor from "./components/Vendor";
import Vendors from "./components/Vendors";
import Wishlist from "./components/Wishlist";
import Chat from "./components/chat/index.jsx";
export default function Routers() {
  return (
    <Routes>
      <Route exact path="/otp" element={<Otp />} />
      <Route exact path="/reset-password" element={<ResetPassword />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/home-five" element={<HomeFive />} />
      <Route exact path="/products" element={<AllProductPage />} />
      <Route exact path="/products/:categoryId" element={<AllProductPage />} />
      <Route
        exact
        path="/products/:categoryId/:subCategoryId"
        element={<AllProductPage />}
      />
      <Route exact path="/:handle" element={<Vendor />} />
      <Route exact path="/:handle/:categoryId" element={<Vendor />} />
      <Route
        exact
        path="/:handle/:categoryId/:subCategoryId"
        element={<Vendor />}
      />
      <Route exact path="/product-detail/:id" element={<ProductDetailPage />} />
      <Route exact path="/order-detail/:id" element={<OrderDetail />} />

      <Route exact path="/cart" element={<CardPage />} />
      <Route exact path="/checkout" element={<CheakoutPage />} />
      <Route exact path="/wishlist" element={<Wishlist />} />
      <Route exact path="/flash-sale" element={<FlashSale />} />
      <Route exact path="/products-compaire" element={<ProductsCompaire />} />
      <Route exact path="/vendors" element={<Vendors />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/blog" element={<Blog />} />
      <Route exact path="/tracking-order" element={<TrackingOrder />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/signup-business" element={<SignupBusiness />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/return-policy" element={<ReturnPolicy />} />
      <Route exact path="/refund-policy" element={<RefundPolicy />} />
      <Route exact path="/terms-condition" element={<TermsCondition />} />
      <Route exact path="/support" element={<Support />} />
      <Route exact path="/success-message" element={<SuccessMessage />} />
      <Route exact path="/success-ticket" element={<SuccessTicket />} />
      <Route exact path="/chat" element={<Chat />} />
      <Route exact path="/chat/:id" element={<Chat />} />
      <Route exact path="*" element={<FourZeroFour />} />
    </Routes>
  );
}
