import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { productService } from "../../services/product.service";
import { userService } from "../../services/user.service";
import Util from "../../utils/util";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import Star from "../Helpers/icons/Star";
export default function SallerInfo({ vendor }) {
  const [isLoadingVendorProducts, setIsLoadingVendorProducts] = useState(true);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [vendorDetails, setVendorDetails] = useState({});

  const getVendor = () => {
    setIsLoadingVendorProducts(true);
    userService
      .findVendorById(vendor?.vendorId)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setVendorDetails(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingVendorProducts(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingVendorProducts(false);
      });
  };

  const getVendorProducts = () => {
    setIsLoadingVendorProducts(true);
    productService
      .findProductsByVendorId(vendor?.vendorId, 0)
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload.content;
          setVendorProducts(responseData);
        }
      })
      .catch((error) => {
        setIsLoadingVendorProducts(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoadingVendorProducts(false);
      });
  };

  useEffect(() => {
    getVendorProducts();
    getVendor();
  }, [vendor]);
  return (
    <div className="saller-info-wrapper w-full">
      <div className="saller-info sm:flex justify-between items-center pb-[30px] border-b border-[#E8E8E8]">
        <div className="sm:flex sm:space-x-5 items-center sm:w-1/4">
          <div className="saller w-[73px] h-[73px] rounded-full overflow-hidden">
            <img
              src={`${Util.storageBaseURL}/${vendorDetails.dp}`}
              onError={Util.onProfileDPError}
              alt="saller"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h6 className="text-[18px] font-medium leading-[30px]">
              {vendorDetails?.businessName || vendorDetails.name}
            </h6>
            <div className="flex items-center mt-4">
              <div className="flex">
                {Util.calculateRating(vendor?.rating ? vendor?.rating : 0).map(
                  (item, idx) => (
                    <span key={idx + Math.random()}>
                      <Star value={item} />
                    </span>
                  )
                )}
              </div>
              <span className="text-[13px] font-normal ml-1">
                ({vendor?.rating ? vendor?.rating : 0})
              </span>
            </div>
          </div>
        </div>
      </div>
      {vendorProducts && vendorProducts.length > 0 ? (
        <div className="saller-product w-full mt-[30px]">
          <h1 className="text-[18px] font-medium mb-5">Product from Vendor</h1>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5">
            {vendorProducts.map((data) => {
              return (
                <div data-aos="fade-up" key={data.id} className="item">
                  <ProductCardStyleOne data={data} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
