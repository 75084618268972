import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../Access";
import { cartService } from "../../../services/cart.service";
import { wishlistService } from "../../../services/wishlist.service";
import Util from "../../../utils/util";
import QuickViewIco from "../icons/QuickViewIco";
import Star from "../icons/Star";
import ThinLove from "../icons/ThinLove";
export default function ProductCardStyleOne({ data = {}, type }) {
  const available = true;
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const [rating, setRating] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({
    color: null,
    size: null,
    quantity: 1,
    product: data,
  });

  const addToCart = () => {
    setCart({ ...cart, product: data });
    setIsLoading(true);
    toast.success("Added to Cart");
    setTimeout(() => {
      setIsLoading(false);
      cartService.addToCart(cart);
    }, 100);
  };

  useEffect(() => {
    if (!data || data.ratings == null) data.ratings = 0;
    const tempRating = [];
    for (let i = 1; i <= 5; i++) {
      if (data.ratings >= i) {
        tempRating.push(1);
      } else {
        tempRating.push(0);
      }

      setRating(tempRating);
    }
  }, [data]);

  const [isProcessing, setIsProcessing] = useState(false);
  const addWishlist = async () => {
    if (authenticated) {
      setIsProcessing(true);
      wishlistService
        .addWishList(data?.id)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success("Added to wishlist");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error(error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      var redirect = location.search.split("=")[1];
      location.href = `login?redirect=${redirect}`;
    }
  };

  return (
    <div
      className="product-card-one w-full h-full bg-white relative group overflow-hidden"
      style={{ boxShadow: "0px 15px 64px 0px rgba(0, 0, 0, 0.05)" }}
    >
      <Link to={`/product-detail/${data?.id}`}>
        <div
          className="product-card-img w-full h-[300px]"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${Util.storageBaseURL}/${data?.thumbnail})`,
          }}
        >
          <div className="px-[30px] absolute left-0 top-3 w-full">
            <div className="progress w-full h-[5px] rounded-[22px] bg-primarygray relative overflow-hidden">
              <div
                style={{
                  width: `${data?.stock ? 100 - available : 0}%`,
                }}
                className={`h-full absolute left-0 top-0  ${
                  type === 3 ? "bg-qh3-blue" : "bg-qyellow"
                }`}
              ></div>
            </div>
          </div>

          <div className="product-type absolute right-[14px] top-[17px]">
            <span
              className={`text-[9px] font-700 leading-none py-[6px] px-3 uppercase text-white rounded-full tracking-wider ${
                data?.categoryName === "popular" ? "bg-qyellow" : "bg-qyellow"
              }`}
            >
              {data?.categoryName}
            </span>
          </div>
        </div>
        <div className="product-card-details px-[30px] py-[20px] relative">
          <span className="subcategory-span bg-danger">
            {data?.subCategoryName}
          </span>
          <div className="reviews flex space-x-[1px] mb-3">
            {rating.map((item, idx) => (
              <span key={idx + Math.random()}>
                <Star value={item} />
              </span>
            ))}
          </div>
          <Link to={`/product-detail/${data?.id}`}>
            <p className="title mb-2 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-[#86ca2a]">
              {data?.name}
              <small>{data?.unit ? ` / ${data?.unit}` : ``}</small>
            </p>
          </Link>
          <p className="price">
            <span className="main-price text-qgray line-through font-600 text-[18px]">
              {Util.decodeCurrency(
                data?.currencyCode,
                data?.price + 5 * (data?.price / 100)
              )}
            </span>
            <span className="offer-price text-qred font-600 text-[18px] ml-2">
              {Util.decodeCurrency(data?.currencyCode, data?.price)}
            </span>
          </p>
        </div>
        {/* quick-access-btns */}
        <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-20  transition-all duration-300 ease-in-out">
          <Link to={`/product-detail/${data?.id}`}>
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              <QuickViewIco />
            </span>
          </Link>
          <button type="button" onClick={addWishlist}>
            <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
              {isProcessing ? <ClipLoader /> : <ThinLove />}
            </span>
          </button>
        </div>
      </Link>
    </div>
  );
}
