import ProductCardRowStyleOne from "./Cards/ProductCardRowStyleOne";

export default function ProductSection({ className, products, type }) {
  return (
    <div
      className={`section-content w-full grid sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 ${
        className || ""
      }`}
    >
      {products.map((data) => {
        return (
          <div key={data.id} className="item w-full">
            <ProductCardRowStyleOne type={type} data={data} />
          </div>
        );
      })}
    </div>
  );
}
