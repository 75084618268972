import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { categoryService } from "../../../../services/category.service";
import { storage } from "../../../../utils/storage";
import Arrow from "../../../Helpers/icons/Arrow";
import ChevronDownIcon from "../../../Helpers/icons/ChevronDownIcon";
import Loader from "../../../Helpers/icons/Loader";

export default function Navbar({ className, type }) {
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const getCategories = () => {
    setIsLoading(true);
    categoryService
      .findAllCategories()
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setData(responseData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const logout = () => {
    storage.remove("user");
    storage.remove("userToken");

    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  };

  const handler = () => {
    setToggle(!categoryToggle);
  };
  useEffect(() => {
    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }
    getCategories();
  }, [categoryToggle]);

  return (
    <div
      className={`nav-widget-wrapper w-full  h-[60px] relative z-30 ${
        type === 3 ? "bg-qorange" : "bg-qorange"
      }  ${className || ""}`}
    >
      <div className="container-x mx-auto h-full">
        <div className="w-full h-full relative">
          <div className="w-full h-full flex justify-between items-center">
            <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
              <div className="category w-[270px] h-[53px] bg-qorange px-5 rounded-t-md mt-[6px] relative">
                <button
                  onClick={handler}
                  type="button"
                  className="w-full h-full flex justify-between items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <span>
                      <svg
                        className="fill-current"
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        color="white"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="14" height="1" />
                        <rect y="8" width="14" height="1" />
                        <rect y="4" width="10" height="1" />
                      </svg>
                    </span>
                    <span className="text-sm font-600 text-white">
                      All Categories
                    </span>
                  </div>
                  <div>
                    <Arrow
                      width="5.78538"
                      height="1.28564"
                      className="fill-current text-white"
                    />
                  </div>
                </button>
                {categoryToggle && (
                  <div
                    className="fixed top-0 left-0 w-full h-full -z-10"
                    onClick={handler}
                  ></div>
                )}
                <div
                  className="category-dropdown w-full absolute left-0 top-[53px] overflow-hidden"
                  style={{ height: `${elementsSize} ` }}
                >
                  <ul className="categories-list">
                    {isLoading ? (
                      <li>
                        <Loader />
                      </li>
                    ) : data && data.length > 0 ? (
                      data.map((item, idx) => {
                        return (
                          <li className="category-item">
                            <Link to={`/products/${item.category.id}`}>
                              <div
                                className={`flex justify-between items-center px-5 h-10 bg-white  transition-all duration-300 ease-in-out cursor-pointer text-qblack ${
                                  type === 3
                                    ? "hover:bg-qorange hover:text-white"
                                    : "hover:bg-qorange"
                                }`}
                              >
                                <div className="flex items-center space-x-6">
                                  {/* <DefaultIcon /> */}
                                  <span className="text-xs font-400">
                                    {item.category.name}
                                  </span>
                                </div>
                                <div>
                                  <ChevronDownIcon />
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
              <div className="nav">
                <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                  <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3 ? "text-white" : "text-white"
                      }`}
                    >
                      <Link to="/">
                        <span>Home</span>
                      </Link>
                    </span>
                    <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                      <div
                        className="w-full bg-white flex justify-between items-center "
                        style={{
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      ></div>
                    </div>
                  </li>
                  <li>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3 ? "text-white" : "text-white"
                      }`}
                    >
                      <Link to="/products">
                        <span>Market</span>
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${
                        type === 3 ? "text-white" : "text-white"
                      }`}
                    >
                      <Link to="/vendors">
                        <span>Vendors</span>
                      </Link>
                    </span>
                  </li>

                  <li>
                    <Link to="/contact">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Contact</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/support">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>Support</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-white"
                        }`}
                      >
                        <span>FAQs</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {authenticated ? (
              <div className="flex items-center">
                <div className="pr-2 color-white">
                  <Link to="/profile">
                    <span className="text-sm font-600 text-white">
                      {user.name}
                    </span>
                  </Link>
                </div>
                <div>
                  <span className="text-sm font-600 text-white">|</span>
                </div>
                &nbsp;&nbsp;
                <Link to="/signup-business">
                  <div className="flex space-x-2 items-center ">
                    <span className="text-sm font-600 text-white">
                      Become a Vendor
                    </span>
                    <span>
                      <svg
                        className="fill-current"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.08984"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(45 1.08984 0)"
                          fill="white"
                        />
                        <rect
                          x="6"
                          y="4.9082"
                          width="6.94106"
                          height="1.54246"
                          transform="rotate(135 6 4.9082)"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="pr-2">
                  <Link to="/login">
                    <span className="text-sm font-600 text-white">Login</span>
                  </Link>
                </div>
                <div>
                  <span className="text-sm font-600 text-white">|</span>
                </div>
                <div className="pr-6 pl-2">
                  <Link to="/signup">
                    <span className="text-sm font-600 text-white">
                      Register
                    </span>
                  </Link>
                </div>
                <div className="become-seller-btn">
                  <Link to="/signup-business">
                    <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                      <div className="flex space-x-2 items-center ">
                        <span className="text-sm font-600">
                          Become a Vendor
                        </span>
                        <span>
                          <svg
                            className="fill-current"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1.08984"
                              width="6.94106"
                              height="1.54246"
                              transform="rotate(45 1.08984 0)"
                              fill="white"
                            />
                            <rect
                              x="6"
                              y="4.9082"
                              width="6.94106"
                              height="1.54246"
                              transform="rotate(135 6 4.9082)"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
