import { useContext, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../../Access";
import { authService } from "../../../../services/auth.service";
import Util from "../../../../utils/util";
import InputCom from "../../../Helpers/InputCom";
import PasswordSvg from "./PasswordSvg";
export default function PasswordTab() {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const [isProcessing, setIsProcessing] = useState(false);
  const [input, setInput] = useState({
    oldPassword: "",
    nePpassword: "",
    confirmPassword: "",
  });
  const [validation, setValidation] = useState({
    validOldPassword: true,
    validNewPassword: true,
    validConfirmPassword: true,
  });

  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value });
    Util.validate(validation, input, [name]);
  };

  const updatePassword = () => {
    Util.validate(validation, input, ["oldPassword", "newPassword"]);
    if (validation.validOldPassword && validation.validNewPassword) {
      if (input.newPassword == input.confirmPassword) {
        setIsProcessing(true);

        const request = {
          oldPassword: input.oldPassword,
          newPassword: input.newPassword,
        };
        authService
          .changePassword(request)
          .then((result) => {
            if (result?.data && result?.data?.success) {
              toast.success("Password change successful");
              setInput({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsProcessing(false);
            toast.error(
              error.response?.data
                ? error.response?.data
                : "Invalid Old Password"
            );
          })
          .finally(() => {
            setIsProcessing(false);
          });
      } else {
        toast.error("Password Mismatch!");
      }
    } else {
      toast.error("All fields required!");
    }
  };
  return (
    <div className="changePasswordTab w-full">
      <div className="w-full flex xl:flex-row flex-col-reverse space-x-5 xl:items-center">
        <div className="w-[397px] mb-10">
          <div className="input-field mb-6 py-3">
            <InputCom
              label="Old Password*"
              name="oldPassword"
              placeholder="● ● ● ● ● ●"
              type="password"
              inputClasses="h-[50px]"
              value={input.oldPassword}
              inputHandler={inputHandler}
            />
          </div>
          <div className="input-field mb-6 py-3">
            <InputCom
              placeholder="● ● ● ● ● ●"
              label="New Password*"
              name="newPassword"
              type="password"
              inputClasses="h-[50px]"
              value={input.newPassword}
              inputHandler={inputHandler}
            />
          </div>
          <div className="input-field mb-6 py-3">
            <InputCom
              placeholder="● ● ● ● ● ●"
              label="Confirm Password*"
              name="confirmPassword"
              type="password"
              inputClasses="h-[50px]"
              value={input.confirmPassword}
              inputHandler={inputHandler}
            />
          </div>
          <div className="w-full mt-[30px] flex justify-start py-5">
            <div className="sm:flex sm:space-x-[30px] items-center">
              <div className="w-[180px] h-[50px]">
                <button
                  onClick={!isProcessing ? updatePassword : null}
                  type="button"
                  className="black-btn w-[200px] h-[50px]  flex justify-center"
                >
                  <span className="flex space-x-1 items-center h-full">
                    <span>
                      {isProcessing ? (
                        <ClipLoader
                          color="#fff"
                          size={30}
                          data-testid="loader"
                        />
                      ) : (
                        <span className="text-sm font-semibold">
                          Change Password
                        </span>
                      )}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:flex hidden justify-end">
          <PasswordSvg />
        </div>
      </div>
    </div>
  );
}
