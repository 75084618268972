// import React from "react";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100px"
    >
      {/* Create 6-8 rectangular boxes */}
      {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <Box
          key={index}
          width={20}
          height={20}
          backgroundColor="#fa9833"
          marginRight={2}
          borderRadius={5}
        />
      ))}
      {/* <CircularProgress size={20} color="secondary" /> */}
    </Box>
  );
};

export default Loader;
