import { useState } from "react";
import { Link } from "react-router-dom";
import { storage } from "../../../utils/storage";

export default function SearchBox({ className, type, placeholder }) {
  const prevSearchVal = location.search.split("=")[1];
  const currentRoute = location.pathname;

  const [activeVendor, setActiveVendor] = useState(
    storage.getObject("activeVendor")
  );
  const [searchVal, setSearchVal] = useState(
    prevSearchVal ? prevSearchVal : ""
  );

  return (
    <>
      <div
        className={`w-full h-full flex items-center  border border-qgray-border bg-white ${
          className || ""
        }`}
      >
        <div className="flex-1 h-full">
          <div className="h-full">
            <input
              type="search"
              className="search-input"
              placeholder={`${
                placeholder
                  ? placeholder
                  : currentRoute == "/vendors"
                  ? "Search Vendors e.g Bella Store"
                  : "Search Products e.g Okra"
              }`}
              value={searchVal}
              onChange={(e) => {
                if (!activeVendor && !activeVendor?.id)
                  setActiveVendor(storage.getObject("activeVendor"));
                setSearchVal(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-[1px] h-[22px] bg-qgray-border"></div>

        <Link
          className={` w-[93px] h-full text-sm font-600 p-3 pl-6 ${
            type === 3 ? "bg-qh3-blue text-white" : "search-btn"
          }`}
          to={
            searchVal && searchVal.length > 2
              ? currentRoute == "/vendors" ||
                currentRoute == `/${activeVendor?.handle}`
                ? `${currentRoute}?search=${searchVal}`
                : `/products?search=${searchVal}`
              : "#"
          }
        >
          Search
        </Link>
      </div>
    </>
  );
}
