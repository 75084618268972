import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { orderService } from "../../services/order.service";
import Util from "../../utils/util";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import Thumbnail from "./Thumbnail";

export default function TrackingOrder() {
  const [order, setOrder] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [validation, setValidation] = useState({
    orderReferenceNumber: true,
  });
  const [input, setInput] = useState({
    orderReferenceNumber: "",
  });

  const inputHandler = (e) => {
    const name = e.target.id;
    const key = `valid${Util.capitalize(name)}`;
    setInput({ ...input, [name]: e.target.value });
    Util.validate(validation, input, [name]);
  };

  const trackOrder = async () => {
    Util.validate(validation, input, ["orderReferenceNumber"]);
    if (validation.validOrderReferenceNumber) {
      setIsProcessing(true);
      orderService
        .getOrderByReferenceId(input?.orderReferenceNumber)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            if (["CA", "RJ", "PP"].includes(order.status)) {
              toast.error("Oops! Can't track unsuccessful order");
            } else {
              setOrder(result.data.payload);
            }
          } else {
            toast.error("Invalid tracking number");
          }
        })
        .catch((e) => {
          toast.error(
            e.response ? e.response.data.message : "Connection refused!"
          );
          console.log(e);
          setIsProcessing(false);
        });
    } else {
      toast.error("Tracking number required!");
    }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="tracking-page-wrapper w-full">
        <div className="page-title mb-[40px]">
          <PageTitle
            title="Track Order"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Track Order", path: "/tracking-order" },
            ]}
          />
        </div>
        <div className="content-wrapper w-full mb-[40px]">
          <div className="container-x mx-auto">
            <h1 className="text-[22px] text-qblack font-semibold leading-9">
              Track Your Order
            </h1>
            <p className="text-[15px] text-qgraytwo leading-8 mb-5">
              Enter your order taracking number and your secreet id.
            </p>
            <div className="w-full bg-white lg:px-[30px] px-5 py-[23px] lg:flex items-center">
              <div className="lg:w-[642px] w-full">
                <div className="mb-3">
                  <InputCom
                    placeholder="Order Number"
                    label="Order Tracking Number*"
                    inputClasses="w-full h-[50px]"
                    name="orderReferenceNumber"
                    inputHandler={inputHandler}
                  />
                </div>

                <button type="button" onClick={trackOrder} className="mt-6">
                  {isProcessing ? (
                    <ClipLoader />
                  ) : (
                    <div className="w-[142px] h-[50px] black-btn flex justify-center items-center">
                      <span>Track Now</span>
                    </div>
                  )}
                </button>

                {order && order?.id ? (
                  <div className="card mt-8 text-left">
                    <hr />
                    <ul className="pt-2 pb-2">
                      <li>
                        Payment Ref:{" "}
                        <strong>
                          {order?.paymentReferenceId
                            ? `$${order?.paymentReferenceId}`
                            : "Unpaid"}
                        </strong>
                      </li>
                      <li>
                        Order Amount:{" "}
                        <strong>
                          {Util.getAmountInCurrency(
                            order.currencyCode,
                            order?.totalAmount
                          )}
                        </strong>
                      </li>
                      <li>
                        Order Status:{" "}
                        <span className={`${order?.status}`}>
                          {Util.decodeStatus(order?.status)}
                        </span>
                      </li>

                      <li>
                        Ordered By: <strong>{order.customerName}</strong>
                      </li>
                      <li>
                        Order Date: <strong>{order?.createdDate}</strong>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex-1 flex justify-center mt-5 lg:mt-0">
                <Thumbnail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
