import { http } from "../utils/http";
import Util from "../utils/util";

let settingsService = {
  findServiceeCharge: async () => {
    return await http.get(
      `${Util.baseUrl}/api/setting/get_by_code?code=101`,
      Util.getHeaders()
    );
  },
};
export { settingsService };
