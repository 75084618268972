import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Privacy Policy", path: "privacy-policy" },
            ]}
            title="Privacy Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      This Privacy Policy explains what personal data is
                      collected when you use the{" "}
                    </font>
                  </font>
                </font>
                <font color="#ff0000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      bellabanga website and on OYE app
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      or any bellabanga mobile application (“
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>bellabanga</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      ”) and the services provided through it (together the “
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Service</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      ”), how such personal data will be used, shared.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE
                      READ, UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND
                      (II) YOU ARE OVER 16 YEARS OF AGE (OR HAVE HAD YOUR PARENT
                      OR GUARDIAN READ AND AGREE TO THIS PRIVACY POLICY FOR
                      YOU). If you do not agree, or are unable to make this
                      promise, you must not use the Service. In such case, you
                      must contact the support team via email to request
                      deletion of your account and data.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">“</font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Process</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      ”, in respect of personal data, includes to collect,
                      store, and disclose to others.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      TABLE OF CONTENTS
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#1">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>1. PERSONAL DATA CONTROLLER</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#2">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>2. CATEGORIES OF PERSONAL DATA WE COLLECT</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#3">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>3. DATA PROTECTION PRINCIPLES</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#4">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>4. FOR WHAT PURPOSES WE PROCESS PERSONAL DATA</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#5">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>
                              5. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL
                              DATA
                            </u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#6">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>6. WITH WHOM WE SHARE YOUR PERSONAL DATA</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#7">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>7. AVAILABLE REMEDIES</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#8">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>8. NO LIMITATION CLAUSE</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#9">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>
                              9. HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS&lt;
                            </u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#10">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>10. AGE LIMITATION</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#11">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>11. CHANGES TO THIS PRIVACY POLICY</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#12">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>12. DATA RETENTION</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <a href="https://jiji.ng/privacy.html#13">
                      <font color="#000000">
                        <font face="Century Gothic, serif">
                          <font size="3" style={{ fontSize: "12pt" }}>
                            <u>13. CONTACT US</u>
                          </font>
                        </font>
                      </font>
                    </a>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      1. PERSONAL DATA CONTROLLER
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Bellabanga Limited, a company registered in the Federal
                      Republic of Nigeria (with registered office at Shop B101,
                      Emirate Mall, Festac Link Road, Amuwo Odofin, Lagos,
                      Nigeria) will be the controller of your personal data In
                      relation to certain categories of personal data expressly
                      specified below 2.3.5
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          a) transaction data relating to sales of food
                          stuff/commodities on this platform
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          b) personal ID, Bank ID and САС details of food
                          stuff/commodities sellers and dealers
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          c) the Required Information of food stuff/commodities
                          sellers and dealers
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      2. CATEGORIES OF PERSONAL DATA WE COLLECT
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We collect data you give us voluntarily (for example, an
                      email address). We also collect data automatically (for
                      example, your IP address).
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginTop: "0.1in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      2.1 Data you give us.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      You may be asked to provide us information about yourself
                      when you register for and/or use the Service. This
                      information includes: first name, last name, phone number,
                      email, gender, date of birth (together “Required
                      Information”), address details, working hours, Bank
                      Verification Number, National Identification Number, etc.,
                      as well as access to your photos, albums and their
                      metadata. You will need to provide us with your ID or
                      similar identification document if and when we consider it
                      necessary. You will need to provide us with your Bank ID
                      and САС (certificate of incorporation of legal entity) if
                      you want to participate as a dealer in food
                      stuff/commodities on our website.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      To use our Service and register an account, you will need
                      to provide Required Information. You will be able to use
                      the Service even if you do not give this data to us, but
                      some Service’s functionality may be limited to you (for
                      example, if you do not register an account, you will not
                      be able to chat with other users, post ads, see contact
                      details of other users or make purchase directly on our
                      website).
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Sometimes you may also need to provide us additional
                      information in the communication with our Support Team in
                      order to fulfill your request (for example, if your
                      account was previously blocked, we may ask you to confirm
                      your identity by providing an ID document).
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      While posting an announcement or advertisement on the
                      Service, you can decide to provide additional personal
                      information on yourself. For example, you can decide to
                      make available your CV or any photos of you or bearing
                      your personal information. You acknowledge that by
                      providing your personal data in the announcement you are
                      making such data publicly available. In addition, you
                      acknowledge and agree that we will make public some
                      personal data from your profile to provide the Service, -
                      it will enable us to facilitate communication and
                      transactions between the users.{" "}
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      You should carefully consider risks associated with the
                      fact that you make certain information – in particular,
                      you phone number, address, photos, passport and financial
                      details or exact location – publicly available.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginTop: "0.1in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      2.2. Data provided to us by third parties
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you decide to log in using Facebook, we could get
                      personal data from your Facebook account. This includes
                      your profile image, name, and Facebook ID. Unless you
                      opt-out on the Facebook Login screen, we may also collect
                      other data, such as email address, gender, date of birth,
                      friends list, and location as listed in your Facebook
                      profile.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      For more information, please refer to the Facebook&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://developers.facebook.com/docs/facebook-login/permissions">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Permissions Reference</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;(describes the categories of information, which
                      Facebook may share with third parties and the set of
                      requirements) and to the Facebook&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://www.facebook.com/about/privacy/">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Data policy</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      . In addition, Facebook lets you control the choices you
                      made when connecting your Facebook profile to the App on
                      their&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://www.facebook.com/settings?tab=applications">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Apps and Websites page</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you log in with Google, we may get your name, email
                      address, profile picture and Google ID. We use your
                      personal data from Google in accordance with this Privacy
                      Policy. To know more about how Google processes your data,
                      visit its&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://policies.google.com/privacy">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Privacy Policy</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      . To remove access granted to us, visit&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://myaccount.google.com/permissions">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Google Permissions</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you decide to log in using Apple, we get Apple ID,
                      name and email from your account. You can use&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://support.apple.com/en-us/HT210425">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Hide My Email</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;function during signing in with Apple, and it will
                      create and share a unique, random email address that will
                      forward our messages to your personal email.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Apple lets you revoke access provided to the App on your
                      Apple ID Manage Page by&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://support.apple.com/en-us/HT210426">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>following the next steps</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you decide to log in or verify your phone number
                      using Truecaller, we may get personal data from your
                      Truecaller user profile upon your consent. This includes
                      your phone number, name, addresses (country code; city;
                      street; zip code), profile image, job title, company name,
                      gender, and other information from your profile. For more
                      information, please&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://www.truecaller.com/nigeria-privacy-policy">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>refer to the Truecaller Privacy Policy</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      If you were invited to create an account in the Service,
                      the person that invited you can provide personal
                      information about you, such as your phone number, email
                      address, social media account or other contact
                      information.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may collect your name, official government ID number
                      and photo image to confirm your identity. For this we will
                      share this information with a 3rd party service provider,
                      Smile Identity Inc., in order to validate it with the
                      governmental sources. Smile Identity Inc. may use your ID
                      number to collect the following additional data on you
                      from the governmental sources: full name, date of birth,
                      image, address, phone number, gender, government ID expiry
                      date or other additional information associated with your
                      ID number that the issuing authority may provide. Smile ID
                      may process or store your data outside of the borders of
                      your country.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginTop: "0.1in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      2.3. Data we collect automatically:
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Data about how you found us</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We collect data about your referring URL (that is, the
                      place on the Web where you were when you tapped on our
                      ad).
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>2.3.2. Device and Location data.</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We collect data from your device. Examples of such data
                      include: language settings, IP address, time zone, type
                      and model of a device, device settings, operating system,
                      Internet service provider, mobile carrier, hardware ID,
                      and Facebook ID.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>2.3.3. Usage data</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We record how you interact with our Service. For example,
                      we log the features, and content you interact with, how
                      often you use the Service, how long you are on the
                      Service, what sections you use, how many ads you watch.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>2.3.4. Advertising IDs</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We collect your Apple Identifier for Advertising (“IDFA”)
                      or Google Advertising ID (“AAID”) (depending on the
                      operating system of your device). You can typically reset
                      these numbers through the settings of your device’s
                      operating system (but we do not control this).
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>2.3.5. Transaction data</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you make payments through the Service, you need to
                      provide financial account data, such as your credit card
                      number, to our third-party service providers. We do not
                      collect or store full credit card number data, though we
                      may receive credit card-related data, data about the
                      transaction, including: date, time and amount of the
                      transaction, the type of payment method used.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>2.3.6. Cookies</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Please see&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/jiji-pages/cookie-policy">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>our Cookies Policy</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;to find out more about our use of cookies.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      3. DATA PROTECTION PRINCIPLES
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      In our data protection practices we strive, in particular,
                      to provide that personal data is:
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          processed in accordance with specific, legitimate and
                          lawful purpose consented to by you;
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          is adequate, accurate and without prejudice to the
                          dignity of human person;
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          stored only for the period within which it is
                          reasonably needed; and
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          secured against reasonably foreseeable hazards and
                          breaches such as theft, cyberattack, viral attack,
                          dissemination, manipulations of any kind, damage by
                          rain, fire or exposure to other natural elements.
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      4. FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We process your personal data:
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.1. To provide our Service</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      This includes enabling you to use the Service in a
                      seamless manner and preventing or addressing Service
                      errors or technical issues.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.2. To customize your experience</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We process your personal data to adjust the content of the
                      Service and make offers tailored to your personal
                      preferences and interests.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        4.3. To manage your account and provide you with
                        customer support
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We process your personal data to respond to your requests
                      for technical support, Service information or to any other
                      communication you initiate. This includes accessing your
                      account to address technical support requests. For this
                      purpose, we may send you, for example, notifications or
                      emails about the performance of our Service, security,
                      payment transactions, notices regarding our&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/rules.html">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Terms of Use</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;or this Privacy Policy.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        4.4. To communicate with you regarding your use of our
                        Service
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We communicate with you, for example, by push
                      notifications or in the chat. As a result, you may, for
                      example, receive a notification whether on the Website or
                      via email that you received a new message on Jiji. To opt
                      out of receiving push notifications, you need to change
                      the settings on your browser or mobile device. To opt out
                      of certain type of emails, you need to follow unsubscribe
                      link located in the footer of the email, by contacting our
                      support team at&nbsp;
                    </font>
                  </font>
                </font>
                <a href="mailto:support@jiji.ng">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>support@jiji.ng</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      , or in&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/profile/settings/notification">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>your profile settings</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      The services that we use for these purposes may collect
                      data concerning the date and time when the message was
                      viewed by our users, as well as when they interacted with
                      it, such as by clicking on links included in the message.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        4.5. To research and analyze your use of the Service
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      This helps us to better understand our business, analyze
                      our operations, maintain, improve, innovate, plan, design,
                      and develop Jiji and our new products. We also use such
                      data for statistical analysis purposes, to test and
                      improve our offers. This enables us to better understand
                      what features and sections of Jiji our users like more,
                      what categories of users use our Service. As a
                      consequence, we often decide how to improve Jiji based on
                      the results obtained from this processing. For example, if
                      we discover that Jobs section is not as popular as others,
                      we may focus on improving it.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.6. To send you marketing communications</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We process your personal data for our marketing campaigns.
                      We may add your email address to our marketing list. As a
                      result, you will receive information about our products,
                      such as for example, special offers, and products of our
                      partners. If you do not want to receive marketing emails
                      from us, you can unsubscribe following instructions in the
                      footer of the marketing emails, by contacting our support
                      team at&nbsp;
                    </font>
                  </font>
                </font>
                <a href="mailto:support@jiji.ng">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>support@jiji.ng</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      , or in&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/profile/settings/notification">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>your profile settings</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may also show you advertisements on the Website, and
                      send you push notifications for marketing purposes. To opt
                      out of receiving push notifications, you need to change
                      the settings on your device or/and browser.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.7. To personalize our ads</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We and our partners use your personal data to tailor ads
                      and possibly even show them to you at the relevant time.
                      For example, if you have visited our Website, you might
                      see ads of our products, for example, in your Facebook’s
                      feed.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may target advertising to you through a variety of ad
                      networks and exchanges, using data from advertising
                      technologies on and off of our Services like unique cookie
                      or similar tracking technology, pixel, device identifiers,
                      geolocation, operation system information, email.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        How to opt out or influence personalized advertising
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>iOS:</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;On your iPhone or iPad, go to “Settings,” then
                      “Privacy” and tap “Advertising” to select “Limit Ad
                      Track”. In addition, you can reset your advertising
                      identifier (this also may help you to see less of
                      personalized ads) in the same section.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Android:</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;To opt-out of ads on an Android device, simply open
                      the Google Settings app on your mobile phone, tap “Ads”
                      and enable “Opt out of interest-based ads”.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Facebook</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;also allows its users to influence the types of ads
                      they see on Facebook. To find how to control the ads you
                      see on Facebook, please go&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://www.facebook.com/help/146952742043748?helpref=related">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>here&nbsp;</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      or adjust your ads settings on&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Facebook</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        4.8. To enforce our Terms and Conditions of Use and to
                        prevent and combat fraud
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We use personal data to enforce our agreements and
                      contractual commitments, to detect, prevent, and combat
                      fraud. As a result of such processing, we may share your
                      information with others, including law enforcement
                      agencies.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.9. To comply with legal obligations</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may process, use, or share your data when the law
                      requires it, in particular, if a law enforcement agency
                      requests your data by available legal means.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>4.10. To process your payments</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We provide paid products and/or services within the
                      Service. For this purpose, we use third-party services for
                      payment processing (for example, payment processors). As a
                      result of this processing, you will be able to make a
                      payment and use the paid features of the Service.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      5. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We process your personal data, in particular, under the
                      following legal bases:
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      5.1. your consent;
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      On this basis we use your cookies.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      5.2. to perform our contract with you;
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      5.3. for our (or others') legitimate interests; Under this
                      legal basis we, in particular:
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          communicate with you regarding your use of our Service
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      This includes, for example, sending you push notifications
                      reminding you that you have unread messages. The
                      legitimate interest we rely on for this purpose is our
                      interest to encourage you to use our Service more often.
                      We also take into account the potential benefits to you.
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          research and analyze your use of the Service
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Our legitimate interest for this purpose is our interest
                      in improving our Service so that we understand users’
                      preferences and are able to provide you with a better
                      experience (for example, to make the use of our mobile
                      application easier and more enjoyable, or to introduce and
                      test new features).
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          send you marketing communications
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      The legitimate interest we rely on for this processing is
                      our interest to promote our Service in a measured and
                      appropriate way.
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          personalize our ads
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      The legitimate interest we rely on for this processing is
                      our interest to promote our Service in a reasonably
                      targeted way.
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          enforce our User Agreement&nbsp;and to prevent and
                          combat fraud
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      Our legitimate interests for this purpose are enforcing
                      our legal rights, preventing and addressing fraud and
                      unauthorised use of the Service, non-compliance with our
                      User Agreement
                    </font>
                  </font>
                </font>
                .
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      5.4. to comply with legal obligations.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      6. WITH WHOM WE SHARE YOUR PERSONAL DATA
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We share information with third parties that help us
                      operate, provide, improve, integrate, customize, support,
                      and market our Service. We may share some sets of personal
                      data, in particular, for purposes and with parties
                      indicated in Section&nbsp;2 of this Privacy Policy. The
                      types of third parties we share information with include,
                      in particular:
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>6.1. Service providers</b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We share personal data with third parties that we hire to
                      provide services or perform business functions on our
                      behalf, based on our instructions. We may share your
                      personal information with the following types of service
                      providers:
                    </font>
                  </font>
                </font>
              </p>
              <ul>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          cloud storage providers (Amazon, DigitalOcean,
                          Hetzner)
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          data analytics providers (Facebook, Google, Appsflyer)
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          login service providers (Google, Apple, Facebook,
                          Truecaller)
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          marketing partners (in particular, social media
                          networks, marketing agencies, email delivery services;
                          such as Facebook, Google)
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          payment services providers (Solid)
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
                <li>
                  <p
                    align="justify"
                    style={{
                      lineHeight: "100%",
                      marginBottom: "0in",
                      background: "#ffffff",
                    }}
                  >
                    <font color="#000000">
                      <font face="Century Gothic, serif">
                        <font size="3" style={{ fontSize: "12pt" }}>
                          local banks and credit organizations (Access Bank,
                          Sterling Bank, Union Bank, Sovereign Finance, Purple
                          Money, etc.);
                        </font>
                      </font>
                    </font>
                  </p>
                </li>
              </ul>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        6.2. Law enforcement agencies and other public
                        authorities
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may use and disclose personal data to enforce our&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/rules.html">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>Terms of Use</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      , to protect our rights, privacy, safety, or property,
                      and/or that of our affiliates, you or others, and to
                      respond to requests from courts, law enforcement agencies,
                      regulatory agencies, and other public and government
                      authorities, or in other cases provided for by law.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        6.3. Third parties as part of a merger or acquisition
                      </b>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      As we develop our business, we may buy or sell assets or
                      business offerings. Customers’ information is generally
                      one of the transferred business assets in these types of
                      transactions. We may also share such information with any
                      affiliated entity (e.g. parent company or subsidiary) and
                      may transfer such information in the course of a corporate
                      transaction, such as the sale of our business, a
                      divestiture, merger, consolidation, or asset sale, or in
                      the unlikely event of bankruptcy.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      7. AVAILABLE REMEDIES
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      If there is a data breach that may cause a risk for the
                      rights and freedoms of individuals, we will notify
                      competent supervisory authority, when such notification is
                      mandatory. If the risk is assessed by us as “high”, we
                      will also notify the affected data subjects without undue
                      delay. The time frame for such notification will be
                      developed by us on the basis of number of affected data
                      subjects and time needed to collect contact information
                      for notifications.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      If the measures taken or proposed to be taken in response
                      to the breach did not address your concerns, you have the
                      right to lodge a complaint with a competent supervisory
                      authority, or to seek redress in a court of competent
                      jurisdiction.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      8. NO LIMITATION CLAUSE
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      The Applicable Statutes of Limitation may avail us in case
                      we act in breach of the principles set out in Section 3.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      9. HOW YOU CAN EXERCISE YOUR RIGHTS
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      To be in control of your personal data, you have the
                      following rights:
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        Accessing / reviewing / updating / correcting your
                        personal data.
                      </b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;You may review, edit, or change the personal data
                      that you had previously provided to Jiji in the settings
                      section on the Website.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      You may also request a copy of your personal data
                      collected during your use of the Service.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>Deleting your personal data.</b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;You can request erasure of your personal data by
                      sending us an email at support@jiji.ng or you can delete
                      your account via the&nbsp;
                    </font>
                  </font>
                </font>
                <a href="https://jiji.ng/profile/settings/delete">
                  <font color="#000000">
                    <font face="Century Gothic, serif">
                      <font size="3" style={{ fontSize: "12pt" }}>
                        <u>link</u>
                      </font>
                    </font>
                  </font>
                </a>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      .
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      When you request deletion of your personal data, we will
                      use reasonable efforts to honor your request. In some
                      cases, we may be legally required to keep some of the data
                      for a certain time; in such event, we will fulfill your
                      request after we have complied with our obligations.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        Objecting to or restricting the use of your personal
                        data (including for direct marketing purposes).
                      </b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      &nbsp;You can ask us to stop using all or some of your
                      personal data or limit our use thereof.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <b>
                        The right to lodge a complaint with supervisory
                        authority
                      </b>
                    </font>
                  </font>
                </font>
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      . We would love you to contact us directly, so we could
                      address your concerns. Nevertheless, you have the right to
                      lodge a complaint with a competent data protection
                      supervisory authority.&nbsp;
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      To exercise any of your privacy rights, please send a
                      request to support@jiji.ng.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      10. AGE LIMITATION
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We do not knowingly process personal data from persons
                      under 16 years of age. If you learn that anyone younger
                      than 16 has provided us with personal data, please contact
                      us at&nbsp;
                    </font>
                  </font>
                </font>
                <font color="#c00000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <u>support@bellabanga.ng</u>
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      11. CHANGES TO THIS PRIVACY POLICY
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We may modify this Privacy Policy from time to time. If we
                      decide to make material changes to this Privacy Policy,
                      you will be notified through our Service or by other
                      available means and will have an opportunity to review the
                      revised Privacy Policy. By continuing to access or use the
                      Service after those changes become effective, you agree to
                      be bound by the revised Privacy Policy.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      12. DATA RETENTION
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We will store your personal data for as long as it is
                      reasonably necessary for achieving the purposes set forth
                      in this Privacy Policy (including providing the Service to
                      you), which includes (but is not limited to) the period
                      during which you have a Jiji account. We will also retain
                      and use your personal data as necessary to comply with our
                      legal obligations, resolve disputes, and enforce our
                      agreements. We will store your hashed email address, phone
                      number, identification documents, and information on the
                      fact your account has been blocked after fulfilling your
                      request on deleting your personal data.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      We will also retain and use your personal data as
                      necessary to comply with our legal obligations, resolve
                      disputes, and enforce our agreements.
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: " 100%",
                  marginTop: "0.21in",
                  marginBottom: "0.1in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      13. CONTACT US
                    </font>
                  </font>
                </font>
              </p>
              <p
                align="justify"
                style={{
                  lineHeight: "100%",
                  marginBottom: "0in",
                  background: "#ffffff",
                }}
              >
                <font color="#000000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      You may contact us at any time for details regarding this
                      Privacy Policy and its previous versions. For any
                      questions concerning your account or your personal data
                      please contact us at&nbsp;
                    </font>
                  </font>
                </font>
                <font color="#c00000">
                  <font face="Century Gothic, serif">
                    <font size="3" style={{ fontSize: "12pt" }}>
                      <u>support@bellabanga.ng.</u>
                    </font>
                  </font>
                </font>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
