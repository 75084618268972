import { http } from "../utils/http";
import Util from "../utils/util";

let contactService = {
  contact: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/message/add`,
      body,
      Util.getHeaders()
    );
  },
};
export { contactService };
