import { http } from "../utils/http";
import Util from "../utils/util";

let supportService = {
  openTicket: async (body) => {
    return await http.post(
      `${Util.baseUrl}/api/ticket/add`,
      body,
      Util.getHeaders()
    );
  },
  findTicketByUserId: async (id, page) => {
    return await http.get(
      `${Util.baseUrl}/api/ticket/get_by_user/${id}?page=${page}&size=${Util.pageSize}`,
      Util.getAuthorizedHeaders()
    );
  },
};
export { supportService };
