import { http } from "../utils/http";
import Util from "../utils/util";

let faqsService = {
  getFaqs: async () => {
    return await http.get(
      `${Util.baseUrl}/api/faqs/get_all`,
      Util.getHeaders()
    );
  },
};
export { faqsService };
