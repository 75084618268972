import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { fileService } from "../../services/file.service";
import { supportService } from "../../services/support.service";
import Util from "../../utils/util";
import FileBox from "../Helpers/FileBox";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import SelectBox from "../Helpers/SelectBox";
import Layout from "../Partials/Layout";
import { UserContext } from "./../../Access";

export default function Support() {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [docs, setDoc] = useState({
    docFile: "",
  });
  const [input, setInput] = useState({
    userId: currentUser?.id,
    name: currentUser?.name,
    emailAddress: currentUser?.email,
    phoneNumber: currentUser?.mobile,
    department: "",
    priority: "",
    attachment: "",
    title: "",
    description: "",
  });
  const [validation, setValidation] = useState({
    validName: true,
    validEmailAddress: true,
    validPhoneNumber: true,
    validDepartment: true,
    validPriority: true,
    validAttachment: true,
    validTitle: true,
    validDescription: true,
  });
  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value });
    Util.validate(validation, input, [name]);
  };
  const submitTicket = async () => {
    Util.validate(validation, input, [
      "name",
      "emailAddress",
      "phoneNumber",
      "department",
      "priority",
      "attachment",
      "title",
      "description",
    ]);
    if (
      validation.validEmailAddress &&
      validation.validName &&
      validation.validPhoneNumber &&
      validation.validDepartment &&
      validation.validPriority &&
      validation.validAttachment &&
      validation.validTitle &&
      validation.validDescription
    ) {
      if (docs.idFile === "" || docs.idFile === null) {
        toast.error("Valid identity required!");
      } else {
        console.log("Uploading....");
        setIsProcessing(true);

        var uploaded = false;
        const files = new FormData();
        files.append("files", docs.idFile);
        if (docs.docFile) files.append("files", docs.docFile);

        const fileUploadResponse = await fileService.uploadFiles(files);

        if (fileUploadResponse.data.success) {
          const fileURLs = fileUploadResponse.data.payload;
          console.log("processed files:", fileURLs);
          if (fileURLs[0]) input.attachment = fileURLs[0];

          uploaded = true;

          if (uploaded) {
            setIsLoading(true);

            supportService
              .openTicket(input)
              .then((result) => {
                if (result.data && result.data.success) {
                  toast.success(result.data.message);
                  setTimeout(() => {
                    navigate("/success-ticket");
                  }, 500);
                } else {
                  toast.error(result.data.message);
                }
              })
              .catch((error) => {
                setIsLoading(false);
                toast.error(error);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        } else {
          toast.error(fileUploadResponse.message);
        }
      }
    } else {
      toast.error("All fields required!");
    }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="page-title mb-10">
        <PageTitle
          title=" Submit A Ticket"
          breadcrumb={[
            { name: "home", path: "/" },
            { name: "Support Ticket", path: "/support" },
          ]}
        />
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="flex-1 bg-white sm:p-10 p-3">
              <div className="title flex flex-col items-center">
                <p className="text-[15px] text-qgraytwo leading-[30px] mb-5">
                  Fill the form below to submit a ticket or complain and submit
                  with a document attachedd.
                </p>
                <span className="-mt-5 block">
                  <svg
                    width="354"
                    height="30"
                    viewBox="0 0 354 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                      stroke="#FFBB38"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </div>
              <div className="inputs mt-5">
                <div className="mb-4 pb-4">
                  <InputCom
                    label="Name*"
                    placeholder="Demo Name"
                    name="name"
                    inputClasses="h-[50px]"
                    value={input.name}
                    inputHandler={inputHandler}
                  />
                </div>
                <div className="mb-4 pb-4">
                  <InputCom
                    label="Email Address*"
                    placeholder="info@quomodosoft.com"
                    name="emailAddress"
                    inputClasses="h-[50px]"
                    parentClasses={
                      !validation.validEmailAddress ? `border-red-600` : " "
                    }
                    value={input.emailAddress}
                    inputHandler={inputHandler}
                  />
                </div>
                <div className="mb-4 pb-4">
                  <InputCom
                    label="Phone Number*"
                    placeholder="+435 67890987"
                    name="phoneNumber"
                    inputClasses="h-[50px]"
                    value={input.phoneNumber}
                    inputHandler={inputHandler}
                  />
                </div>
                <div className="mb-4 pb-4">
                  <SelectBox
                    value={input.department}
                    id="deparment"
                    action={(value) => {
                      setInput({ ...input, department: value });
                    }}
                    datas={[
                      "Departments",
                      "Technical Support Desk",
                      "Customer Service Desk",
                      "Payment Service Desk",
                      "Abuse Department",
                    ]}
                    className="w-full border-2 br-5 p-4" // Add appropriate styling classes if needed
                    label="Department*"
                  >
                    {(props) => (
                      <span className="text-[13px] text-qblack">
                        {props.item}
                      </span>
                    )}
                  </SelectBox>
                </div>
                <div className="mb-4 pb-4">
                  <SelectBox
                    value={input.priority}
                    id="priority"
                    action={(value) => {
                      setInput({ ...input, priority: value });
                    }}
                    datas={["Priority", "LOW", "MEDIUM", "HIGH"]}
                    className="w-full border-2 p-4"
                    label="Priority*"
                  >
                    {(props) => (
                      <span className="text-[13px] text-qblack">
                        {props.item}
                      </span>
                    )}
                  </SelectBox>
                </div>
                <div className="mb-4 pb-4">
                  <InputCom
                    label="Title*"
                    placeholder="Your Ttile here"
                    name="title"
                    inputClasses="h-[50px]"
                    value={input.title}
                    inputHandler={inputHandler}
                  />
                </div>
                <div className="mb-5">
                  <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                    Description*
                  </h6>
                  <textarea
                    placeholder="Type your Description here"
                    name="description"
                    id="description"
                    className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                    defaultValue={input.description}
                    value={input.description}
                    onChange={inputHandler}
                  ></textarea>
                </div>
                <div className="mb-4 pb-4">
                  <FileBox
                    label="Upload Attachement"
                    name="attachment"
                    value="Choose File"
                    value={input.attachment ? input.attachment : "Choose File"}
                    className="w-full h-[50px]  px-5 flex justify-between items-center mb-2 rounded-lg"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        let selectedFile = e.target.files[0];
                        setDoc({ ...docs, docFile: selectedFile });
                        setInput({ ...input, attachment: selectedFile.name });
                      }
                    }}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={!isLoading ? submitTicket : null}
                    type="button"
                    className="black-btn w-[300px] h-[50px]  flex justify-center"
                  >
                    <span className="flex space-x-1 items-center h-full">
                      <span>
                        {isLoading ? (
                          <ClipLoader
                            color="#fff"
                            size={30}
                            data-testid="loader"
                          />
                        ) : (
                          <span className="text-sm font-semibold">Submit</span>
                        )}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
