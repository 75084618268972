export default function Star({ w = "18", h = "17", value, onClick }) {
  return (
    <svg
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
        fill={value > 0 ? "#FFA800" : "#ccc"}
      />
    </svg>
  );
}
