import { Chat, CheckBoxOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { orderService } from "../../services/order.service";
import { productService } from "../../services/product.service";
import Util from "../../utils/util";
import CustomModal from "../Helpers/Modal/CustomModal";
import ConfirmationModal from "../Helpers/Modal/Items/ConfirmationModal";
import RatingModal from "../Helpers/Modal/Items/RatingModal";
import TableOption from "../Helpers/Modal/TableOption";
export default function ProductView({ className, items, order }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalRateVendorOpen, setModalRateVendorOpen] = useState(false);
  const [modalReviewProductOpen, setModalReviewProductOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState({});
  const [selectedProduct, setSelectedProduct] = useState();

  const confirmProductOrderReceipt = (detaildId) => {
    setIsProcessing(true);
    orderService
      .updateOrderDetailStatus(order?.id, detaildId, "RC")
      .then((result) => {
        setIsProcessing(false);
        if (result.data.success) {
          setModalConfirmationOpen(false);
          toast.success("Request successful");
        }
      })
      .catch((e) => {
        toast.error(
          e.response ? e.response.data.message : "Connection refused!"
        );
        console.log(e);
        setIsProcessing(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const submitProductRating = (rate) => {
    setIsProcessing(true);
    rate.productId = selectedProduct;

    productService
      .addReview(rate)
      .then((result) => {
        setIsProcessing(false);
        if (result.data.success) {
          setModalReviewProductOpen(false);
          toast.success("Submited successfully");
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        toast.error(
          e.response ? e.response.data.message : "Connection refused!"
        );
        console.log(e);
        setIsProcessing(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    console.log("Order:", order, items);
  }, []);

  return (
    <>
      <div
        className={`product-view w-full lg:flex justify-between ${
          className || ""
        }`}
      >
        <div
          data-aos="fade-right"
          className="lg:w-[70%] xl:mr-[70px] lg:mr-[50px]"
        >
          <div className="relative w-full overflow-x-auto sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                  <td className="py-4 block whitespace-nowrap text-center">
                    #
                  </td>
                  <td className="py-4 whitespace-nowrap text-center">
                    Product
                  </td>
                  <td className="py-4 whitespace-nowrap text-center">Vendor</td>
                  <td className="py-4 whitespace-nowrap text-center">
                    Quantity
                  </td>
                  <td className="py-4 whitespace-nowrap text-center">Status</td>
                  <td className="py-4 whitespace-nowrap text-center">Amount</td>
                  <td className="py-4 whitespace-nowrap  text-center">
                    Action
                  </td>
                </tr>
                {items && items.length > 0 ? (
                  items.map((item, idx) => (
                    <tr
                      key={idx}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="text-center py-4">
                        <span className="text-lg text-qgray font-medium">
                          <Link to={`/product-detail/${item?.product?.id}`}>
                            <img
                              src={`${Util.storageBaseURL}/${item?.product?.thumbnail}`}
                              onError={Util.onImageError}
                              className="w-[40px] h-[40px] rounded border"
                            />
                          </Link>
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qgray  whitespace-nowrap">
                          {item?.details.productName}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qgray  whitespace-nowrap">
                          {item?.details.vendorName}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qgray  whitespace-nowrap">
                          {item?.details.quantity}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span
                          className={`text-sm rounded p-2 ${item?.details.status}`}
                        >
                          {Util.decodeStatus(item?.details.status)}
                        </span>
                      </td>
                      <td className="text-center py-4 px-2">
                        <span className="text-base text-qblack whitespace-nowrap px-2 ">
                          {Util.getAmountInCurrency(
                            item?.details.transactionCurrency,
                            item?.details.amount
                          )}
                        </span>
                      </td>
                      <td className="inline-flex pt-2">
                        {item?.details?.status == "DV" ||
                        item?.details.status == "DP" ? (
                          <button
                            type="button"
                            className="h-[45px] rounded bg-qyellow text-white font-bold p-2 mr-2"
                            onClick={() => {
                              setSelectedDetail(item?.details);
                              setModalConfirmationOpen(true);
                            }}
                          >
                            {isProcessing ? (
                              <ClipLoader color="#fff" />
                            ) : (
                              <span>
                                <CheckBoxOutlined />
                                Confirm Receipt
                              </span>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                        <Link
                          to={`/chat/${item?.details.vendorId}`}
                          type="button"
                          className="h-[45px] bg-qyellow rounded text-white text-center font-bold p-3 mr-2"
                        >
                          <Chat />
                        </Link>
                        <TableOption icon={<SlOptionsVertical size={35} />}>
                          <ul className="table-opt p-3 rounded">
                            {item?.details.status == "DV" ||
                            item?.details.status == "RC" ? (
                              <>
                                <li
                                  className="tab-opt"
                                  onClick={() => {
                                    setSelectedProduct(item?.product.id);
                                    setModalReviewProductOpen(true);
                                  }}
                                >
                                  Add Review
                                </li>
                                {/* <li className="tab-opt">Rate Vendor</li> */}
                              </>
                            ) : (
                              <></>
                            )}
                            <li className="tab-opt">Raise Complain</li>
                          </ul>
                        </TableOption>
                      </td>
                    </tr>
                  ))
                ) : (
                  <></> // <Error message="No product found from this order" />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex-1">
          <div className="product-details w-full mt-10 lg:mt-0">
            <span
              data-aos="fade-up"
              className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
            >
              Order
            </span>
            <p
              data-aos="fade-up"
              className="text-xl font-medium text-qblack mb-4"
            >
              #{order?.orderReferenceId}
            </p>

            <span
              data-aos="fade-up"
              className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
            >
              Shipping Fees
            </span>
            <p
              data-aos="fade-up"
              className="text-xl font-medium text-qblack mb-4"
            >
              {Util.getAmountInCurrency(
                order.currencyCode,
                order?.shippingFees
              )}
            </p>

            <span
              data-aos="fade-up"
              className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
            >
              Transaction Charges
            </span>
            <p
              data-aos="fade-up"
              className="text-xl font-medium text-qblack mb-4"
            >
              {Util.getAmountInCurrency(
                order.currencyCode,
                order?.totalSystemAmount
              )}
            </p>
            <span
              data-aos="fade-up"
              className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
            >
              Total Amount
            </span>
            <p
              data-aos="fade-up"
              className="text-xl font-medium text-qblack mb-4"
            >
              {Util.getAmountInCurrency(order.currencyCode, order?.totalAmount)}
            </p>

            <span
              data-aos="fade-up"
              className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"
            >
              Order Status
            </span>
            <p
              data-aos="fade-up"
              className={`text-xl font-medium text-qblack mb-4 ${order.status}`}
            >
              {Util.decodeStatus(order.status)}
            </p>

            <div className="product-size mb-[30px]">
              <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                Customer Details
              </span>
              <div className="w-full">
                <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block pr-2">
                  Name
                </span>
                <span>{order.customerName}</span>
              </div>
              <div className="w-full">
                <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block pr-2">
                  Recipient
                </span>
                <span>{order.customerMobileNumber}</span>
              </div>
              <div className="w-full">
                <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block pr-2">
                  Recipient Address
                </span>
                <span>
                  {order.address} {order.state} {order.country}
                </span>
              </div>
            </div>
          </div>
          <CustomModal
            title="Confirmation"
            open={modalConfirmationOpen}
            width={400}
            onClose={() => setModalConfirmationOpen(false)}
          >
            <ConfirmationModal
              message={`Are you sure you have received this item?`}
              isProcessing={isProcessing}
              onRejectHandler={() => setModalConfirmationOpen(false)}
              onAcceptHandlaer={() =>
                confirmProductOrderReceipt(selectedDetail.id)
              }
            />
          </CustomModal>

          <CustomModal
            title="Drop Review"
            open={modalReviewProductOpen}
            width={600}
            height={300}
            onClose={() => setModalReviewProductOpen(false)}
          >
            <RatingModal
              isProcessing={isProcessing}
              onClickHandler={submitProductRating}
            />
          </CustomModal>
        </div>
      </div>
    </>
  );
}
