import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function ReturnPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Return Policy", path: "return-policy" },
            ]}
            title="Return Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p className="text-[15px] leading-7">
                In the event where you are not satisfied with your order,
                Bellabanga has a 30 days return policy in place. To initiate a
                return, kindly contact us at{" "}
                <strong>support@bellabanga.com</strong> within 30 days of the
                delivery date to request a refund. It is important to note that
                the refunded amount will not include any assembly or delivery
                fees paid during the initial order. Please be aware that
                customers will be responsible for covering the expenses
                associated with the return.
              </p>
            </div>
            <p>
              All products are eligible for a return, with the exception of the
              following:
            </p>
            <div className="content-item w-full mb-10">
              <p className="text-[15px] leading-7 mb-10">
                1. Perishable Items: Items that have a limited shelf life or
                require refrigeration, such as fresh fruits, vegetables, dairy
                products, meats,etc.
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                2.Opened or Consumed Items: Products that have been opened or
                partially consumed.
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                3. Customized or Personalized Items
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                4. Bulk or Wholesale Orders
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                5. Items Beyond the Expiry Date: Products that have exceeded
                their expiration or best-before date are typically
                non-returnable.
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                6. Specialty or Artisanal Items: Handcrafted or specialty items
                that have a limited production.
                <br />
              </p>
              <p className="text-[15px] leading-7 mb-10">
                Returned products must be unused and in good condition for
                potential resale. Bellabanga Ltd. retains full discretion in
                determining whether an item is in good condition and reserves
                the right to reject returns deemed unsuitable for resale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
