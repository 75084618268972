import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { UserContext } from "../../../Access";
import { cartService } from "../../../services/cart.service";
import { wishlistService } from "../../../services/wishlist.service";
import Util from "../../../utils/util";
import QuickViewIco from "../icons/QuickViewIco";
import Star from "../icons/Star";
import ThinLove from "../icons/ThinLove";

export default function ProductCardRowStyleTwo({ className, data = {}, type }) {
  const [rating, setRating] = useState([]);
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const authenticated = user && user.uuid ? true : false;

  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({
    color: null,
    size: null,
    quantity: 1,
    product: data,
  });

  const addToCart = () => {
    setCart({ ...cart, product: data });
    setIsLoading(true);
    toast.success("Added to Cart");

    setTimeout(() => {
      setIsLoading(false);
      cartService.addToCart(cart);
    }, 100);
  };

  useEffect(() => {
    if (!data || data.ratings == null) data.ratings = 0;
    const tempRating = [];
    for (let i = 1; i <= 5; i++) {
      if (data.ratings >= i) {
        tempRating.push(1);
      } else {
        tempRating.push(0);
      }

      setRating(tempRating);
    }
  }, []);
  const [isProcessing, setIsProcessing] = useState(false);

  const addWishlist = async () => {
    if (authenticated) {
      setIsProcessing(true);
      wishlistService
        .addWishList(data?.id)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success("Added to wishlist");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsProcessing(false);
          toast.error(error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      var redirect = location.search.split("=")[1];
      location.href = `login?redirect=${redirect}`;
    }
  };

  return (
    <div
      data-aos="fade-left"
      className={`product-row-card-style-one w-full h-[250px] bg-white group relative overflow-hidden ${
        className || ""
      }`}
    >
      <div className="flex space-x-5 items-center w-full h-full lg:p-[30px] sm:p-5 p-2">
        <div className="lg:w-1/2 w-1/3 h-full">
          <Link to={`/product-detail/${data?.id}`}>
            <img
              src={`${Util.storageBaseURL}/${data?.thumbnail}`}
              onError={Util.onImageError}
              alt=""
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
        <div className="flex-1 flex flex-col justify-center h-full">
          <div>
            {/* reviews */}
            <div className="flex space-x-1 mb-3">
              {rating.map((item, idx) => (
                <span key={idx + Math.random()}>
                  <Star value={item} />
                </span>
              ))}
            </div>
            <Link to={`/product-detail/${data?.id}`}>
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-qorange">
                {data?.name}
              </p>
            </Link>
            <p className="price mb-[26px]">
              <span className="main-price text-qgray line-through font-600 sm:text-[18px] text-base">
                {Util.decodeCurrency(
                  data?.currencyCode,
                  data?.price + 5 * (data?.price / 100)
                )}
              </span>
              <span className="offer-price text-qred font-600 sm:text-[18px] text-base ml-2">
                {Util.decodeCurrency(data?.currencyCode, data?.price)}
              </span>
            </p>
            <button
              type="button"
              onClick={addToCart}
              className="w-[110px] h-[30px] "
            >
              {isLoading ? (
                <ClipLoader />
              ) : (
                <span className={type === 3 ? "blue-btn" : "yellow-btn"}>
                  <span className="text-white">Add To Cart</span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* quick-access-btns */}
      <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-[30px]  transition-all duration-300 ease-in-out">
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <QuickViewIco />
          </span>
        </a>
        <button type="button" onClick={addWishlist}>
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            {isProcessing ? <ClipLoader /> : <ThinLove />}
          </span>
        </button>
      </div>
    </div>
  );
}
