import "../../tc.css";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function TermsCondition() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Terms and condition", path: "/terms-conditions" },
            ]}
            title="Terms and Condition"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p className="c8">
                <span className="c4">Part </span>
              </p>
              <p className="c8 c12">
                <span className="c0"></span>
              </p>
              <p className="c8">
                <span className="c0">
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY!
                </span>
              </p>
              <p className="c8">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c8">
                <span className="c11">
                  Welcome to www.bellabanga.com. These Terms of Use describe the
                  terms and conditions applicable to your access and use of the
                  websites, mobile sites, mobile applications and other portals
                  owned, operated, branded or made available by BellaBanga
                  Limited (a limited Liability Company Registered in Nigeria)
                  from time to time which relate to the BellaBanga e-commerce
                  platform including but not limited to the web and
                  mobile-optimized versions of the websites identified by the
                  uniform resource locators
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=http://www.bellabanga.com&amp;sa=D&amp;source=editors&amp;ust=1703092596484493&amp;usg=AOvVaw0Si8GE68LAAvo7p_ead-yl"
                  >
                    www.bellabanga.com
                  </a>
                </span>
                <span className="c11">
                  &nbsp;and their sub-domains (collectively the &ldquo;
                </span>
                <span className="c4">Sites</span>
                <span className="c11">&rdquo; or &ldquo;</span>
                <span className="c4">BellaBanga Sites</span>
                <span className="c11">
                  &rdquo;), and the&nbsp;mobile applications&nbsp;of the
                  BellaBanga&nbsp;e-commerce&nbsp;platforms.&nbsp;This document
                  is a legally binding agreement between you as the user(s) of
                  the Sites (referred to as &ldquo;
                </span>
                <span className="c4">you</span>
                <span className="c11">&rdquo;, &ldquo;</span>
                <span className="c4">your</span>
                <span className="c11">&rdquo; or &ldquo;</span>
                <span className="c4">User</span>
                <span className="c11">
                  &rdquo; hereinafter) and the BellaBanga Limited contracting
                  entity determined in accordance with clause 2.1 below
                  (referred to as &ldquo;
                </span>
                <span className="c4">we</span>
                <span className="c11">&rdquo;, &ldquo;</span>
                <span className="c4">our</span>
                <span className="c11">&rdquo; or &ldquo;</span>
                <span className="c4">BellaBanga.com</span>
                <span className="c0">&rdquo; hereinafter).</span>
              </p>
              <p className="c8 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Application
                  and Acceptance of the Terms
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your
                  access to and use of the Sites and BellaBanga.com&rsquo; s
                  services, software and products through the Sites, which shall
                  include the platforms described in clause 7.1 of the Terms
                  (such services, software and products collectively referred to
                  as the &ldquo;
                </span>
                <span className="c4">Services</span>
                <span className="c11">
                  &rdquo; hereinafter) is subject to the terms and conditions
                  contained in this document as well as the
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=http://terms.alicdn.com/legal-agreement/terms/suit_bu1_aliexpress/suit_bu1_aliexpress201909171350_82407.html&amp;sa=D&amp;source=editors&amp;ust=1703092596485776&amp;usg=AOvVaw19DuRBMOZarEQaNWD0h55V"
                  >
                    &nbsp;BellaBanga.com Privacy Policy&nbsp;
                  </a>
                </span>
                <span className="c11">
                  (defined in clause 3.3 below), the&nbsp;
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=https://campaign.aliexpress.com/wow/gcp/itemblocken/index?wh_weex%3Dtrue%26wx_navbar_hidden%3Dtrue%26wx_navbar_transparent%3Dtrue%26ignoreNavigationBar%3Dtrue%26wx_statusbar_hidden%3Dtrue%26previewTime%3D1651045471708&amp;sa=D&amp;source=editors&amp;ust=1703092596486056&amp;usg=AOvVaw3jniLPUV1UzDTvc2_jGTIX"
                  >
                    &nbsp;BellaBanga Product Listing Policy
                  </a>
                </span>
                <span className="c11">
                  &nbsp;and any other rules and policies of the Sites that
                  BellaBanga.com may publish from time to time. This document
                  and such other rules and policies of the Sites are
                  collectively referred to below as the &ldquo;
                </span>
                <span className="c4">Terms</span>
                <span className="c0">
                  &rdquo;. By accessing and use of the Sites and Services, you
                  agree to accept and be bound by the Terms. Please do not
                  access or use the Services or the Sites if you do not accept
                  all of the Terms.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not
                  access or use the Services or the Sites and may not accept the
                  Terms if (a) you are not of legal age to form a binding
                  contract with BellaBanga.com, or (b) you are not permitted to
                  receive any Services under the laws of Nigeria or other
                  countries/regions including the country/region in which you
                  are resident or from which you access and use the Services and
                  the Sites.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  1.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  may amend any Terms at any time by posting the relevant
                  amended and restated Terms on the Sites. By continuing to
                  access or use the Services or the Sites, you agree that the
                  amended and restated Terms will apply to you.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  1.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If
                  BellaBanga.com has posted or provided a translation of the
                  English language version of the Terms, you agree that the
                  translation is provided for convenience only and that the
                  English language version will govern your access to and use of
                  the Services or the Sites.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  1.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
                  may be required to enter into separate agreement(s), whether
                  online or offline, with BellaBanga.com or our affiliate for
                  any Service (or features within the Services) (each an &ldquo;
                </span>
                <span className="c4">Additional Agreement</span>
                <span className="c0">
                  &rdquo;). If there is any conflict or inconsistency between
                  the Terms and an Additional Agreement, the Additional
                  Agreement shall take precedence over the Terms only in
                  relation to that Service (or feature within the Service)
                  concerned. &nbsp;&nbsp;
                  <br />
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  1.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Terms
                  may not otherwise be modified except in writing by an
                  authorized officer of BellaBanga.com. &nbsp; &nbsp;
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Provision of
                  Services
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If
                  you are a registered member, dealer seller or buyer on
                  www.BellaBanga.com of any of the Sites, you are contracting
                  with BellaBanga Limited. Notwithstanding anything to the
                  contrary, if you are a registered member of BellaBanga, and
                  you are resident in or access and use the BellaBanga Sites
                  from any of Jurisdictions your contract is with BellaBanga
                  Limited domiciled in Nigeria.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must
                  register as a member on the Sites in order to access and use
                  some Services. Further, BellaBanga.com reserves the right,
                  without prior notice, to restrict access to or use of certain
                  Services (or any features within the Services) to paying
                  Users, or subject to other conditions that BellaBanga.com may
                  impose in our discretion.&nbsp;
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services
                  (or any features within the Services) may vary for different
                  regions and countries. No warranty or representation is given
                  that a particular goods Service or feature or function thereof
                  or the same type and extent of the Service or features and
                  functions thereof will be available for Users. BellaBanga.com
                  may in our sole discretion limit, deny or create different
                  levels of access to and use of any Services (or any features
                  within the Services) with respect to different Users.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  may launch, change, upgrade, impose conditions to, suspend, or
                  stop any Services (or any features within the Services)
                  without prior notice except that in case of a fee-based
                  Service, such changes will not substantially adversely affect
                  the ability of such paying users to enjoy that Service.&nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some
                  Services (or part thereof) may be provided by
                  BellaBanga.com&rsquo;s affiliates and agents &nbsp;on behalf
                  of BellaBanga.com.
                </span>
              </p>
              <p className="c8 c12">
                <span className="c0"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Promotion
                  Services provided by BellaBanga.com pursuant to the terms
                  hereunder shall include:{" "}
                </span>
              </p>
              <p className="c7">
                <span className="c0">
                  1) Top Ranking and Sponsored Listing and publications;{" "}
                </span>
              </p>
              <p className="c7">
                <span className="c11">
                  2) services that benefit, promote, support, whether directly
                  or indirectly, the business of User, the User&rsquo;s brand,
                  product, store, logo, trademark, service mark, User Content
                  (as defined in clause 3.7 of the Terms) and/or derivative work
                  using the User Content on software, applications, tools,
                  browser extensions and/or platforms operated and/or controlled
                  by BellaBanga.com (&ldquo;
                </span>
                <span className="c4">BellaBanga Channels</span>
                <span className="c11">
                  &rdquo;) and/or on software, applications, channels,
                  platforms, websites and/or any other forms of media that are
                  operated and/or controlled by third parties (&ldquo;
                </span>
                <span className="c4">Third Party Channels</span>
                <span className="c0">&rdquo;); and </span>
              </p>
              <p className="c7">
                <span className="c0">
                  3) any such other services as may be announced by
                  BellaBanga.com from time to time.
                </span>
              </p>
              <p className="c7">
                <span className="c0">
                  BellaBanga.com shall be entitled to, at its sole discretion,
                  determine whether the Promotion Services or any part thereof
                  will be available to you. &nbsp;
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users
                  Generally&nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As
                  a condition of your access to and use of the Sites or
                  Services, you agree that you will comply with all applicable
                  laws and regulations when accessing or using the Sites or
                  Services.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You
                  agree that{" "}
                </span>
              </p>
              <p className="c7">
                <span className="c0">
                  (a) you will not copy, reproduce, download, re-publish, sell,
                  distribute or resell any Services or any information, text,
                  images, graphics, video clips, sound, directories, files,
                  databases or listings, etc. available on or through the Sites
                  (the &ldquo;Site Content&rdquo;), and{" "}
                </span>
              </p>
              <p className="c7">
                <span className="c0">
                  (b) you will not copy, reproduce, download, compile or
                  otherwise use any Site Content for the purposes of operating a
                  business that competes with BellaBanga.com, or otherwise
                  commercially exploiting the Site Content. Systematic retrieval
                  of Site Content from the Sites to create or compile, directly
                  or indirectly, a collection, compilation, database or
                  directory (whether through robots, spiders, automatic devices
                  or manual processes) without written permission from
                  BellaBanga.com is prohibited. Use of any content or materials
                  on the Sites for any purpose not expressly permitted in the
                  Terms is prohibited.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must
                  read the following documents which govern the protection and
                  use of personal information about Users in the possession of
                  BellaBanga.com and our affiliates:
                </span>
              </p>
              <p className="c7">
                <span className="c11">
                  for Users who access or use Sites relating to
                  the&nbsp;BellaBanga&nbsp;e-commerce&nbsp;platforms, the&nbsp;
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=https://terms.alicdn.com/legal-agreement/terms/suit_bu1_aliexpress/suit_bu1_aliexpress201909171350_82407.html&amp;sa=D&amp;source=editors&amp;ust=1703092596489053&amp;usg=AOvVaw3S7Fz9mg3iQDe6dpJF2hjz"
                  >
                    BellaBanga.com Privacy Policy
                  </a>
                </span>
                <span className="c0">
                  &nbsp;(herein called the &ldquo;Privacy Policy&rdquo;).
                </span>
              </p>
              <p className="c8">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  may allow Users to access to content, products or services
                  offered by third parties through hyperlinks (in the form of
                  word link, banners, channels or otherwise), API or otherwise
                  to such third parties&#39; web sites. You are cautioned to
                  read such web sites&#39; terms and conditions and/or privacy
                  policies before using the Sites. You acknowledge that
                  BellaBanga.com has no control over such third parties&#39; web
                  sites, does not monitor such web sites, and shall not be
                  responsible or liable to anyone for such web sites, or any
                  content, products or services made available on or through
                  such web sites.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You agree
                  not to undertake any action to undermine the integrity of the
                  computer systems or networks of BellaBanga.com and/or any
                  other User nor to gain unauthorized access to such computer
                  systems or networks.
                  <br />
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You agree
                  not to undertake any action which may undermine the integrity
                  of BellaBanga.com&rsquo;s feedback system, such as leaving
                  positive feedback for yourself using secondary Member IDs or
                  through third parties or by leaving unsubstantiated negative
                  feedback for another User.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  3.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By posting
                  or displaying any logos, trademarks, service marks, brands,
                  description/information in the product listings, and any other
                  information, content or material&nbsp; on the Sites (any of
                  such information, content or material, collectively referred
                  to as &ldquo;
                </span>
                <span className="c4">User Content</span>
                <span className="c0">
                  &rdquo;) or providing any User Content to BellaBanga.com
                  and/or its affiliates and their respective representative(s),
                  and to the extent permitted under applicable laws, you grant
                  an irrevocable, perpetual, worldwide, royalty-free, and
                  sub-licensable (through multiple tiers) license to
                  BellaBanga.com and/or its affiliates and their respective
                  representative(s) to display, transmit, distribute, reproduce,
                  publish, duplicate, adapt, modify, edit, translate, create
                  derivative works using the User Content, remove any part of it
                  (including, without limitation, the watermark or mark the User
                  Content bears), and otherwise use any or all of the User
                  Content in any form, media, or technology now known or not
                  currently known in any manner, on the Sites, BellaBanga
                  Channels and/or Third Party Channels and for any purpose which
                  may be beneficial, whether directly or indirectly, to
                  BellaBanga.com, the operation of the Sites, the provision of
                  any Services and Promotion Services and/or the business of the
                  User. You confirm and warrant to BellaBanga.com that you have
                  all the rights, power and authority necessary to grant the
                  above license and the User Content and use of such User
                  Content (including derivative works) by BellaBanga.com and/or
                  its affiliates under such license is free from any
                  infringement or violation of any Third Party Rights (as
                  defined in clause 5.4 of the Terms). To the maximum extent
                  permitted by law, you waive your right to enforce your
                  Intellectual Property Rights in the User Content against
                  BellaBanga.com and/or its affiliates, assignees or
                  sub-licensees in connection with use of such User Content in
                  connection with the Services. Information that is protected
                  under data protection laws will only be used and kept in
                  compliance with those laws.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  3.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As a user
                  you further undertake, represent and warrant as follows:
                </span>
              </p>
              <p className="c20 c31">
                <span className="c0">
                  By using the our platform and Service, you represent and
                  warrant that:
                </span>
              </p>
              <ol className="c18 lst-kix_list_1-1 start" start="1">
                <li className="c14 li-bullet-0">
                  <span className="c0">
                    You have the legal capacity and you agree to comply with the
                    Terms;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    If you register yourself as a representative of a legal
                    entity, you are authorized by the company to enter into an
                    agreement with us and with users of the Platform;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">You are above 18 years of age;</span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will or have provided true, accurate, and complete
                    information in your Account;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will update your information on your Account to maintain
                    its truthfulness, accuracy, and completeness;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will immediately change data for access to the Platform
                    if you have a suspicion that your Account access details
                    were disclosed or probably used by the third parties;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will notify the Administrator of any unauthorized access
                    to your Account;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not provide any false or misleading information
                    about your identity or location in your Account;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will use the Service in strict abidance by applicable
                    laws, regulations, rules, guidelines;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not use the Service for any illegal or unauthorized
                    purpose;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not post on the Platform announcements that offer
                    for sale or exchange any Prohibited Items and contraband
                    goods.
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not post on the Platform announcements that
                    infringe other person&rsquo;s rights or interests, including
                    any intellectual property rights or any other personal or
                    proprietary rights of any third party.
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not post on the Platform announcements that
                    include:
                  </span>
                </li>
              </ol>
              <ol className="c18 lst-kix_list_1-2 start" start="1">
                <li className="c20 c31 c32 li-bullet-0">
                  <span className="c0">
                    false, misleading or deceptive statements;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    personal or identifying information about minors or other
                    persons without the proper consent;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    pornographic, overtly sexual materials;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    depictions that encourage illegal or reckless use of weapons
                    and dangerous objects, or facilitate the purchase of
                    firearms or ammunition;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    defamatory, discriminatory, mean-spirited, threatening or
                    harassing, improper, unacceptable materials, vulgar or
                    abusive language;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    advocacy of hate, violence, discrimination, racism,
                    xenophobia, ethnic conflicts;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    appeals to violence and unlawful actions;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    offers of prostitution or other services contradicting moral
                    or legal norms;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    services, provision of which is prohibited by the applicable
                    law;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    information of solely promotional nature with no offers of
                    specific goods or services;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    counterfeit and imitated goods or unauthorized copies.
                    Unauthorized copies include also goods having been acquired
                    by illegal means, pirated or stolen; and
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    direct or indirect references to any other web sites,
                    references, or information about websites competing with the
                    Platform;
                  </span>
                </li>
              </ol>
              <ol className="c18 lst-kix_list_1-1" start="14">
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not use software and pursue any other actions aimed
                    to interference with the normal operation of the Platform;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not promote or distribute unsolicited commercial
                    emails, chain letters, Ponzi schemes through the Platform or
                    by any other means towards other users of the Platform;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not copy, modify, distribute any other User Content
                    without consent of the respective user;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not harvest or otherwise collect information about
                    users, including email addresses, phone numbers, without
                    their consent or otherwise violate the privacy of another
                    person;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You will not download, store, post, distribute and provide
                    access to, or in any other way use worms, viruses, trojans,
                    and other malware;
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You have a legal title to the items offered for sale in your
                    announcement; and
                  </span>
                </li>
                <li className="c9 li-bullet-0">
                  <span className="c0">
                    You have the necessary license or are otherwise authorized,
                    as required by applicable law, to offer for sale, to
                    advertise, and distribute goods described in your
                    announcement.
                  </span>
                </li>
              </ol>
              <p className="c8 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  Accounts
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User must
                  be registered on the Sites to access or use some Services (a
                  registered User is also referred to as a &ldquo;
                </span>
                <span className="c4">Member</span>
                <span className="c0">
                  &rdquo; below). Except with BellaBanga.com &rsquo;s approval,
                  one User may only register one member account on the Sites.
                  BellaBanga.com may cancel or terminate a User&rsquo;s member
                  account if BellaBanga.com has reasons to suspect that the User
                  has concurrently registered or is in control of two or more
                  member accounts. Further, BellaBanga.com may reject
                  User&rsquo;s application for registration for any reason.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon
                  registration on the Sites, BellaBanga.com shall assign an
                  account and issue a member ID and password (the latter shall
                  be chosen by a registered User during registration) to each
                  registered User. An account may have a web-based email account
                  with limited storage space for the Member to send or receive
                  emails.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A set
                  of Member ID and password is unique to a single account. Each
                  Member shall be solely responsible for maintaining the
                  confidentiality and security of your Member ID and password
                  and for all use of and activities that occur under your
                  account (whether such use or activities are authorized or
                  not). No Member may share, assign, or permit the use of your
                  Member account, ID or password by another person, even to
                  other individuals within the Member&rsquo;s own business
                  entity (where applicable). Member agrees to notify
                  BellaBanga.com immediately if you become aware of any
                  unauthorized use of your password or your account or any other
                  breach of security of your account.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  agrees that all use of the Sites and Services, and all
                  activities that occur under your account (including without
                  limitation, posting any company or product information,
                  clicking to accept any Additional Agreements or rules,
                  subscribing to or making any payment for any services, sending
                  emails using the email account or sending SMS) will be deemed
                  to have been authorized by the Member.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  acknowledges that sharing of your account with other persons,
                  or allowing multiple users outside of your business entity to
                  use your account (collectively, &quot;
                </span>
                <span className="c4">multiple use</span>
                <span className="c0">
                  &quot;), may cause irreparable harm to BellaBanga.com or other
                  Users of the Sites. Member shall indemnify BellaBanga.com, our
                  affiliates, directors, employees, agents and representatives
                  against any loss or damages (including but not limited to loss
                  of profits) suffered as a result of the multiple use of your
                  account. Member also agrees that in case of the multiple use
                  of your account or Member&rsquo;s failure to maintain the
                  security of your account, BellaBanga.com shall not be liable
                  for any loss or damages arising from such a breach and shall
                  have the right to suspend or terminate Member&rsquo;s account
                  without liability to the Member.
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member&rsquo;s
                  Responsibilities
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each
                  Member represents, warrants and agrees that (a) you have full
                  power and authority to accept the Terms, to grant the license
                  and authorization and to perform the obligations hereunder;
                  (b) your access and use the Sites and Services will be for
                  business purposes only; and (c) for Members who are business
                  entities, the address you provide when registering is the
                  principal place of business of your business entity. &nbsp;For
                  purposes of this provision, a branch or liaison office will
                  not be considered a separate entity and your principal place
                  of business will be deemed to be that of your head
                  office.&nbsp;
                </span>
              </p>
              <p className="c8">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  will be required to provide information or material about your
                  entity, business or products/services as part of the
                  registration process on the Sites for your access to and use
                  of any Service or the member account. &nbsp;Each Member
                  represents, warrants and agrees that (a) such information and
                  material whether submitted during the registration process or
                  thereafter throughout the continuation of the use of the Sites
                  or Service is true, accurate, current and complete, and (b)
                  you will maintain and promptly amend all information and
                  material to keep it true, accurate, current and complete.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c4">
                  UPON BECOMING A MEMBER, YOU CONSENT TO THE INCLUSION OF THE
                  CONTACT INFORMATION ABOUT YOU IN OUR DATABASE AND AUTHORIZE
                  BELLABANGA.COM AND OUR AFFILIATES TO SHARE THE CONTACT
                  INFORMATION WITH OTHER USERS OR OTHERWISE USE YOUR PERSONAL
                  INFORMATION IN ACCORDANCE WITH THE PRIVACY POLICY.&nbsp;
                </span>
                <span className="c0">
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each Member
                  represents, warrants and agrees that (a) you shall be solely
                  responsible for obtaining all necessary third party licenses
                  and permissions regarding any User Content that you submit,
                  post or display; (b) any User Content that you submit, post or
                  display does not infringe or violate any of the copyright,
                  patent, trademark, trade name, trade secrets or any other
                  personal or proprietary rights of any third party (&ldquo;
                </span>
                <span className="c4">Third Party Rights</span>
                <span className="c0">
                  &rdquo;); and (c) you have the right and authority to sell,
                  trade, distribute or export or offer to sell, trade,
                  distribute or export the products or services described in the
                  User Content and such sale, trade, distribution or export or
                  offer does not violate any Third Party Right&#65307;and (d)
                  neither the Member nor any of its related persons, have been
                  banned or removed from any major e-commerce platforms, or
                  otherwise implicated in selling counterfeit or pirated
                  products online.&nbsp;
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each Member
                  further represents, warrants and agrees that the User Content
                  that you submit, post or display shall:
                  <br />
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  a)&nbsp;be true, accurate, complete and lawful;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  b)&nbsp;not be false, misleading or deceptive;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  c)&nbsp;not contain information that is defamatory, libelous,
                  threatening or harassing, obscene, objectionable, offensive,
                  sexually explicit or harmful to minors;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  d)&nbsp;not contain information that is discriminatory or
                  promotes discrimination based on race, sex, religion,
                  nationality, disability, sexual orientation or age;
                </span>
              </p>
              <p className="c3">
                <span className="c11">e)not violate the&nbsp;</span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=https://campaign.aliexpress.com/wow/gcp/itemblocken/index?wh_weex%3Dtrue%26wx_navbar_hidden%3Dtrue%26wx_navbar_transparent%3Dtrue%26ignoreNavigationBar%3Dtrue%26wx_statusbar_hidden%3Dtrue%26previewTime%3D1651045471708&amp;sa=D&amp;source=editors&amp;ust=1703092596495087&amp;usg=AOvVaw1yJRKLW3CMUu8qa0sol2OT"
                  >
                    BellaBanga Product Listing Policy
                  </a>
                </span>
                <span className="c0">
                  , other Terms or any applicable Additional Agreements
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  f)&nbsp;not violate any applicable laws and regulations
                  (including without limitation those governing export control,
                  consumer protection, unfair competition,&nbsp;price
                  gouging&nbsp;or false advertising) or promote any activities
                  which may violate any applicable laws and regulations;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  g) not contain any link directly or indirectly to any other
                  web Sites which includes any content that may violate the
                  Terms.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c8">
                <span className="c0">
                  5.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each Member
                  further represents, warrants and agrees that you shall/are:
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  a)carry on your activities on the Sites in compliance with any
                  applicable laws and regulations;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  b)conduct your business transactions with other users of the
                  Sites in good faith;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  c)&nbsp;carry on your activities in accordance with the Terms
                  and any applicable Additional Agreements;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  d)&nbsp;not use the Services or Sites to defraud any person or
                  entity (including without limitation sale of stolen items, use
                  of stolen credit/debit cards);
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  e)&nbsp;not impersonate any person or entity, misrepresent
                  yourself or your affiliation with any person or entity;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  f)&nbsp;not engage in spamming or phishing;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  g)&nbsp;not engage in any other unlawful activities (including
                  without limitation those which would constitute a criminal
                  offence, give rise to civil liability, etc.) or encourage or
                  abet any unlawful activities;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  h) not involve attempts to copy, reproduce, exploit or
                  expropriate BellaBanga.com&rsquo;s various proprietary
                  directories, databases and listings;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  i)&nbsp;not involve any computer viruses or other destructive
                  devices and codes that have the effect of damaging,
                  interfering with, intercepting or expropriating any software
                  or hardware system, data or personal information;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  j)not involve any scheme to undermine the integrity of the
                  data, systems or networks used by BellaBanga.com and/or any
                  user of the Sites or gain unauthorized access to such data,
                  systems or networks;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  k)not, and your director(s), officer(s), controlling
                  party/ies, affiliates and legal jurisdiction in which any of
                  the foregoing persons or entities is organized or has
                  operations are not, persons or entities that are subject to
                  any economic or fraud sanctions of any governmental,
                  international or regulatory entities; and
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  l)&nbsp;not engage in any activities that would otherwise
                  create any liability for BellaBanga.com or our affiliates.
                </span>
              </p>
              <p className="c3">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member/user
                  shall not use the Sites, Services or member account to engage
                  in activities which are identical or similar to
                  BellaBanga.com&rsquo;s e-commerce marketplace business.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If
                  Member provides a business referee, Member represents,
                  warrants and agrees that you have obtained all necessary
                  consents, approvals and waivers from such referee to (a) act
                  as your business referee; (b) post and publish their contact
                  details and information, reference letters and comments on
                  their behalf; and (c) that third parties may contact such
                  business referees to support claims or statements made about
                  you. You further warrant and agree that all reference letters
                  and comments are true and accurate and third parties may
                  contact the business referees without the need to obtain your
                  consent.
                  <br />
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  agrees to provide all necessary information, materials and
                  approval, and render all reasonable assistance and cooperation
                  necessary for BellaBanga.com&rsquo;s provision of the
                  Services, evaluating whether Member has breached the Terms
                  and/or handling any complaint against the Member. If
                  Member&rsquo;s failure to do so results in delay in, or
                  suspension or termination of, the provision of any Service,
                  BellaBanga.com shall not be obliged to extend the relevant
                  service period nor be liable for any loss or damages arising
                  out of or in connection with such delay, suspension or
                  termination.
                  <br />
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  acknowledges and agrees that BellaBanga.com reserves the right
                  to, but shall not be required to actively monitor or exercise
                  any editorial control whatsoever over the content of any
                  message or material or information (including User Content)
                  created, obtained or accessible through the Services or Sites.
                  BellaBanga.com does not endorse, verify or otherwise certify
                  the contents of any comments or other material or information
                  (including User Content) created, submitted, posted, displayed
                  or otherwise made by any Member. Each Member is solely
                  responsible for the contents of their communications and may
                  be held legally liable or accountable for the content of their
                  comments or other material or information.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  5.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Member
                  acknowledges and agrees that each Member is solely responsible
                  for observing applicable laws and regulations in its
                  respective jurisdictions to ensure that all access and use of
                  the Site and Services are in compliance with the same.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c8">
                <span className="c0">
                  5.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each
                  Member represents, warrants and agrees that&nbsp;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  a)you and your affiliates shall comply with applicable laws
                  and regulations in conducting your respective business
                  (including without limitation applicable laws and regulations
                  with respect to product safety, intellectual property rights,
                  data privacy, consumer protection, product or regulatory
                  certification, import and export control, unfair competition,
                  price gouging, false advertising, labor, environment, health
                  and safety, anti-bribery and anti-money laundering).
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  b)&nbsp;you and your affiliates are not the subject of any
                  trade restrictions, sanctions or other legal restrictions
                  enacted or proposed to be enacted by any country,
                  international organization or jurisdiction (&quot;Relevant
                  Subjects&quot;);
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  c)&nbsp;you and your affiliates are not offering products or
                  services that would likely trigger any international trade
                  restrictions, sanctions or other legal restrictions by any
                  Relevant Subjects;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  d)neither you, or any of your parent companies or affiliates,
                  directly or indirectly, deals with, or provides any funds,
                  goods or services to Relevant Subjects;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  e)you will at all times comply with all applicable export
                  control and sanctions laws and regulations with regard to
                  products, services, software and technologies in using the
                  Services, including sanctions resolutions, laws and
                  regulations enacted and enforced by the UN Security Council,
                  the People&#39;s Republic of China, the United States of
                  America, and any other country;&nbsp;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  f)&nbsp;If&nbsp;you and your affiliates are a Seller of
                  products which require a warning under California Health &amp;
                  Safety Code Section 25249.6 (a &quot;Proposition 65
                  Warning&rdquo;) you (a) will promptly display a Proposition 65
                  Warning on the product detail page as required under the
                  relevant law; (b) authorize BellaBanga.com to display a
                  Proposition 65 Warning on your behalf; and (c) will only
                  revise or remove a Proposition 65 Warning for a product when
                  the prior warning needs to be modified or is no longer legally
                  required, as the case may be; and
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  g)You and your affiliates are responsible for your products
                  compliance with the laws and regulations of the country/region
                  of destination and applicable international conventions,
                  including with relevant labor laws and standards ,such as the
                  Forced Labor Convention,1930 and the Abolition of Forced Labor
                  Convention,1957. If a product is not permitted in one or more
                  markets, Sites reserve the right to remove the product listing
                  from, OR STOP THE SALE OF ANY SUCH PRODUCT IN, those markets.
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  h)&nbsp;If, at any time, you fail to meet any of the above
                  requirements, you should stop using the Services immediately.
                  If BellaBanga.com reasonably believes that any of your conduct
                  violates or threatens to violate any applicable laws and
                  regulations, BellaBanga.com may, at its sole discretion, at
                  any time take action as it may deem appropriate in light of
                  the circumstances, including, but not limited to, terminating
                  the provision of Services to you, closing relevant purchase
                  orders, and terminating your accounts, while reserving all
                  rights it may have regarding any non-compliant actions or
                  conduct of its members.
                </span>
              </p>
              <p className="c3 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Breaches by
                  Members
                </span>
              </p>
              <p className="c20 c27">
                <span className="c4">
                  6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c11">
                  &nbsp;BellaBanga.com reserves the right in our sole discretion
                  to remove, modify or reject any User Content (in whole or in
                  part) that you submit to, post or display on the Sites which
                  we reasonably believe (i) violates any applicable laws and
                  regulations, (ii) violates the Terms hereunder, (iii) could
                  subject BellaBanga.com or our affiliates to liability, (iv)
                  infringes any Third Party Rights, (v) could harm the interests
                  of our Members, third party or BellaBanga.com or our
                  affiliates, or (vi) is otherwise found inappropriate in
                  BellaBanga.com&rsquo;s sole discretion.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If
                  any Member breaches any Terms, or if BellaBanga.com has
                  reasonable grounds to believe that a Member is in breach of
                  any Terms, BellaBanga.com shall have the right to take such
                  disciplinary actions as it deems appropriate, including
                  without limitation: (i) suspending or terminating the
                  Member&rsquo;s account and any and all accounts determined to
                  be related to such account by BellaBanga.com in its sole
                  discretion without liability for any losses or damages arising
                  out of or in connection with such suspension or termination;
                  (ii) restricting, downgrading, suspending or terminating the
                  subscription of, access to, or current or future use of any
                  Service; (iii) removing any product listings or other User
                  Content that the Member has submitted, posted or displayed, or
                  imposing restrictions on the number of product listings or
                  User Content that the Member may post or display; (iv)
                  imposing other restrictions on the Member&rsquo;s use of any
                  features or functions of any Service as BellaBanga.com may
                  consider appropriate in its sole discretion; and (v) any other
                  corrective actions, discipline or penalties as BellaBanga.com
                  may deem necessary or appropriate in its sole discretion.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Without
                  limiting the generality of the provisions of the Terms, a
                  Member would be considered as being in breach of the Terms in
                  any of the following circumstances:
                </span>
              </p>
              <p className="c8 c10">
                <span className="c0">
                  a)upon complaint or claim from any third party, BellaBanga.com
                  has reasonable grounds to believe that such Member has
                  willfully or materially failed to perform your contract with
                  such third party including without limitation where a Member
                  who supplies products or services using the Sites and Services
                  has failed to deliver any items ordered by such third party
                  after receipt of the purchase price, or where the items such
                  Member has delivered materially fail to meet the terms and
                  descriptions outlined in your contract with such third party,
                </span>
              </p>
              <p className="c8 c10">
                <span className="c0">
                  b)BellaBanga.com has reasonable grounds to suspect that such
                  Member has used a stolen credit card or other false or
                  misleading information in any transaction with a counter
                  party,
                </span>
              </p>
              <p className="c8 c10">
                <span className="c0">
                  c)BellaBanga.com has reasonable grounds to suspect that any
                  information provided by the Member is not current or complete
                  or is untrue, inaccurate, or misleading, or
                </span>
              </p>
              <p className="c8 c10">
                <span className="c0">
                  d)BellaBanga.com believes that the Member&rsquo;s actions may
                  cause financial loss or legal liability to BellaBanga.com or
                  our affiliates or any other Users.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  reserves the right to cooperate fully with governmental or
                  regulatory authorities, law enforcement bodies, private
                  investigators and/or injured third parties in the
                  investigation of any suspected criminal or civil wrongdoing.
                  Further, to the extent permitted by applicable laws and
                  policies, BellaBanga.com may disclose the Member&#39;s
                  identity, contact information and/or information regarding the
                  Member&#39;s account(s), transactions or activities carried
                  out on or via the Site, if requested by a government,
                  regulatory or law enforcement body or an injured third party,
                  or as a result of a subpoena or other legal
                  action.&nbsp;BellaBanga.com shall not be liable for damages or
                  results arising from such disclosure, and Member agrees not to
                  bring any action or claim against BellaBanga.com for such
                  disclosure.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  may, at any time and in our reasonable discretion, impose
                  limitations on, suspend or terminate the Member&rsquo;s use of
                  any Service or the Sites without being liable to the Member if
                  BellaBanga.com has received notice that the Member is in
                  breach of any agreement or undertaking with any affiliate of
                  BellaBanga.com including without limitation Taobao, Alipay and
                  such breach involves or is reasonably suspected to involve
                  dishonest or fraudulent activities. BellaBanga.com reserves
                  the right to, but shall not be required to investigate such
                  breach or request confirmation from the Member.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each Member
                  agrees to indemnify BellaBanga.com, our affiliates, directors,
                  employees, agents and representatives and to hold them
                  harmless, from any and all damages, losses, claims and
                  liabilities (including legal costs on a full indemnity basis)
                  which may arise from your submission, posting or display of
                  any User Content, from your access to or use of the Sites or
                  Services, or from your breach of the Terms or any Additional
                  Agreements.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each Member
                  further agrees that BellaBanga.com is not responsible, and
                  shall have no liability to you or anyone else for any User
                  Content or other material transmitted through the Sites or
                  Services, including fraudulent, untrue, misleading,
                  inaccurate, defamatory, offensive or illicit material and that
                  the risk of damage from such User Content or other material
                  rests entirely with the Member. BellaBanga.com reserves the
                  right, at our own expense, to assume the exclusive defense and
                  control of any matter otherwise subject to indemnification by
                  the Member, in which event the Member shall cooperate with
                  BellaBanga.com in asserting any available defenses.
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0"></span>
              </p>
              <p className="c1">
                <span className="c0">
                  6.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You agree
                  to indemnify and hold the Administrator, its successors,
                  subsidiaries, affiliates, related companies, its suppliers,
                  licensors and partners, and the officers, directors,
                  employees, agents, and representatives of each of them
                  harmless from any claim or demand, including costs and
                  attorneys&rsquo; fees, made by any third party due to or
                  arising out of: (i) your use of the Platform; (ii) your User
                  Content; (iii) your violation of any representation, warranty,
                  covenant, or obligation stipulated in these Terms; (iv) your
                  violation of any applicable law, industry-standard,
                  regulation, guideline, rule; (v) any transaction entered into
                  by you via the Platform or your violation of terms of such
                  transaction. The Administrator reserves the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us and you
                  agree to cooperate with our defense of these claims.
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Transactions
                  Between Buyers and Sellers
                </span>
              </p>
              <p className="c24">
                <span className="c11">
                  7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Through the
                  Sites, BellaBanga.com provides electronic web-based platforms
                  for exchanging information between buyers and sellers of
                  products and services. &nbsp;BellaBanga.com additionally
                  provides electronic web-based transaction platforms for
                  Members to place, accept, conclude, manage and fulfill orders
                  for the provision of products and services online within the
                  Sites subject to the terms of the&nbsp;
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=https://rule.alibaba.com/rule/detail/2054.htm&amp;sa=D&amp;source=editors&amp;ust=1703092596500110&amp;usg=AOvVaw0j1hCf0rp_VjesEOIApWdI"
                  >
                    Transaction Services Agreement
                  </a>
                </span>
                <span className="c0">
                  . However, for any Services, BellaBanga.com does not represent
                  either the seller or the buyer in specific transactions.
                  BellaBanga.com does not control and is not liable or
                  responsible for the quality, safety, lawfulness or
                  availability of the products or services offered for sale on
                  the Sites, the ability of the sellers to complete a sale or
                  the ability of buyers to complete a purchase.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Users
                  are hereby made aware that there may be risks of dealing with
                  people acting under false pretences. BellaBanga.com uses
                  several techniques to verify the accuracy of certain
                  information our paying Users provide us when they register for
                  a paying membership service on the Sites. However, because
                  user verification on the Internet is difficult, BellaBanga.com
                  cannot and does not confirm each User&#39;s purported identity
                  (including, without limitation, paying Members). We encourage
                  you to use various means, as well as common sense, to evaluate
                  with whom you are dealing.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buyers and
                  sellers accessing or using the Sites or Services shall assume
                  the risks of conducting any purchase and sale transactions in
                  connection with or through the Sites or Services. Buyer and
                  sellers accessing or using the Site or Services shall also
                  fully assume all risks of liability or harm of any kind
                  arising out of or in connection with any subsequent activity
                  relating to the products or services that are the subject of
                  the transactions on the Sites. Examples of such risks shall
                  include, but are not limited to, mis-representation of
                  products and services, fraudulent schemes, unsatisfactory
                  product quality, failure to meet specifications, defective or
                  dangerous products, unlawful products, delay or default in
                  delivery or payment, cost mis-calculations, breach of
                  warranty, breach of contract, transportation accidents, the
                  risk that the manufacture, importation, export, distribution,
                  offer, display, purchase, sale and/or use of products or
                  services offered or displayed on the Sites may violate or may
                  be asserted to violate Third Party Rights, and the risk that
                  Users may incur costs of defense or other costs in connection
                  with third parties&rsquo; assertion of Third Party Rights, or
                  in connection with any claims by any party that they are
                  entitled to defense or indemnification in relation to the
                  assertion of rights, demands or claims by claimants of Third
                  Party Rights. Examples of such risks also include the risk of
                  claims from consumers, other purchasers, end-users of products
                  or other&nbsp;third parties that they have suffered injuries
                  or harm from their use of the products obtained through the
                  Sites or Services. All of the foregoing risks are referred to
                  as &quot;Transaction Risks&quot;. BellaBanga.com is not liable
                  or responsible for any damages, claims, liabilities, costs,
                  harm, inconveniences, business disruptions or expenditures of
                  any kind that may arise a result of or in connection with any
                  Transaction Risks.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buyers and
                  sellers on the Sites are solely responsible for setting out
                  and performance of the terms and conditions of the
                  transactions conducted on, through or as a result of use of
                  the Sites or Services, including, without limitation, terms
                  regarding payment, returns, warranties, shipping, insurance,
                  fees, taxes, title, licenses, fines, permits, handling,
                  transportation and storage, subject to any additional
                  obligations imposed under the&nbsp;
                </span>
                <span className="c11 c13">
                  <a
                    className="c19"
                    href="https://www.google.com/url?q=https://rule.alibaba.com/rule/detail/2054.htm?spm%3Da271m.8038972.1999288231.8.33536d8283P9S4&amp;sa=D&amp;source=editors&amp;ust=1703092596500872&amp;usg=AOvVaw18R9g-FMSLOpGwQEOU5Icj"
                  >
                    Transaction&nbsp;Services Agreement
                  </a>
                </span>
                <span className="c0">
                  &nbsp;(and in particular, clause 3.2).
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;User agrees
                  to provide all information and materials as may be reasonably
                  required by BellaBanga.com in connection with your
                  transactions conducted on, through or as a result of use of
                  the Sites or Services. BellaBanga.com has the right to suspend
                  or terminate any User&rsquo;s account if the User fails to
                  provide the required information and materials without
                  liability for any losses or damages arising out of or in
                  connection with such suspension or termination.
                </span>
              </p>
              <p className="c8 c17">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the
                  event that any User has a dispute with any party to a
                  transaction, such User agrees to release and indemnify
                  BellaBanga.com (and our agents, affiliates, directors,
                  officers and employees) from all claims, demands, actions,
                  proceedings, costs, expenses and damages (including without
                  limitation any actual, special, incidental or consequential
                  damages) arising out of or in connection with such dispute or
                  the transaction.
                </span>
              </p>
              <p className="c8">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c28">
                <span className="c4 c15">8. Limitation of Liability</span>
              </p>
              <p className="c1">
                <span className="c4">8.1</span>
                <span className="c11">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c4">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES PROVIDED
                  BY BELLABANGA.COM ON OR THROUGH THE SITES ARE PROVIDED
                  &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND &ldquo;WITH
                  ALL FAULTS&rdquo;, AND BELLABANGA.COM HEREBY EXPRESSLY
                  DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
                  INCLUDING BUT NOT LIMITED TO, ANY WARRANTIES OF CONDITION,
                  QUALITY, DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY,
                  MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. ALL SUCH
                  WARRANTIES, REPRESENTATIONS, CONDITIONS, AND UNDERTAKINGS ARE
                  HEREBY EXCLUDED.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c4">8.2</span>
                <span className="c11">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c4">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, BELLABANGA.COM MAKES
                  NO REPRESENTATIONS OR WARRANTIES WHATSOEVER ABOUT THE
                  VALIDITY, ACCURACY, CORRECTNESS, RELIABILITY, QUALITY,
                  STABILITY, COMPLETENESS OR CURRENTNESS OF ANY INFORMATION
                  PROVIDED ON OR THROUGH THE SITES; BELLABANGA.COM DOES NOT
                  REPRESENT OR WARRANT THAT THE MANUFACTURE, IMPORTATION,
                  EXPORT, DISTRIBUTION, OFFER, DISPLAY, PURCHASE, SALE AND/OR
                  USE OF PRODUCTS OR SERVICES OFFERED OR DISPLAYED ON THE SITES
                  DOES NOT VIOLATE ANY THIRD PARTY RIGHTS; AND BELLABANGA.COM
                  MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING
                  ANY PRODUCT OR SERVICE OFFERED OR DISPLAYED ON THE SITES.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any
                  material downloaded or otherwise obtained through the Sites or
                  Services is done at each User&#39;s sole discretion and risk
                  and each User is solely responsible for any damage to its own
                  or to BellaBanga.com&rsquo;s computer system(s) or any loss of
                  data that may result from the download of any such material.
                  No advice or information, whether oral or written, obtained by
                  any User from BellaBanga.com or through or from the Sites
                  shall create any warranty not expressly stated herein.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Sites
                  may make available to User services or products provided by
                  independent third parties. No warranty or representation is
                  made with regard to such services or products. In no event
                  shall BellaBanga.com or our affiliates be held liable for any
                  such services or products.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each
                  User hereby agrees to indemnify and hold BellaBanga.com, our
                  affiliates, directors, officers and employees harmless, from
                  any and all losses, claims, liabilities (including legal costs
                  on a full indemnity basis) which may arise from such
                  User&#39;s access to or use of the Sites or Services
                  (including but not limited to the submission, posting or
                  display of such User&#39;s information and User Content on the
                  Sites, BellaBanga Channels or Third Party Channels under
                  Promotion Services) or from your breach of any of the
                  representations, warranties hereunder and the terms and
                  conditions of the Terms. Each User hereby further agrees to
                  indemnify and hold BellaBanga.com, our affiliates, directors,
                  officers and employees harmless, from any and all losses,
                  damages, claims, liabilities (including legal costs on a full
                  indemnity basis) which may arise from User&#39;s breach of any
                  representations and warranties made by User to BellaBanga.com,
                  including but not limited to those set forth in clause 5
                  hereunder. &nbsp;
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each User
                  hereby further agrees to indemnify and save BellaBanga.com,
                  our affiliates, directors, officers and employees harmless,
                  from any and all losses, damages, claims, liabilities
                  (including legal costs on a full indemnity basis) which may
                  arise, directly or indirectly, as a result of any claims
                  asserted by Third Party Rights claimants or other third
                  parties relating to the User Content, information and/or
                  products offered or displayed on the Sites. Each User hereby
                  further agrees that BellaBanga.com is not responsible and
                  shall have no liability to you, for any material posted by
                  others, including defamatory, offensive or illicit material
                  and that the risk of damages from such material rests entirely
                  with each User. BellaBanga.com reserves the right, at our own
                  expense, to assume the exclusive defense and control of any
                  matter otherwise subject to indemnification by you, in which
                  event you shall cooperate with BellaBanga.com in asserting any
                  available defenses.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  shall not be liable for any special, direct, indirect,
                  punitive, incidental or consequential damages or any damages
                  whatsoever (including but not limited to damages for loss of
                  profits or savings, business interruption, loss of
                  information), whether in contract, negligence, tort, equity or
                  otherwise or any other damages resulting from any of the
                  following:
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  a)the use or the inability to use the Sites or Services;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  b)any defect in goods, samples, data, information or services
                  purchased or obtained from a User or any other third party
                  through the Sites or Services;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  c)violation of Third Party Rights or claims or demands that
                  User&#39;s manufacture, importation, exportation,
                  distribution, offer, display, purchase, sale and/or use of
                  products or services offered or displayed on the Sites or
                  through the Services may violate or may be asserted to violate
                  Third Party Rights; or claims by any party that they are
                  entitled to defense or indemnification in relation to
                  assertions of rights, demands or claims by Third Party Rights
                  claimants;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  d)unauthorized access by third parties to data or private
                  information of any User;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  e)statements or conduct of any User of the Sites or Services;
                  or;
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  f)any matters relating to the Sites or Services, however
                  arising, including negligence.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding
                  any of the foregoing provisions, the aggregate liability of
                  BellaBanga.com, our employees, agents, affiliates,
                  representatives or anyone acting on our behalf with respect to
                  each User for all claims arising from the access to or use of
                  the Sites or Services during any calendar year shall be
                  limited to the greater of (a) the amount of fees the User has
                  paid to BellaBanga.com in exchange for the access to or use of
                  the Site or Services during the calendar year and (b) the
                  maximum amount permitted under the applicable law. The
                  preceding sentence shall not preclude the requirement by the
                  User to prove actual damages. All claims arising from the use
                  of the Sites or Services must be filed within one (1) year
                  from the date the cause of action arose or such longer period
                  as prescribed under the applicable law governing the Terms.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  8.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The
                  limitations and exclusions of liability to you under the Terms
                  shall apply to the maximum extent permitted by law and shall
                  apply whether or not BellaBanga.com has been advised of or
                  should have been aware of the possibility of any such losses
                  arising.
                </span>
              </p>
              <p className="c16">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c20">
                <span className="c4 c15">9. Force Majeure</span>
              </p>
              <p className="c20 c23">
                <span className="c4">
                  9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="c11">
                  &nbsp;BellaBanga.com shall not be liable for or required to
                  pay compensation of any nature whatsoever for any loss arising
                  from the unavailability,&nbsp;&nbsp;inconvenience or failures
                  of the services or systems due to the following reasons: (i)
                  system shut-down for maintenance; (ii) inability to transmit
                  data&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; due&nbsp;
                  &nbsp;to failures in communications terminals or
                  telecommunications equipment; (iii) systems failure and
                  inability to perform its functions) due to force&nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; majeure events including but not limited
                  to typhoons, earthquakes, tsunamis, floods, power failure,
                  fires, storms, war, political unrest, labour strikes,&nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; shortage of labor or
                  materials, riots, insurrections, civil disturbances, terrorist
                  attack, explosions, acts of God, governmental actions, orders
                  of domestic&nbsp; &nbsp; &nbsp; or foreign courts or
                  tribunals, non-performance of third parties; or (iv)
                  suspension or delay of services or systems failure due to
                  reasons beyond the&nbsp;reasonable control of BellaBanga.com
                  such as hacker or cyber attacks, technical adjustments or
                  failure of the telecommunications department, website
                  upgrades, third party problems or any suspension or disruption
                  of transportation or business operation (including but not
                  limited to delays or&nbsp;disruption of the&nbsp;
                  &nbsp;resumption of work or operation ordered by any
                  government agency) in the event of a national or regional
                  spread of&nbsp;epidemic or pandemic.
                </span>
              </p>
              <p className="c8">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c20">
                <span className="c4 c15">
                  10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Intellectual Property Rights
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  is the sole owner or lawful licensee of all the rights and
                  interests in the Sites and the Site Content. The Sites and
                  Site Content embody trade secrets and other intellectual
                  property rights protected under worldwide copyright and other
                  laws. All title, ownership and intellectual property rights in
                  the Sites and Site Content shall remain with BellaBanga.com,
                  our affiliates or licensors, as the case may be. All rights
                  not otherwise claimed under the Terms or by BellaBanga.com are
                  hereby reserved.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ldquo;BELLABANGA&rdquo;
                  and related icons and logos are registered trademarks or
                  trademarks or service marks of BellaBanga Limited. The
                  unauthorized copying, modification, use or publication of
                  these marks is strictly prohibited.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  10.3&nbsp;BellaBanga.com may have independent third parties
                  involved in the provision of the Sites or Services (e.g., the
                  authentication and verification service providers). &nbsp;You
                  may not use any trademark, service mark or logo of such
                  independent third parties without prior written approval from
                  such parties.
                </span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c1">
                <span className="c0">
                  10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To the
                  largest extent permissible under applicable law, all rights,
                  title and interest to all derivative work created by
                  BellaBanga.com and/or its affiliates using User Content
                  pursuant to the Terms shall belong to BellaBanga.com, which
                  may be freely assignable, licensable or grantable by
                  BellaBanga.com to any third party or its affiliates.&nbsp;
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0">
                  <br />
                </span>
              </p>
              <p className="c20">
                <span className="c4 c15">11. Notices</span>
              </p>
              <p className="c1">
                <span className="c11">
                  11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All legal
                  notices or demands to or upon BellaBanga Limited&nbsp;shall be
                  made in writing and sent to&nbsp;
                </span>
                <span className="c5">
                  <a className="c19" href="mailto:support@bellabanga.ng">
                    support@bellabanga.ng
                  </a>
                </span>
                <span className="c22">.</span>
                <span className="c0">
                  &nbsp;The notices shall be effective when they are
                  acknowledged by BellaBanga Limited or 7 days after the date of
                  delivery.{" "}
                </span>
              </p>
              <p className="c1">
                <span className="c11">
                  11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You agree
                  that all agreements, notices, demands, disclosures and other
                  communications that BellaBanga.com or &nbsp;BellaBanga Limited
                  sends to you electronically will satisfy any legal requirement
                  that such communication should be in writing.
                </span>
              </p>
              <ol className="c18 lst-kix_list_4-0 start" start="12">
                <li className="c25 li-bullet-0">
                  <span className="c4 c15">FEES</span>
                </li>
              </ol>
              <p className="c20 c26">
                <span className="c0">
                  12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any sales
                  transaction successfully conducted on BellaBanga.com shall
                  attract a transaction fee of 5% of the gross total
                  respectively payable by the seller and the buyer, which
                  Transaction fee shall be deductable and payable in favor of
                  BellaBanga Limited.
                </span>
              </p>
              <p className="c7 c12">
                <span className="c0"></span>
              </p>
              <p className="c16">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c20">
                <span className="c4 c15">13. General Provisions</span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subject to
                  any Additional Agreements, the Terms constitute the entire
                  agreement between you and BellaBanga.com with respect to and
                  govern your use of the Sites and Services, superseding any
                  prior written or oral agreements in relation to the same
                  subject matter herein.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  and you are independent contractors, and no agency,
                  partnership, joint venture, employee-employer or
                  franchiser-franchisee relationship is intended or created by
                  the Terms.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If any
                  provision of the Terms is held to be invalid or unenforceable,
                  such provision shall be deleted and the remaining provisions
                  shall remain valid and be enforced.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Headings
                  are for reference purposes only and in no way define, limit,
                  construe or describe the scope or extent of such clause.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com&rsquo;s
                  failure to enforce any right or failure to act with respect to
                  any breach by you under the Terms will not constitute a waiver
                  of that right nor a waiver of BellaBanga.com&rsquo;s right to
                  act with respect to subsequent or similar breaches.
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BellaBanga.com
                  shall have the right to assign the Terms (including all of our
                  rights, titles, benefits, interests, and obligations and
                  duties in the Terms to any person or entity (including any
                  affiliates of BellaBanga.com). You may not assign, in whole or
                  part, the Terms to any person or entity.
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Where a
                  User or member perceives that there has been a default, breach
                  or negligence on the part of BellaBanga Limited, such user or
                  member shall be obliged to first make his complaint to
                  BellaBanga Limited within 3 days of the becoming aware of such
                  default, breach or negligence.
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Failure or
                  default of a user or member to complain about any perceived
                  default, breach or negligence on the part of BellaBanga
                  Limited within 3 days of the becoming aware of such shall be
                  deemed a waiver.
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  <br />
                  &nbsp;
                </span>
              </p>
              <p className="c21">
                <span className="c0">
                  13.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These
                  Terms shall be governed in accordance with the laws of the
                  Republic of Nigeria.
                </span>
              </p>
              <p className="c2">
                <span className="c0">
                  13.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any
                  dispute arising out of or in connection with these Terms,
                  including any question regarding its existence, validity or
                  termination, shall be referred to and finally resolved by
                  arbitration under the Arbitration and Mediation Act, 2023Laws
                  of the Federal Republic of Nigeria. The number of arbitrators
                  shall be one. The seat of arbitration shall be Anambra State,
                  Nigeria. The language to be used in the arbitral proceedings
                  shall be English.&nbsp;
                </span>
              </p>
              <ol className="c18 lst-kix_list_3-1 start" start="1">
                <li className="c6 li-bullet-0">
                  <span className="c0">
                    Except as otherwise provided, if any provision of these
                    Terms is held to be invalid, void, or for any reason
                    unenforceable, such provision shall be struck out and shall
                    not affect the validity and enforceability of the remaining
                    provisions.
                  </span>
                </li>
                <li className="c6 li-bullet-0">
                  <span className="c0">
                    We may transfer and assign any and all of our rights and
                    obligations under these Terms to any other person, by any
                    way, including by novation, and by accepting these Terms you
                    give us consent to any such transfer or assignment.
                  </span>
                </li>
                <li className="c6 li-bullet-0">
                  <span className="c0">
                    If we fail to take any action with respect to your breach of
                    these Terms, we will still be entitled to use our rights and
                    remedies in any other situation where you breach these
                    Terms.
                  </span>
                </li>
              </ol>
              <p className="c8 c23">
                <span className="c0">
                  13.11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In no
                  event shall the Administrator be liable for any failure to
                  comply with these Terms to the extent that such failure arises
                  from factors outside the Administrator&#39;s reasonable
                  control.
                </span>
              </p>
              <p className="c8 c17">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c7">
                <span className="c0">&nbsp;</span>
              </p>
              <p className="c12 c29">
                <span className="c0"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
