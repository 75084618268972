import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { UserContext } from "../../Access";
import { contactService } from "../../services/contact.service";
import { faqsService } from "../../services/faqs.service";
import Util from "../../utils/util";
import Accodion from "../Helpers/Accodion";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
export default function Faq() {
  const userSession = useContext(UserContext);
  const currentUser = userSession.user;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFaq, setIsLoadingFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [input, setInput] = useState({
    userId: currentUser?.id,
    name: "",
    emailAddress: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [validation, setValidation] = useState({
    validName: true,
    validEmailAddress: true,
    validPhoneNumber: true,
    validSubject: true,
    validMessage: true,
  });
  const inputHandler = (e) => {
    const name = e.target.id;
    setInput({ ...input, [name]: e.target.value });
    Util.validate(validation, input, [name]);
  };
  const addFaq = () => {
    Util.validate(validation, input, [
      "name",
      "emailAddress",
      "phoneNumber",
      "subject",
      "message",
    ]);
    if (
      validation.validEmailAddress &&
      validation.validName &&
      validation.validPhoneNumber &&
      validation.validSubject &&
      validation.validMessage
    ) {
      setIsLoadingFaq(true);
      contactService
        .contact(input)
        .then((result) => {
          if (result.data && result.data.success) {
            toast.success(result.data.message);
            navigate("/success-message");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          setIsLoadingFaq(false);
          toast.error(error);
        })
        .finally(() => {
          setIsLoadingFaq(false);
        });
    } else {
      toast.error("All fields required!");
    }
  };

  const getFaqs = () => {
    setIsLoading(true);
    faqsService
      .getFaqs()
      .then((result) => {
        if (result.data && result.data.success) {
          const responseData = result.data.payload;
          setFaqs(responseData);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="faq-page-wrapper w-full mb-10">
        <div className="page-title w-full">
          <PageTitle
            title="Frequently Asked Questions"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "FAQ", path: "/faq" },
            ]}
          />
        </div>
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
              <h1 className="text-qblack font-bold text-[22px] mb-4">
                Frequently asked questions
              </h1>
              <div className="flex flex-col space-y-7 justify-between">
                {faqs.map((faq, index) => {
                  return (
                    <Accodion
                      key={index}
                      title={faq?.title}
                      des={faq?.description}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex-1">
              <div className="bg-white sm:p-10 p-5">
                <div className="title flex flex-col items-center">
                  <h1 className="lg:text-[34px] text-xl font-bold text-qblack">
                    Have Any Qustion
                  </h1>
                  <span className="-mt-5 block">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#FFBB38"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </div>
                <div className="inputs mt-5">
                  <div className="mb-4 pb-4">
                    <InputCom
                      label="Name*"
                      placeholder="Demo Name"
                      name="name"
                      inputClasses="h-[50px]"
                      value={input.name}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="mb-4 pb-4">
                    <InputCom
                      label="Email Address*"
                      placeholder="info@quomodosoft.com"
                      name="emailAddress"
                      inputClasses="h-[50px]"
                      parentClasses={
                        !validation.validEmailAddress ? `border-red-600` : " "
                      }
                      value={input.emailAddress}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="mb-4 pb-4">
                    <InputCom
                      label="Phone Number*"
                      placeholder="+435 67890987"
                      name="phoneNumber"
                      inputClasses="h-[50px]"
                      value={input.phoneNumber}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="mb-4 pb-4">
                    <InputCom
                      label="Subject*"
                      placeholder="subject"
                      name="subject"
                      inputClasses="h-[50px]"
                      value={input.subject}
                      inputHandler={inputHandler}
                    />
                  </div>
                  <div className="mb-5">
                    <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                      Message*
                    </h6>
                    <textarea
                      placeholder="Type your message here"
                      name="message"
                      id="message"
                      className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                      defaultValue={input.message}
                      value={input.message}
                      onChange={inputHandler}
                    ></textarea>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={!isLoadingFaq ? addFaq : null}
                      type="button"
                      className="black-btn w-[300px] h-[50px]  flex justify-center"
                    >
                      <span className="flex space-x-1 items-center h-full">
                        <span>
                          {isLoadingFaq ? (
                            <ClipLoader
                              color="#fff"
                              size={30}
                              data-testid="loader"
                            />
                          ) : (
                            <span className="text-sm font-semibold">
                              Submit
                            </span>
                          )}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
